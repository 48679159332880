import { IScheduleTransform } from 'common-api'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScheduleId } from '../../commonTypes'
import type { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { applyAllTransforms } from '../../utils/scheduleTransforms'
import { useActiveOrgId } from '../auth/hooks'
import type { ApplicationState } from '../index'

import { subscribe, unsubscribe } from './actions'

export const useLocalSchedule = (): ScheduleAccessor => {
    const schedule = useSchedule()
    const transforms = useSelector<ApplicationState, IScheduleTransform[]>(({ schedule }) => schedule.pendingTransforms)

    const transformedSchedule = useMemo(() => applyAllTransforms(schedule, transforms), [schedule, transforms])

    return transformedSchedule
}

export const useScheduleSubscription = (scheduleId: ScheduleId) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(subscribe(scheduleId))

        return () => {
            dispatch(unsubscribe(scheduleId))
        }
    }, [dispatch, scheduleId])
}

export const useSchedule = (): ScheduleAccessor => {
    const scheduleId = useActiveOrgId()
    useScheduleSubscription(scheduleId)

    return useSelector((s: ApplicationState) => s.schedule.schedule)
}

export const useIsDummySchedule = (): boolean =>
    useSelector<ApplicationState, boolean>((state) => state.schedule.isDummy)
