import classNames from 'classnames'

import TypeScale from '../../../styles/TypeScale'
import { toTranslate } from '../../../utils/miscUtil'
import { getResultByTypes } from '../hooks/useSearchResults'
import styles from '../style.module.css'
import { Result } from '../types'

import { ResultSection } from './ResultSection'

export const Results = ({
    results,
    isSearching,
    isSelected,
    searchText
}: {
    results: Result[]
    isSearching: boolean
    isSelected: (id: string) => boolean
    searchText: string
}) => {
    const { teachers, subjects, courses, rooms, studentGroups } = getResultByTypes(results)

    return (
        <div>
            <p className={classNames(TypeScale.Label_MD_Semibold, styles.label)}>{toTranslate('Results')}</p>
            <div className={styles.resultSections}>
                <ResultSection
                    results={teachers}
                    title={toTranslate('Teachers')}
                    isSearching={isSearching}
                    isSelected={isSelected}
                    searchText={searchText}
                />
                <ResultSection
                    results={subjects}
                    title={toTranslate('Subjects')}
                    isSearching={isSearching}
                    isSelected={isSelected}
                    searchText={searchText}
                />
                <ResultSection
                    results={courses}
                    title={toTranslate('Courses')}
                    isSearching={isSearching}
                    isSelected={isSelected}
                    searchText={searchText}
                />
                <ResultSection
                    results={rooms}
                    title={toTranslate('Rooms')}
                    isSearching={isSearching}
                    isSelected={isSelected}
                    searchText={searchText}
                />
                <ResultSection
                    results={studentGroups}
                    title={toTranslate('Student Groups')}
                    isSearching={isSearching}
                    isSelected={isSelected}
                    searchText={searchText}
                />
            </div>
        </div>
    )
}
