import classNames from 'classnames'
import { ITeacherProblemPair } from 'common-api'
import { useTranslation } from 'react-i18next'

import { TeacherAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { isProblemRelatedToTeacher, isProblemRelatedToTeachingAssignment } from '../../../../utils/problems'
import { ProblemCorner } from '../../../schedule/components/ProblemCorner'
import { toProblemListProblem } from '../../../schedule/components/ProblemsList/utils'
import { indexToHeight } from '../../util'

import classes from './style.module.css'

type TeachingAssignmentRowsProps = {
    teachers: TeacherAccessor[]
    sortedCourseRounds: {
        row: number
        crws: {
            cr: any
            startHour: number
            totalHours: number
        }
    }[]
    maxVisibleHours: number
    TEACHER_FULL_TIME_HOURS: number
    taDragErrors: ITeacherProblemPair[]
    onTeacherClick: (teacherId: string, selectMultipleTeachers: boolean) => void
    selectedTeachersIds: string[]
}

export default function TeachingAssignmentRows({
    teachers: filteredTeachers,
    sortedCourseRounds,
    maxVisibleHours,
    TEACHER_FULL_TIME_HOURS,
    taDragErrors,
    onTeacherClick,
    selectedTeachersIds
}: TeachingAssignmentRowsProps) {
    const { t } = useTranslation()
    const schedule = useLocalSchedule()

    function taDragErrorsForTeacher(teacher: TeacherAccessor) {
        return taDragErrors.filter((e) => e.teacherId === teacher.getTeacherId())
    }

    function getWorkHours(workPercentage: number) {
        return (TEACHER_FULL_TIME_HOURS * workPercentage) / 100
    }

    function forbiddenHourStart(teacher: TeacherAccessor) {
        const errorsForTeacher = taDragErrorsForTeacher(teacher)

        return errorsForTeacher.length > 0 ? 0 : getWorkHours(teacher.getWorkPercentage())
    }

    return (
        <>
            {filteredTeachers.map((teacher, index) => (
                <div
                    className={classNames(classes.rowWrapper, {
                        [classes['rowWrapper--even']]: index % 2 === 0,
                        [classes['rowWrapper--odd']]: index % 2 !== 0,
                        [classes['rowWrapper--selected']]: selectedTeachersIds.includes(teacher.getTeacherId())
                    })}
                    key={teacher.getTeacherId()}
                    style={{ top: `calc(${indexToHeight(index)}px + var(--ta-row-height)` }}
                >
                    <button
                        type="button"
                        onClick={(event) => {
                            onTeacherClick(teacher.getTeacherId(), event.shiftKey || event.ctrlKey)
                        }}
                        className={classes.teacherName}
                    >
                        <div className={classes.teacherNameBackground}>
                            <div className={classes.teacherNameBackgroundContent}>
                                <ProblemCorner
                                    problems={schedule
                                        .getSchedulingProblems()
                                        .filter(
                                            (p) =>
                                                isProblemRelatedToTeachingAssignment(p.problem) &&
                                                isProblemRelatedToTeacher(schedule, p.problem, teacher.getTeacherId())
                                        )
                                        .map((p) => toProblemListProblem(schedule, p, t))}
                                    roundedCorner={false}
                                />

                                {teacher.getTeacherSchoolId()}
                            </div>
                            <div style={{ marginTop: '.3em' }} className={classes.teacherNameBackgroundContent}>
                                {sortedCourseRounds
                                    .filter((cr) => cr.row === index)
                                    .map((cr) => cr.crws.totalHours)
                                    .reduce((prev, current) => prev + current, 0)}
                                h &nbsp;(
                                {Math.round(
                                    (100 *
                                        sortedCourseRounds
                                            .filter((cr) => cr.row === index)
                                            .map((cr) => cr.crws.totalHours)
                                            .reduce((prev, current) => prev + current, 0)) /
                                        TEACHER_FULL_TIME_HOURS
                                )}
                                %)
                            </div>
                        </div>
                    </button>
                    <div className={classes.hoursWrapper}>
                        <div
                            className={classes.blocked}
                            style={{
                                left: `${(100 * forbiddenHourStart(teacher)) / maxVisibleHours}%`,
                                width: `${100 * (1 - forbiddenHourStart(teacher) / maxVisibleHours)}%`
                            }}
                        />
                    </div>
                </div>
            ))}
        </>
    )
}
