function isStarting(obj) {
    return (obj.type === "starting");
}
function starting(obj) {
    return {
        starting: obj,
        type: "starting",
    };
}
function isRunning(obj) {
    return (obj.type === "running");
}
function running(obj) {
    return {
        running: obj,
        type: "running",
    };
}
function isStopping(obj) {
    return (obj.type === "stopping");
}
function stopping(obj) {
    return {
        stopping: obj,
        type: "stopping",
    };
}
function isStopped(obj) {
    return (obj.type === "stopped");
}
function stopped(obj) {
    return {
        stopped: obj,
        type: "stopped",
    };
}
function visit(obj, visitor) {
    if (isStarting(obj)) {
        return visitor.starting(obj.starting);
    }
    if (isRunning(obj)) {
        return visitor.running(obj.running);
    }
    if (isStopping(obj)) {
        return visitor.stopping(obj.stopping);
    }
    if (isStopped(obj)) {
        return visitor.stopped(obj.stopped);
    }
    return visitor.unknown(obj);
}
export var IOptimizationState = {
    isStarting: isStarting,
    starting: starting,
    isRunning: isRunning,
    running: running,
    isStopping: isStopping,
    stopping: stopping,
    isStopped: isStopped,
    stopped: stopped,
    visit: visit
};
