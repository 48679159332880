import { Select } from 'antd'
import type { LabeledValue } from 'antd/es/select'
import { flatten, uniq, without } from 'lodash'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { useState } from 'react'

import { isSubsequenceIgnoreCase } from '../../pages/schedule/components/ScheduleSearchSelector/searchUtil'
import { multimapKeysWithValue, transformMapValues } from '../../utils/collections'
import { comparing } from '../../utils/compareUtil'
import Chip from '../Chip'

import { ManyToManySelectorProps } from './types'

// Component to assign one or more values to a fixed set of keys.
// Example: Assign attributes to rooms
//   - The rooms are keys. If three rooms are being edited, the keys prop is a list of the three room ids.
//   - The initial options are all the currently available attributes.
export const ManyToManySelector = <K, V extends string>({
    value = new Map<K, V[]>(),
    onChange = () => {},
    mode,
    preexistingOptionValues,
    optionLabelFn,
    partialCoverSuffix,
    dropdownRenderer
}: ManyToManySelectorProps<K, V>) => {
    const [open, setOpen] = useState(false)

    const transformMapValuesHelper = (valueTransform: (currentVals: V[]) => V[]) => {
        return transformMapValues(value, valueTransform)
    }

    const onClear = () => {
        const newValue = transformMapValuesHelper(() => [])
        onChange(newValue)
    }

    const onSelect = (selectedLabeledValue: any) => {
        const selectedValue = (selectedLabeledValue as LabeledValue).value as V
        const newValue = transformMapValuesHelper((currentVals) => [...currentVals, selectedValue])
        onChange(newValue)
        setOpen(false)
    }

    const onDeselect = (deselectedLabeledValue: any) => {
        const deselectedValue = (deselectedLabeledValue as LabeledValue).value as V
        const newValue = transformMapValuesHelper((currentVals) => without(currentVals, deselectedValue))
        onChange(newValue)
    }

    // In tags mode the user can add alternatives "on the fly" which means that preexistingOptionValues does not include
    // all values in value. We need to create a complete list of options for the tagRender function below.
    const preexistingAndSelectedValues = uniq([...preexistingOptionValues, ...flatten([...value.values()])])
    const options = preexistingAndSelectedValues
        .map((value) => ({ value, label: optionLabelFn(value) }))
        .toSorted(comparing((opt) => opt.label))

    const keysWithValue = (v: V): K[] => multimapKeysWithValue(value, v)

    const tagRender = (props: CustomTagProps) => {
        const keys = keysWithValue(props.value)

        return (
            <Chip {...props} variant="filled" size="xs" onDelete={props.onClose}>
                {props.label}
                {keys.length < value.size && <> {partialCoverSuffix(keys)}</>}
            </Chip>
        )
    }

    const uniqSelectedValues = uniq(flatten(Array.from(value.values()))).map((v) =>
        options.find((opt) => opt.value === v)
    )

    return (
        <Select
            dropdownRender={dropdownRenderer}
            allowClear
            mode={mode}
            open={open}
            onDropdownVisibleChange={setOpen}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            showSearch
            filterOption={(str, opt) => isSubsequenceIgnoreCase(str, opt?.label || '')}
            labelInValue
            maxTagCount={10}
            value={uniqSelectedValues}
            options={options}
            tagRender={tagRender}
        />
    )
}
