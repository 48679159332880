import { useTranslation } from 'react-i18next'

import LecturesActionsPanel from '../../components/lectures/LectureActions'
import { LectureDetails } from '../../components/lectures/LectureDetails'
import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import LecturesTable from './components/LecturesTable'

export const LecturesIndexPage = () => {
    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/lectures')
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    // If selected lecture has been deleted (for example in another browser tab) the selection may have become invalid.
    const validSelectedRowKeys = selectedRowKeys.filter((selectedLectureId) =>
        schedule.doesLectureIdExist(selectedLectureId)
    )

    const actionsPanel = (
        <LecturesActionsPanel
            lectureIds={validSelectedRowKeys}
            onDeleteLecture={() => {
                setSelectedRowKeys([])
            }}
        />
    )
    const detailsPanel = <LectureDetails key={validSelectedRowKeys.join(',')} lectureIds={validSelectedRowKeys} />

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                { key: 'actions', header: t('Actions'), panel: actionsPanel }
            ]}
        >
            <LecturesTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}
