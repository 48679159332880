import { DeleteOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import styles from '../style.module.css'

import { SearchItemContent } from './SearchItemContent'

type SearchItemType = {
    term: string
    url: string
    isSelected: boolean
    searchText: string
    onClick: (term: string) => void
    isFavorite?: boolean
    onFavoriteToggle?: () => void
    onDelete?: () => void
    onClose?: () => void
}

export const SearchItem = ({
    term,
    url,
    isSelected,
    searchText,
    onClick,
    isFavorite,
    onFavoriteToggle,
    onDelete,
    onClose
}: SearchItemType) => {
    return (
        <div
            className={classNames(styles.item, {
                [styles.selected]: isSelected
            })}
        >
            <SearchItemContent term={term} url={url} searchText={searchText} onClick={onClick} onClose={onClose} />
            <div className={styles.icons}>
                {onFavoriteToggle &&
                    (isFavorite ? (
                        <StarFilled className={styles.starIcon} onClick={onFavoriteToggle} />
                    ) : (
                        <StarOutlined className={styles.starIcon} onClick={onFavoriteToggle} />
                    ))}
                {onDelete && <DeleteOutlined className={styles.deleteIcon} onClick={onDelete} />}
            </div>
        </div>
    )
}
