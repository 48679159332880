import { Table } from 'antd'

import type { StudentGroupId } from '../../../../commonTypes'
import TableView from '../../../../EditTable/components/TableView'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { NewStudentGroupButton } from '../NewStudentGroupButton'

import { useStudentGroupColumns } from './columns'
import type { StudentGroupRow, StudentGroupsTableProps } from './types'

const StudentGroupTable = ({
    selectedRowKeys,
    setSelectedRowKeys,
    showOverlappingGroupsColumn = true,
    showAddButton = true
}: StudentGroupsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()

    const { columns, setVisibleColumns } = useStudentGroupColumns(showOverlappingGroupsColumn, filtering)

    const dataSourceUnfiltered = schedule.getStudentGroups().map((studentGroup) => ({ studentGroup }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ studentGroup }) => studentGroup.getDisplayName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableView
            filtering={filtering}
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            handleModeChange={handleModeChange}
            showColumnsSelector={showAddButton}
            CreateButton={({ disabled }) =>
                showAddButton ? (
                    <NewStudentGroupButton
                        disabled={disabled}
                        onStudentGroupCreated={(studentGroupId) => {
                            setSelectedRowKeys([studentGroupId])
                        }}
                    />
                ) : null
            }
        >
            <Table<StudentGroupRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys as StudentGroupId[])
                    }
                }}
                dataSource={dataSource}
                columns={columns}
                showSorterTooltip={false}
                size="small"
                rowKey={({ studentGroup }) => studentGroup.getStudentGroupId()}
                sticky
                onRow={({ studentGroup }) => ({
                    onClick: () => {
                        setSelectedRowKeys([studentGroup.getStudentGroupId()])
                    }
                })}
                style={{ height: '100vh' }}
                pagination={false}
                rowClassName={(record) => `row-${record.studentGroup.getStudentGroupId()}`}
            />
        </TableView>
    )
}

export default StudentGroupTable
