import { Table } from 'antd'

import TableView from '../../../../EditTable/components/TableView'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { useSubjectColumns } from '../columns'
import { NewSubjectButton } from '../NewSubjectButton'

import type { SubjectRow, SubjectsTableProps } from './types'

export const SubjectsTable = ({ selectedRowKeys, setSelectedRowKeys }: SubjectsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useSubjectColumns(filtering)

    const dataSourceUnfiltered = schedule.getSubjects().map((subject) => ({ subject }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ subject }) => subject.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableView
            filtering={filtering}
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            handleModeChange={handleModeChange}
            CreateButton={({ disabled }) => (
                <NewSubjectButton
                    disabled={disabled}
                    onNewSubject={(newSubjectId) => {
                        setSelectedRowKeys([newSubjectId])
                    }}
                />
            )}
        >
            <Table<SubjectRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRows) => {
                        setSelectedRowKeys(newSelectedRows.map(String))
                    }
                }}
                columns={columns}
                dataSource={dataSource}
                showSorterTooltip={false}
                size="small"
                rowKey={({ subject }) => subject.getSubjectId()}
                onRow={({ subject }) => ({
                    onClick: () => {
                        setSelectedRowKeys([subject.getSubjectId()])
                    }
                })}
                pagination={false}
                rowClassName={(record) => `row-${record.subject.getSubjectId()}`}
                sticky
            />
        </TableView>
    )
}
