import { useTranslation } from 'react-i18next'

import { RoomDetails } from '../../components/rooms/RoomDetails'
import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { RoomActionsPanel } from './RoomActionsPanel'
import RoomsTable from './RoomsTable'

export const RoomsIndexPage = () => {
    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/rooms')

    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    // Some selected rooms may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedRoomId) => schedule.doesRoomIdExist(selectedRoomId))

    const selectedRooms = validSelectedRowKeys.map((roomId) => schedule.findRoom(roomId))

    const detailsPanel = (
        <RoomDetails key={`${schedule.getVersion()} ${validSelectedRowKeys.join(',')}`} rooms={selectedRooms} />
    )
    const actionsPanel = (
        <RoomActionsPanel
            rooms={selectedRooms}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                { key: 'actions', header: t('Actions'), panel: actionsPanel }
            ]}
        >
            <RoomsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}
