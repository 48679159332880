import { Link } from 'react-router-dom'

import { FilterHighlight } from '../../dev-util/FilterHighlight'
import { defaultFiltering } from '../types'

export type SearchItemContentProps = {
    term: string
    url: string
    searchText: string
    onClick: (term: string) => void
    onClose?: () => void
}

export const SearchItemContent = ({ term, url, searchText, onClick, onClose }: SearchItemContentProps) => {
    if (url) {
        return (
            <Link to={url} onClick={onClose}>
                <FilterHighlight
                    text={term}
                    filtering={{
                        ...defaultFiltering,
                        filterText: searchText
                    }}
                />
            </Link>
        )
    }

    return (
        <button
            onClick={() => {
                onClick(term)
            }}
            type="button"
        >
            {term}
        </button>
    )
}
