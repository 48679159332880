import { AutoSchedulerTargetMode, IAutoSchedulerState } from 'conjure-ai-schema-server-api'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { ScheduleId } from '../../commonTypes'
import { Endpoints } from '../../services/Endpoints'
import { sseConnectDisconnectLoop } from '../../utils/sse/sse'
import { setAutoSchedulerSseConnectionState } from '../devmode/actions'
import type { ApplicationState } from '../index'

import { autoSchedulerStateUpdate } from './actions'
import { AutoSchedulerActionTypes } from './types'

const apiBaseUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3006/api'

type SetAutoSchedulerTargetModeAction = {
    type: typeof AutoSchedulerActionTypes.LOCALLY_TRIGGERED_AUTO_SCHEDULER_TARGET_MODE_UPDATE
    payload: AutoSchedulerTargetMode
}

function* handleLocallyTriggeredAutoSchedulerTargetModeUpdate(action: SetAutoSchedulerTargetModeAction): any {
    const targetMode = action.payload

    // Fire off update request.
    const scheduleId = (yield select((state: ApplicationState) =>
        state.schedule.schedule.getScheduleId()
    )) as ScheduleId

    yield call(
        [Endpoints.autoSchedulingService, Endpoints.autoSchedulingService.setAutoSchedulerTargetMode],
        scheduleId,
        targetMode
    )
}

function* sseAutoSchedulerStateUpdateEventHandler(event: any): any {
    const newAutoSchedulerState = JSON.parse(event.data) as IAutoSchedulerState
    yield put(autoSchedulerStateUpdate(newAutoSchedulerState))
}

function* autoSchedulerStateSubscriptionsSaga(): any {
    yield call(
        sseConnectDisconnectLoop,
        [AutoSchedulerActionTypes.SUBSCRIBE, AutoSchedulerActionTypes.UNSUBSCRIBE],
        (scheduleId: string) => `${apiBaseUrl}/auto-scheduler/${scheduleId}/updates`,
        'autoscheduler-state-update',
        (s: ApplicationState) => s.autoSchedulerState.subscriptionCount,
        sseAutoSchedulerStateUpdateEventHandler,
        setAutoSchedulerSseConnectionState
    )
}

function* watchAutoSchedulerTargetModeUpdates(): any {
    yield takeEvery(
        AutoSchedulerActionTypes.LOCALLY_TRIGGERED_AUTO_SCHEDULER_TARGET_MODE_UPDATE,
        handleLocallyTriggeredAutoSchedulerTargetModeUpdate
    )
}

function* autoSchedulerSaga() {
    yield all([fork(watchAutoSchedulerTargetModeUpdates), fork(autoSchedulerStateSubscriptionsSaga)])
}

export default autoSchedulerSaga
