import { IScheduleTransform } from 'common-api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import EditModal from '../../../../EditTable/components/EditModal'
import { diff } from '../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'

import RoomListDiff from './components/RoomListDiff'
import { parseRoomRows, roomCmp } from './data'
import { useRoomTableDataState } from './hooks'
import RoomsEditGrid from './RoomsEditGrid'
import { conjureRoomFromRooms, sortedRoomsFromSchedule } from './utils'
import { globalValidationErrors } from './validation'

const RoomsEditTable = () => {
    const schedule = useLocalSchedule()
    const liveRooms = conjureRoomFromRooms(sortedRoomsFromSchedule(schedule))
    const [roomsWhenEditStarted, setRoomsWhenEditStarted] = useState(liveRooms)

    const { roomGridData, setRooms, resetRooms } = useRoomTableDataState()
    const dispatch = useDispatch()

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const onSave = () => {
        const parsedRooms = parseRoomRows(liveRooms, roomGridData)
        const diffToSave = diff(liveRooms, parsedRooms, (room) => room.roomId, roomCmp)

        const roomTransforms = [
            ...diffToSave.deleted.map((roomToDelete) =>
                IScheduleTransform.roomDeleteTransform({ roomId: roomToDelete.roomId })
            ),
            ...diffToSave.updated.map(([_, newRoom]) => IScheduleTransform.roomTransform({ newRoom })),
            ...diffToSave.created.map((roomToCreate) => IScheduleTransform.roomTransform({ newRoom: roomToCreate }))
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(roomTransforms)))
        setIsPreviewOpen(false)

        setRoomsWhenEditStarted(parsedRooms)
    }

    const newRooms = parseRoomRows(liveRooms, roomGridData)
    const diffPreview = diff(liveRooms, newRooms, (room) => room.roomId, roomCmp)

    const validationErrors = globalValidationErrors(schedule, roomGridData)

    return (
        <>
            <EditModal
                isPreviewOpen={isPreviewOpen}
                onSave={onSave}
                setIsPreviewOpen={setIsPreviewOpen}
                diffPreview={diffPreview}
                validationErrors={validationErrors}
                DiffComponent={RoomListDiff}
            />
            <RoomsEditGrid
                rooms={roomGridData}
                setRooms={setRooms}
                onReset={resetRooms}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
                roomsWhenEditingStarted={roomsWhenEditStarted}
            />
        </>
    )
}

export default RoomsEditTable
