import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type SearchItem = {
    id: string
    term: string
    url: string
    timestamp: number
}

type SearchStore = {
    favorites: SearchItem[]
    recentSearches: SearchItem[]
    addFavorite: ({ term, url }: { term: string; url: string }) => void
    removeFavorite: (id: string) => void
    addRecentSearch: (term: string) => void
    removeRecentSearch: (id: string) => void
    clearRecentSearches: () => void
}

export const useSearchStore = create<SearchStore>()(
    persist(
        (set) => ({
            favorites: [],
            recentSearches: [],
            addFavorite: ({ term, url }) => {
                set((state) => ({
                    favorites: [
                        {
                            id: crypto.randomUUID(),
                            term,
                            url,
                            timestamp: Date.now()
                        },
                        ...state.favorites
                    ].slice(0, 10)
                }))
            },
            removeFavorite: (id) => {
                set((state) => ({
                    favorites: state.favorites.filter((item) => item.id !== id)
                }))
            },
            addRecentSearch: (term) => {
                set((state) => ({
                    recentSearches: [
                        {
                            id: crypto.randomUUID(),
                            term,
                            url: '',
                            timestamp: Date.now()
                        },
                        ...state.recentSearches.filter((item) => item.term !== term)
                    ].slice(0, 10)
                }))
            },
            removeRecentSearch: (id) => {
                set((state) => ({
                    recentSearches: state.recentSearches.filter((item) => item.id !== id)
                }))
            },
            clearRecentSearches: () => {
                set(() => ({
                    recentSearches: []
                }))
            }
        }),
        {
            name: 'search-store',
            storage: createJSONStorage(() => localStorage)
        }
    )
)
