import { Flex } from 'antd'
import { useNavigate } from 'react-router'

import { Icon } from '../../../../../icon/src'
import { toTranslate } from '../../../../../utils/miscUtil'
import Button from '../../../../Button'

import { PublishHelpPanelProps } from './types'

export const PublishHelpPanel = ({ onNavigateAway }: PublishHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                Publicera schemat gör du från Meitners adminpanel. För att föra över aktuellt schema till adminpanelen
                klickar du på <em>Överför</em> i menyn, väljer ett schema att föra över till, och klickar på överför.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    variant="primary"
                    startIcon={<Icon name="icon-arrow-right-16" />}
                    onClick={() => {
                        onNavigateAway()
                        navigate('/import-export')
                    }}
                >
                    {toTranslate('Gå till Överför')}
                </Button>
            </Flex>
        </div>
    )
}
