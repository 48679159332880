import { Flex } from 'antd'
import { forwardRef, ReactNode } from 'react'

type EditTableWrapperProps = {
    children: ReactNode
}

const EditTableWrapper = forwardRef<HTMLDivElement, EditTableWrapperProps>(({ children }, ref) => {
    return (
        <Flex vertical style={{ height: '100%', padding: 0, overflowY: 'scroll' }} ref={ref}>
            {children}
        </Flex>
    )
})

export default EditTableWrapper
