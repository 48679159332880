import { SearchOutlined } from '@ant-design/icons'
import { Input, Modal } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Favorites } from './components/Favorites'
import { Recent } from './components/Recent'
import { Results } from './components/Results'
import { useGlobalSearch } from './hooks/useGlobalSearch'
import styles from './style.module.css'

export const GlobalSearch = () => {
    const { t } = useTranslation()
    const { isOpen, searchText, inputRef, results, isSelected, handleInputChange, handlePressEnter, handleClose } =
        useGlobalSearch()
    const [isFocused, setIsFocused] = useState(false)

    const handleSearchTermUpdate = (term: string) => {
        handleInputChange({ target: { value: term } } as React.ChangeEvent<HTMLInputElement>)
    }

    return (
        <Modal
            open={isOpen}
            onCancel={handleClose}
            styles={{
                content: {
                    padding: 0
                }
            }}
            centered
            width={1400}
            footer={null}
            closable={false}
        >
            <Input
                className={styles.input}
                variant="borderless"
                value={searchText}
                onChange={handleInputChange}
                onPressEnter={handlePressEnter}
                placeholder={t('Search')}
                onFocus={() => {
                    setIsFocused(true)
                }}
                onBlur={() => {
                    setIsFocused(false)
                }}
                autoFocus
                prefix={
                    <SearchOutlined
                        className={classNames(styles.searchIcon, { [styles['searchIcon--focused']]: isFocused })}
                    />
                }
                allowClear
                ref={inputRef}
            />
            <div className={classNames(styles.searchResults, { [styles['searchResults--focused']]: isFocused })}>
                <Results
                    results={results}
                    isSearching={searchText.trim().length > 1}
                    isSelected={isSelected}
                    searchText={searchText}
                />
                <Recent
                    searchItemProps={{
                        onClick: handleSearchTermUpdate,
                        isSelected,
                        searchText,
                        onClose: handleClose
                    }}
                />
                <Favorites
                    searchItemProps={{
                        onClick: handleSearchTermUpdate,
                        isSelected,
                        searchText,
                        onClose: handleClose
                    }}
                />
            </div>
        </Modal>
    )
}
