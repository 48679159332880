import { IOptimizationState } from 'conjure-ai-schema-server-api'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScheduleId } from '../../commonTypes'
import { AppError } from '../../utils/errorutil'
import { useActiveOrgId } from '../auth/hooks'
import { ApplicationState } from '../index'

import { subscribe, unsubscribe } from './actions'

export const useAutoSchedulerStateSubscription = (scheduleId: ScheduleId) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(subscribe(scheduleId))

        return () => {
            dispatch(unsubscribe(scheduleId))
        }
    }, [dispatch, scheduleId])
}

export const usePendingAutoSchedulerTargetMode = () =>
    useSelector<ApplicationState>((s) => s.autoSchedulerState.pendingAction)

export const useScheduleOptimizationState = () => {
    const scheduleId = useActiveOrgId()
    useAutoSchedulerStateSubscription(scheduleId)

    return useSelector<ApplicationState, IOptimizationState | undefined>(
        (applicationState) => applicationState.autoSchedulerState.lastKnownState?.scheduleOptState
    )
}

export const useScheduleOptimizationStateOrThrow = () => {
    const scheduleOptimizationState = useScheduleOptimizationState()
    if (scheduleOptimizationState === undefined) {
        throw new AppError('Expected schedule optimization state to be defined')
    }

    return scheduleOptimizationState
}

export const useTeachingAssignmentOptimizationState = () => {
    const scheduleId = useActiveOrgId()
    useAutoSchedulerStateSubscription(scheduleId)

    return useSelector<ApplicationState, IOptimizationState | undefined>(
        (applicationState) => applicationState.autoSchedulerState.lastKnownState?.taOptState
    )
}

