import { Empty, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform, type IWeekSelection } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useFormProps from '../../../form/hooks/useFormProps'
import type { ReservedTimeAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import BulkGeneralInput from '../../BulkGeneralInput'
import BulkInput from '../../BulkInput'
import Button from '../../Button'
import { WeekSelectionInput } from '../../lectures/WeekSelectionInput'
import { MinutesInput } from '../../MinutesInput'
import { ReservedTimeRoomSelector } from '../ReservedTimeRoomSelector'
import { ReservedTimeStudentGroupSelector } from '../ReservedTimeStudentGroupSelector'
import { ReservedTimeTeacherSelector } from '../ReservedTimeTeacherSelector'

import type { ReservedTimeDetailsProps, ReservedTimeFormValues } from './types'

export const ReservedTimeDetails = ({ reservedTimeIds }: ReservedTimeDetailsProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [form] = useForm()
    const { t } = useTranslation()
    const { antFormProps, buttonProps, container } = useFormProps()

    if (reservedTimeIds.length === 0) {
        return <Empty description={t('NoEvent')} />
    }

    const reservedTimes = reservedTimeIds.map((rid) => schedule.findReservedTime(rid))

    reservedTimes.sort(comparing((rt) => rt.getTitle()))

    const formValuesFromAccessors = (reservedTimes: ReservedTimeAccessor[]): ReservedTimeFormValues => ({
        title: reservedTimes.map((rt) => rt.getTitle()),
        teacherIds: new Map(
            reservedTimes.map((rt) => [rt.getReservedTimeId(), rt.getTeachers().map((t) => t.getTeacherId())])
        ),
        studentGroupIds: new Map(
            reservedTimes.map((rt) => [
                rt.getReservedTimeId(),
                rt.getStudentGroups().map((sg) => sg.getStudentGroupId())
            ])
        ),
        roomIds: new Map(reservedTimes.map((rt) => [rt.getReservedTimeId(), rt.getRooms().map((r) => r.getRoomId())])),
        reservedTimeIds: reservedTimes.map((rt) => rt.getReservedTimeId()),
        weekSelections: reservedTimes.map((rt) => rt.getWeekSelection().getConjureWeekSelection()),
        durationsInMinutes: reservedTimes.map((rt) => rt.getDurationInMinutes())
    })

    const saveReservedTimes = (formValues: ReservedTimeFormValues) => {
        const reservedTimeTransforms = formValues.reservedTimeIds.map((reservedTimeId, index) =>
            IScheduleTransform.reservedTimeTransform({
                newReservedTime: {
                    ...reservedTimes[index].getConjureObject(),
                    title: formValues.title[index],
                    teacherIds: formValues.teacherIds.get(reservedTimeId)!,
                    studentGroupIds: formValues.studentGroupIds.get(reservedTimeId)!,
                    roomIds: formValues.roomIds.get(reservedTimeId)!,
                    weekSelection: formValues.weekSelections[index],
                    durationInMinutes: formValues.durationsInMinutes[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(reservedTimeTransforms)))
    }

    return (
        <div ref={container}>
            <Form
                {...antFormProps}
                form={form}
                initialValues={formValuesFromAccessors(reservedTimes)}
                onFinish={saveReservedTimes}
            >
                <Form.List name="reservedTimeIds">
                    {(fields) =>
                        fields.map((field) => (
                            <Form.Item hidden {...field}>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item name="title" label={t('Title')}>
                    <BulkInput multiplicityMessage={t('SelectSingleReservedTimeToChangeTitle')} />
                </Form.Item>
                <Form.Item name="teacherIds" label={t('Teachers')}>
                    <ReservedTimeTeacherSelector />
                </Form.Item>
                <Form.Item name="studentGroupIds" label={t('StudentGroup')}>
                    <ReservedTimeStudentGroupSelector />
                </Form.Item>
                <Form.Item name="roomIds" label={t('Rooms')}>
                    <ReservedTimeRoomSelector />
                </Form.Item>
                <Form.Item name="weekSelections" label={t('Weeks')}>
                    <BulkGeneralInput<IWeekSelection>
                        renderInput={(value, onChange) => (
                            <WeekSelectionInput value={value} onChange={onChange} layout="vertical" />
                        )}
                        multiplicityMessage={t('SelectSingleReservedTimeToChangeWeekSettings')}
                    />
                </Form.Item>
                <Form.Item name="durationsInMinutes" label={t('Duration')}>
                    <BulkGeneralInput<number>
                        renderInput={(value, onChange) => <MinutesInput value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleReservedTimeToChangeLength')}
                    />
                </Form.Item>
                <Form.Item {...buttonProps}>
                    <Button variant="primary" type="submit" fullWidth size="md">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
