import classes from './style.module.css'
import { ProgressBarProps } from './types'

export const ProgressBar = ({ progress }: ProgressBarProps) => {
    return (
        <div className={classes.progressContainer}>
            {progress > 0 && (
                <div className={classes.progressBarBackground}>
                    <div className={classes.progressBar} style={{ width: `${progress * 100}%` }}></div>
                </div>
            )}
        </div>
    )
}
