import type { AutoSchedulerAction } from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerState } from './types'

const initialState: AutoSchedulerState = {
    lastKnownState: null,
    pendingAction: null,
    subscriptionCount: 0
}

export const autoSchedulerReducer = (state = initialState, action: AutoSchedulerAction): AutoSchedulerState => {
    switch (action.type) {
        case AutoSchedulerActionTypes.LOCALLY_TRIGGERED_AUTO_SCHEDULER_TARGET_MODE_UPDATE: {
            return {
                ...state,
                pendingAction: action.payload
            }
        }
        case AutoSchedulerActionTypes.SERVER_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE: {
            return {
                ...state,
                lastKnownState: action.payload,

                // This solution is not perfect since there could potentially be a race. There's no guarantee that the
                // server triggered update processed in this action is associated with the currently pending action.
                // In the schedule solution we have versions associated with each request and each update from the
                // server, so we can be more precise when clearing the queue of pending actions.
                pendingAction: null
            }
        }
        case AutoSchedulerActionTypes.SUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount + 1
            }
            console.log(
                `[sse autoscheduler reducer] action = SUBSCRIBE. new subscriptionCount: ${newState.subscriptionCount}`
            )

            return newState
        }
        case AutoSchedulerActionTypes.UNSUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount - 1
            }
            console.log(
                `[sse autoscheduler reducer] action = UNSUBSCRIBE. subscriptionCount: ${newState.subscriptionCount}`
            )

            return newState
        }
        default: {
            return state
        }
    }
}
