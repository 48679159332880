import { IStudentGroup } from 'common-api'

import { ScheduleAccessor, StudentGroupAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { combinedCmpFn, comparing } from '../../../../../utils/compareUtil'

import { StudentGroupGridRow, StudentGroupsGridContentColumn, StudentGroupsGridMetaDataColumn } from './types'

export const sortedStudentGroupsFromSchedule = (schedule: ScheduleAccessor) => {
    return schedule.getStudentGroups().toSorted(combinedCmpFn(comparing((r) => r.getDisplayName())))
}

export const conjureStudentGroupFromStudentGroups = (studentGroups: StudentGroupAccessor[]): IStudentGroup[] => {
    return studentGroups.map((studentGroup) => studentGroup.getConjureObject())
}

export const isEmptyStudentGroupsRow = (studentGroupRow: StudentGroupGridRow) =>
    [
        StudentGroupsGridContentColumn.DisplayName,
        StudentGroupsGridContentColumn.Labels,
        StudentGroupsGridContentColumn.Description
    ].every((col) => studentGroupRow[col].trim() === '')

export const generateEmptyStudentGroupsRow = (rowIndex: number): StudentGroupGridRow => {
    return {
        [StudentGroupsGridMetaDataColumn.RowIndex]: rowIndex,
        [StudentGroupsGridContentColumn.DisplayName]: '',
        [StudentGroupsGridContentColumn.Labels]: '',
        [StudentGroupsGridContentColumn.Description]: ''
    }
}
