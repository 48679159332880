import { AutoSchedulerTargetMode, IAutoSchedulerState } from 'conjure-ai-schema-server-api'

export enum AutoSchedulerActionTypes {
    LOCALLY_TRIGGERED_AUTO_SCHEDULER_TARGET_MODE_UPDATE = 'autoScheduler/LOCALLY_TRIGGERED_TARGET_MODE_UPDATE',
    SERVER_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE = 'autoScheduler/SERVER_TRIGGERED_STATE_UPDATE',

    SUBSCRIBE = 'autoScheduler/subscribe',
    UNSUBSCRIBE = 'autoScheduler/unsubscribe'
}

export type AutoSchedulerState = {
    // State is null if for example no state update has been received since the page was loaded.
    lastKnownState: IAutoSchedulerState | null
    pendingAction: AutoSchedulerTargetMode | null
    subscriptionCount: number
}
