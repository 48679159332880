import { InputRef } from 'antd'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useKeyPress from '../../../hooks/useKeyPress'
import { useSearchStore } from '../store'

import { NavigableItem, useKeyboardNavigation } from './useKeyboardNavigation'
import { useSearchResults } from './useSearchResults'

export const useGlobalSearch = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchText, setSearchTerm] = useState('')
    const inputRef = useRef<InputRef>(null)
    const [selectedItem, setSelectedItem] = useState<NavigableItem | null>(null)

    const handleClose = useCallback(() => {
        setIsOpen(false)
        setSelectedItem(null)
    }, [setSelectedItem])

    const handleReset = useCallback(() => {
        setSearchTerm('')
        setSelectedItem(null)
    }, [setSelectedItem])

    const { results, handleSearch } = useSearchResults({
        onClose: handleClose,
        onReset: handleReset
    })

    const { addRecentSearch, recentSearches, favorites } = useSearchStore()

    const handleSearchTermUpdate = useCallback((term: string) => {
        setSearchTerm(term)
    }, [])

    const navigableItems = useMemo(() => {
        const items = [
            ...results.map((result) => ({
                id: `result-${result.id}`,
                onClick: result.onClick,
                section: 'results' as const
            })),
            ...recentSearches.map((recent) => ({
                id: `recent-${recent.id}`,
                onClick: () => {
                    handleSearchTermUpdate(recent.term)
                },
                section: 'recent' as const
            })),
            ...favorites.map((favorite) => ({
                id: `favorite-${favorite.id}`,
                onClick: () => {
                    handleSearchTermUpdate(favorite.term)
                },
                section: 'favorites' as const
            }))
        ]

        return items
    }, [results, recentSearches, favorites, handleSearchTermUpdate])

    const { isSelected } = useKeyboardNavigation({ navigableItems, selectedItem, setSelectedItem })

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch(searchText)
        }, 0)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [searchText, handleSearch])

    useKeyPress({
        key: 'k',
        onKeyPress: () => {
            setIsOpen((prev) => !prev)
        },
        elementToFocus: inputRef
    })

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleSearchTermUpdate(e.target.value)
        },
        [handleSearchTermUpdate]
    )

    const handlePressEnter = useCallback(() => {
        if (selectedItem) {
            selectedItem.onClick()
        }

        addRecentSearch(searchText)
    }, [selectedItem, addRecentSearch, searchText])

    return {
        isOpen,
        searchText,
        inputRef,
        results,
        isSelected,
        handleInputChange,
        handlePressEnter,
        handleClose
    }
}
