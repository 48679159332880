import classNames from 'classnames'

import TypeScale from '../../../styles/TypeScale'
import { toTranslate } from '../../../utils/miscUtil'
import { useSearchStore } from '../store'
import styles from '../style.module.css'
import { type SearchItemProps } from '../types'

import { SearchItem } from './SearchItem'

type FavoritesProps = {
    searchItemProps: SearchItemProps
}

export const Favorites = ({ searchItemProps }: FavoritesProps) => {
    const { favorites, removeFavorite } = useSearchStore()
    const { isSelected, searchText, onClick, onClose } = searchItemProps

    if (favorites.length === 0) return null

    return (
        <div>
            <p className={classNames(TypeScale.Label_MD_Semibold, styles.label)}>{toTranslate('Favorites')}</p>
            <div className={styles.list}>
                {favorites.map((favorite) => (
                    <SearchItem
                        key={favorite.id}
                        term={favorite.term}
                        url={favorite.url}
                        isSelected={isSelected(`favorite-${favorite.id}`)}
                        searchText={searchText}
                        onClick={onClick}
                        onClose={onClose}
                        isFavorite={true}
                        onFavoriteToggle={() => {
                            removeFavorite(favorite.id)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
