import { Tooltip } from 'antd'

import { comparing } from '../../../../utils/compareUtil'

import classes from './style.module.css'
import { EntityListTooltipProps } from './types'

export const EntityListTooltip = <T,>({
    title,
    entities,
    entityLabelFn,
    entityKeyFn,
    singularEntityLabel,
    pluralEntityLabel
}: EntityListTooltipProps<T>) => (
    <span className={classes.entityListTooltip}>
        (
        <Tooltip
            placement="bottom"
            title={
                <>
                    {title}
                    <ul>
                        {entities.sort(comparing(entityLabelFn)).map((r) => (
                            <li key={entityKeyFn(r)}>{entityLabelFn(r)}</li>
                        ))}
                    </ul>
                </>
            }
        >
            <span className={classes.entityListTooltip__link}>
                {entities.length} {entities.length === 1 ? singularEntityLabel : pluralEntityLabel}
            </span>
        </Tooltip>
        )
    </span>
)
