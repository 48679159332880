import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Dropdown, Modal, Popover, Space, Splitter } from 'antd'
import type { IWeekSelection } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../components/Button'
import { WeekSelectionInput } from '../../components/lectures/WeekSelectionInput'
import { ScrollGradientMaskWrapper } from '../../EditTable/components/ScrollGradientMaskWrapper'
import usePersistedSplitter from '../../hooks/usePersistedSplitterWidth'
import type { ApplicationState } from '../../store'
import { cancelBlockedTimeSelection, startBlockedTimeSelection } from '../../store/blockedtime/actions'
import { setWeekSelection } from '../../store/weekselector/actions'

import { AutomationControlPanelWithSuspense } from './components/automation-control/AutomationControlPanelWithSuspense'
import MultipleSchedules from './components/MultipleSchedules'
import { PanelSider } from './components/PanelSider'
import { Schedule } from './components/Schedule/Schedule'
import { ScheduleSearchSelector } from './components/ScheduleSearchSelector'
import { BlockedTimeSelectionZIndex } from './components/types'
import ViewModeOptions from './components/ViewModeOptions'
import { useScheduleStore } from './store'
import classes from './style.module.css'

const ScheduleIndexPage = () => {
    const dispatch = useDispatch()
    const weekSelection = useSelector<ApplicationState, IWeekSelection>((s) => s.weekSelection)
    const { t } = useTranslation()
    const blockedTimeSelectionModeActive = useSelector<ApplicationState, boolean>(
        (state) => state.blockedTimeSelection.selectionModeActive
    )

    const { splitterPanelProps, onResizeEnd } = usePersistedSplitter(true)

    const { viewingMode } = useScheduleStore()

    const onStartBlockedTimeSelection = () => {
        dispatch(startBlockedTimeSelection())
    }

    const onCancelBlockedTimeSelection = () => {
        dispatch(cancelBlockedTimeSelection())
    }

    const createMenu = {
        items: [
            {
                label: t('ReservedTime'),
                key: '2',
                icon: <CloseCircleOutlined />,
                onClick: onStartBlockedTimeSelection
            }
        ]
    }

    return (
        <div className={classes.wrapper}>
            <Splitter onResizeEnd={onResizeEnd}>
                <Splitter.Panel>
                    <div className={classes.innerWrapper}>
                        <div className={classes.controlBar}>
                            <Modal
                                mask={false}
                                footer={null}
                                closable={false}
                                open={blockedTimeSelectionModeActive}
                                styles={{
                                    body: {
                                        padding: '0.5em'
                                    }
                                }}
                                className={classes.modalStyle}
                                wrapClassName={classes.modalWrap}
                                zIndex={1}
                            >
                                <div className={classes.blockedTime}>
                                    <div>{t('MarkedBlockedTime')}</div>
                                    <Button variant="secondary" onClick={onCancelBlockedTimeSelection}>
                                        {t('Cancel')}
                                    </Button>
                                </div>
                            </Modal>
                            <ScheduleSearchSelector />
                            <Space>
                                <WeekSelectionInput
                                    layout="horizontal"
                                    value={weekSelection}
                                    onChange={(newWeekSelection) => dispatch(setWeekSelection(newWeekSelection))}
                                />
                                <Popover content={<ViewModeOptions />} trigger="click">
                                    <Button variant="secondary" size="sm">
                                        {t('ViewOptions')}
                                    </Button>
                                </Popover>
                                <Modal
                                    mask
                                    footer={null}
                                    closable={false}
                                    open={blockedTimeSelectionModeActive}
                                    className={classes.modalStyle}
                                    bodyStyle={{ padding: '0.5em' }}
                                    zIndex={BlockedTimeSelectionZIndex.MASK}
                                >
                                    <div className={classes.modalContent}>
                                        <p>{t('MarkedBlockedTime')}</p>
                                        <Button variant="secondary" onClick={onCancelBlockedTimeSelection}>
                                            {t('Cancel')}
                                        </Button>
                                    </div>
                                </Modal>
                                <Dropdown menu={createMenu}>
                                    <Button variant="primary" size="sm" startIcon={<PlusOutlined />}>
                                        {t('Create')}
                                    </Button>
                                </Dropdown>
                            </Space>
                        </div>
                        <div className={classes.scheduleContainer}>
                            <ScrollGradientMaskWrapper>
                                <div style={{ paddingRight: '10px' }}>
                                    {viewingMode === 'columns' ? (
                                        <Schedule renderMultipleMode="columns" />
                                    ) : (
                                        <MultipleSchedules />
                                    )}
                                </div>
                            </ScrollGradientMaskWrapper>
                        </div>
                        <AutomationControlPanelWithSuspense />
                    </div>
                </Splitter.Panel>
                <Splitter.Panel {...splitterPanelProps}>
                    <PanelSider />
                </Splitter.Panel>
            </Splitter>
        </div>
    )
}

export default ScheduleIndexPage
