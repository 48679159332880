import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

// Custom hook to handle URL parameter selection and navigation
const useUrlParamSelection = (pathToNavigateTo: string) => {
    const { id } = useParams<Params>()

    const [selectedRowKeys, setSelectedRowKeys] = useState(id ? [id] : [])
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            setSelectedRowKeys([id])

            const element = document.querySelector(`.row-${id}`)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }

            // Reset url param after setting the selected row keys
            // to keep the source of truth in the url. Its like a redirect
            setTimeout(() => {
                navigate(pathToNavigateTo)
            }, 100)
        }
    }, [id, navigate, pathToNavigateTo])

    return { selectedRowKeys, setSelectedRowKeys }
}

export default useUrlParamSelection
