import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CourseRoundDetails } from '../../components/course-rounds/CourseRoundDetails'
import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { CourseRoundActionsPanel } from './components/CourseRoundActionsPanel'
import CourseRoundsTable from './components/CourseRoundsTable'

type Params = { id: string }

const CourseRoundsIndexPage = () => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const { id } = useParams<Params>()

    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/course-rounds')

    // Some selected course rounds may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedCrId) => schedule.doesCourseRoundIdExist(selectedCrId))

    const selectedCourseRounds = validSelectedRowKeys.map((courseRoundId) => schedule.findCourseRound(courseRoundId))

    const detailsPanel = (
        <CourseRoundDetails
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    const actionsPanel = (
        <CourseRoundActionsPanel
            onDelete={() => {
                setSelectedRowKeys([])
            }}
            key={validSelectedRowKeys.join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        >
            <CourseRoundsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}

export default CourseRoundsIndexPage
