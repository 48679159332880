import { Cell, Row } from '@silevis/reactgrid'
import { IRoom } from 'common-api'
import { merge } from 'lodash'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useRef } from 'react'

import EditTable from '../../../../EditTable'
import { BackgroundChangesAlert } from '../../../../EditTable/components/BackgroundChangesAlert'
import EditTableWrapper from '../../../../EditTable/components/EditTableWrapper'
import { GridActionsFooter } from '../../../../EditTable/components/GridActionsFooter'
import { DEFAULT_CONTENT_CELL, HEADER_ROW, MIN_ROWS_TO_SHOW } from '../../../../EditTable/constants'
import { useFocusedCell } from '../../../../EditTable/hooks'
import {
    anyDiff,
    DEFAULT_HEADER_CELL,
    diff,
    generateEmptyRows,
    handleClearTableContent,
    getIndexCell
} from '../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { handleCopy } from '../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handleCopy'
import { handlePaste } from '../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handlePaste'

import SimpleRoomListDiff from './components/SimpleRoomListDiff'
import { parseRoomRows, roomCmp } from './data'
import { useRoomGridColumns } from './hooks'
import { ALL_CONTENT_COLUMNS, RoomGridMetaDataColumn, RoomGridRow } from './types'
import { conjureRoomFromRooms, generateEmptyRoomRow } from './utils'
import { roomCellValidationErrors } from './validation'

type RoomsEditTableProps = {
    rooms: RoomGridRow[]
    setRooms: Dispatch<SetStateAction<RoomGridRow[]>>
    onReset: () => void
    onSave: () => void
    roomsWhenEditingStarted: IRoom[]
}

export default function RoomsEditGrid({
    rooms,
    setRooms,
    onReset,
    onSave,
    roomsWhenEditingStarted
}: RoomsEditTableProps) {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useRoomGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveRooms = conjureRoomFromRooms(schedule.getRooms())

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<IRoom>(roomsWhenEditingStarted, liveRooms, (r) => r.roomId, roomCmp)

    const parsedFromCurrentEditTable = parseRoomRows(liveRooms, rooms)
    const changesMade = anyDiff(diff(parsedFromCurrentEditTable, liveRooms, (r) => r.roomId, roomCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - rooms.length)
    const newEmptyRows = generateEmptyRows(rooms.length, emptyRowsToAdd, generateEmptyRoomRow)
    const roomGridData = [...rooms, ...newEmptyRows]

    const rows = [
        HEADER_ROW,
        ...roomGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        const fromIndex = rooms.length
        const withEmptyRows = generateEmptyRows(fromIndex, numRows, generateEmptyRoomRow)

        setRooms([...roomGridData, ...withEmptyRows])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    const validationProblems = roomCellValidationErrors(rooms)

    roomGridData.forEach((roomRow, roomIndex) => {
        const rowIndex = roomIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === RoomGridMetaDataColumn.RowIndex) {
                cells.push(getIndexCell(rowIndex))
            } else {
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: roomRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newTeacherGridData = [...roomGridData]
                                    newTeacherGridData[roomIndex][gridColumn] = newText
                                    setRooms(newTeacherGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        {}
                    )
                )
            }
        })
    })

    const changedData = [
        ...backgroundUpdates.updated.map(([prev]) => prev),
        ...backgroundUpdates.created,
        ...backgroundUpdates.deleted
    ]

    return (
        <EditTableWrapper ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "room data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const newRooms = handlePaste<RoomGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: roomGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptyRoomRow(dstRowIndex),
                        indexLocation: targetCell
                    })

                    setRooms(newRooms)
                }}
            />
            {anyDiff(backgroundUpdates) ? (
                <BackgroundChangesAlert
                    diff={backgroundUpdates}
                    changedData={changedData}
                    DiffPopupComponent={<SimpleRoomListDiff diff={backgroundUpdates} />}
                    namesList={changedData.map((room) => room.name).toSorted(comparing((n) => n.length))}
                />
            ) : null}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={() => {
                    handleClearTableContent({
                        rows: roomGridData,
                        generateEmptyRow: generateEmptyRoomRow,
                        setData: setRooms
                    })
                }}
            />
        </EditTableWrapper>
    )
}
