import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalSchedule } from '../../../store/schedule/hooks'
import { useSearchStore } from '../store'
import { Result, SearchType } from '../types'

type UseSearchResultsProps = {
    onClose: () => void
    onReset: () => void
}

const calculateRelevanceScore = (searchText: string, itemText: string): number => {
    const normalizedSearch = searchText.toLowerCase()
    const normalizedItem = itemText.toLowerCase()

    // Exact match gets highest score
    if (normalizedItem === normalizedSearch) return 100

    // Starts with gets second highest score
    if (normalizedItem.startsWith(normalizedSearch)) return 75

    // Word boundary match gets third highest score
    const wordBoundaryRegex = new RegExp(`\\b${normalizedSearch}`, 'i')
    if (wordBoundaryRegex.test(normalizedItem)) return 50

    // Contains gets lowest score
    if (normalizedItem.includes(normalizedSearch)) return 25

    return 0
}

export const resultByType = (results: Result[], type: SearchType): Result[] =>
    results.filter((result) => result.type === type)

export const getResultByTypes = (results: Result[]) => ({
    teachers: resultByType(results, SearchType.Teacher),
    subjects: resultByType(results, SearchType.Subject),
    courses: resultByType(results, SearchType.Course),
    rooms: resultByType(results, SearchType.Room),
    studentGroups: resultByType(results, SearchType.StudentGroup)
})

export const useSearchResults = ({ onClose, onReset }: UseSearchResultsProps) => {
    const [results, setResults] = useState<Result[]>([])

    const schedule = useLocalSchedule()
    const { addRecentSearch } = useSearchStore()
    const navigate = useNavigate()

    const handleOnClick = useCallback(
        (url: string, searchText: string) => {
            onClose()
            navigate(url)
            addRecentSearch(searchText)
        },
        [onClose, addRecentSearch, navigate]
    )

    const handleSearch = useCallback(
        (searchText: string) => {
            const trimmedSearch = searchText.trim().toLowerCase()

            if (trimmedSearch.length <= 0) {
                setResults([])
                onReset()

                return
            }

            const matchedResults: (Result & { score: number })[] = []

            // Teachers
            schedule.getTeachers().forEach((teacher) => {
                const title = `${teacher.getFirstName()} ${teacher.getLastName()}`
                const score = calculateRelevanceScore(trimmedSearch, title.toLowerCase())
                const url = `/teachers/${teacher.getTeacherId()}`
                if (score > 0) {
                    matchedResults.push({
                        id: teacher.getTeacherId(),
                        title,
                        type: SearchType.Teacher,
                        onClick: () => {
                            handleOnClick(url, searchText)
                        },
                        score,
                        url
                    })
                }
            })

            // Subjects
            schedule.getSubjects().forEach((subject) => {
                const title = subject.getName()
                const score = calculateRelevanceScore(trimmedSearch, title.toLowerCase())
                const url = `/subjects/${subject.getSubjectId()}`
                if (score > 0) {
                    matchedResults.push({
                        id: subject.getSubjectId(),
                        title,
                        type: SearchType.Subject,
                        onClick: () => {
                            handleOnClick(url, searchText)
                        },
                        score,
                        url
                    })
                }
            })

            // Courses
            schedule.getCourses().forEach((course) => {
                const title = course.getName()
                const score = calculateRelevanceScore(trimmedSearch, title.toLowerCase())
                const url = `/courses/${course.getCourseId()}`
                if (score > 0) {
                    matchedResults.push({
                        id: course.getCourseId(),
                        title,
                        type: SearchType.Course,
                        onClick: () => {
                            handleOnClick(url, searchText)
                        },
                        score,
                        url
                    })
                }
            })

            // Rooms
            schedule.getRooms().forEach((room) => {
                const title = room.getName()
                const score = calculateRelevanceScore(trimmedSearch, title.toLowerCase())
                const url = `/rooms/${room.getRoomId()}`
                if (score > 0) {
                    matchedResults.push({
                        id: room.getRoomId(),
                        title,
                        type: SearchType.Room,
                        onClick: () => {
                            handleOnClick(url, searchText)
                        },
                        score,
                        url
                    })
                }
            })

            // Student Groups
            schedule.getStudentGroups().forEach((group) => {
                const title = group.getDisplayName()
                const score = calculateRelevanceScore(trimmedSearch, title.toLowerCase())
                const url = `/student-groups/${group.getStudentGroupId()}`

                if (score > 0) {
                    matchedResults.push({
                        id: group.getStudentGroupId(),
                        title,
                        type: SearchType.StudentGroup,
                        onClick: () => {
                            handleOnClick(url, searchText)
                        },
                        score,
                        url
                    })
                }
            })

            const sortedResults = matchedResults.sort((a, b) => b.score - a.score).map(({ score, ...result }) => result)
            setResults(sortedResults)
        },
        [schedule, handleOnClick, onReset]
    )

    return {
        results,
        handleSearch
    }
}
