import { ICourseRound, Terms } from 'common-api'

import {
    acceptCourseOrSubject,
    CourseAccessor,
    CourseRoundAccessor,
    ScheduleAccessor,
    SubjectAccessor
} from '../../../../../schedule-access/scheduleAccessWrappers'
import { isBlank } from '../../../../../textUtil'
import { combinedCmpFn, comparing } from '../../../../../utils/compareUtil'
import { toTranslate } from '../../../../../utils/miscUtil'

import {
    ALL_CONTENT_COLUMNS,
    CourseRoundGridContentColumn,
    CourseRoundGridMetaDataColumn,
    CourseRoundGridRow
} from './types'

export const sortedCourseRoundsFromSchedule = (schedule: ScheduleAccessor) => {
    return schedule.getCourseRounds().toSorted(combinedCmpFn(comparing((r) => r.getDisplayName())))
}

const commaSepTeacherSignatures = (courseRound: CourseRoundAccessor): string =>
    courseRound
        .getTeachers()
        .map((t) => t.getTeacherSchoolId())
        .join(',')

export const termsToString = (terms: Terms): string => {
    if (terms === Terms.FALL) {
        return toTranslate('HT')
    }

    if (terms === Terms.SPRING) {
        return toTranslate('VT')
    }

    return toTranslate('Läsår')
}

export const conjureCourseRoundsFromCourses = (courseRounds: CourseRoundAccessor[]): ICourseRound[] => {
    return courseRounds.map((courseRound) => courseRound.getConjureObject())
}

export const isEmptyCourseRoundsRow = (courseRoundRow: CourseRoundGridRow) =>
    ALL_CONTENT_COLUMNS.every((col) => isBlank(courseRoundRow[col]))

export const generateEmptyCourseRoundRow = (rowIndex: number): CourseRoundGridRow => {
    return {
        [CourseRoundGridMetaDataColumn.RowIndex]: rowIndex,
        [CourseRoundGridContentColumn.Name]: '',
        [CourseRoundGridContentColumn.CourseOrSubjectCode]: '',
        [CourseRoundGridContentColumn.TotalHours]: '',
        [CourseRoundGridContentColumn.TeacherSignatures]: '',
        [CourseRoundGridContentColumn.Terms]: '',
        [CourseRoundGridContentColumn.StudentGroup]: ''
    }
}

const courseOrSubjectCode = (courseOrSubject: CourseAccessor | SubjectAccessor): string =>
    acceptCourseOrSubject(courseOrSubject, {
        course: (course) => course.getCode(),
        subject: (subject) => subject.getCode()
    })

export const courseRoundToCourseRoundRow = (
    courseRound: CourseRoundAccessor,
    rowIndex: number
): CourseRoundGridRow => ({
    [CourseRoundGridMetaDataColumn.RowIndex]: rowIndex,
    [CourseRoundGridContentColumn.Name]: courseRound.getDisplayName(),
    [CourseRoundGridContentColumn.CourseOrSubjectCode]: courseOrSubjectCode(courseRound.getCourseOrSubject()),
    [CourseRoundGridContentColumn.TotalHours]: courseRound.getTotalHours().toString(),
    [CourseRoundGridContentColumn.TeacherSignatures]: commaSepTeacherSignatures(courseRound),
    [CourseRoundGridContentColumn.Terms]: termsToString(courseRound.getTerms()),
    [CourseRoundGridContentColumn.StudentGroup]: courseRound.getStudentGroup().getDisplayName()
})
