import { IScheduleTransform } from 'common-api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import EditModal from '../../../../../EditTable/components/EditModal'
import { diff } from '../../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'

import { TeacherListDiff } from './TeacherBulkUpdatePreview/TeacherListDiff'
import { TeacherGrid } from './TeacherGrid'
import { teacherCmp } from './TeacherGrid/diff'
import {
    conjureSubjectsFromSubjects,
    conjureTeacherFromTeachers,
    sortedTeachersFromSchedule,
    useTeacherTableDataState
} from './TeacherGrid/hooks'
import { parseTeacherRows } from './TeacherGrid/teacherData'
import { globalValidationErrors } from './TeacherGrid/validation'

export const TeacherEditTable = () => {
    const schedule = useLocalSchedule()
    const liveTeachers = conjureTeacherFromTeachers(sortedTeachersFromSchedule(schedule))
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())

    const { teacherGridData, setTeachers, resetTeachers } = useTeacherTableDataState()
    const dispatch = useDispatch()

    const [teachersWhenEditStarted, setTeachersWhenEditStarted] = useState(liveTeachers)

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const onSave = () => {
        const parsedTeachers = parseTeacherRows(liveTeachers, liveSubjects, teacherGridData)
        const diffToSave = diff(liveTeachers, parsedTeachers, (teacher) => teacher.teacherId, teacherCmp)
        const teacherTransforms = [
            ...diffToSave.deleted.map((teacherToDelete) =>
                IScheduleTransform.teacherDeleteTransform({ teacherId: teacherToDelete.teacherId })
            ),
            ...diffToSave.updated.map(([_, newTeacher]) => IScheduleTransform.teacherTransform({ newTeacher })),
            ...diffToSave.created.map((teacherToCreate) =>
                IScheduleTransform.teacherTransform({ newTeacher: teacherToCreate })
            )
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(teacherTransforms)))
        setIsPreviewOpen(false)

        setTeachersWhenEditStarted(parsedTeachers)
    }

    const validationErrors = globalValidationErrors(schedule, teacherGridData)
    const parsedTeachers = parseTeacherRows(liveTeachers, liveSubjects, teacherGridData)
    const diffPreview = diff(liveTeachers, parsedTeachers, (teacher) => teacher.teacherId, teacherCmp)

    return (
        <>
            <EditModal
                isPreviewOpen={isPreviewOpen}
                onSave={onSave}
                setIsPreviewOpen={setIsPreviewOpen}
                diffPreview={diffPreview}
                validationErrors={validationErrors}
                DiffComponent={TeacherListDiff}
            />
            <TeacherGrid
                teachers={teacherGridData}
                setTeachers={setTeachers}
                onReset={resetTeachers}
                teachersWhenEditingStarted={teachersWhenEditStarted}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
            />
        </>
    )
}
