import Icon, { LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { AutoSchedulerTargetMode, IOptimizationState } from 'conjure-ai-schema-server-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SvgPlayFilled } from '../../icons/SvgPlayFilled'
import { SvgSquareFilled } from '../../icons/SvgSquareFilled'
import { locallyTriggeredAutoSchedulerTargetModeUpdate } from '../../store/autoscheduler/actions'
import { usePendingAutoSchedulerTargetMode, useScheduleOptimizationState } from '../../store/autoscheduler/hooks'

import classes from './style.module.css'

export const AutoSchedulerStartStopButton = () => {
    const dispatch = useDispatch()
    const scheduleOptimizationState = useScheduleOptimizationState()
    const pendingAutoSchedulerTargetMode = usePendingAutoSchedulerTargetMode()
    const { t } = useTranslation()

    const START_TEXT = t('start')
    const STOP_TEXT = t('stop')

    const handleStop = () => dispatch(locallyTriggeredAutoSchedulerTargetModeUpdate(AutoSchedulerTargetMode.STOP))
    const handleStart = () =>
        dispatch(locallyTriggeredAutoSchedulerTargetModeUpdate(AutoSchedulerTargetMode.RUN_SCHEDULE_OPTIMIZATION))

    if (scheduleOptimizationState === undefined) {
        return null
    }

    const modeChangeIsPending = pendingAutoSchedulerTargetMode !== null

    const buttonIcon = modeChangeIsPending ? (
        <LoadingOutlined />
    ) : (
        IOptimizationState.visit(scheduleOptimizationState, {
            starting: () => <LoadingOutlined />,
            running: () => <Icon component={SvgSquareFilled} />,
            stopping: () => <LoadingOutlined />,
            stopped: () => <Icon component={SvgPlayFilled} />,
            unknown: () => <LoadingOutlined />
        })
    )

    const buttonText =
        pendingAutoSchedulerTargetMode === AutoSchedulerTargetMode.STOP
            ? START_TEXT
            : pendingAutoSchedulerTargetMode === AutoSchedulerTargetMode.RUN_SCHEDULE_OPTIMIZATION
              ? STOP_TEXT
              : IOptimizationState.visit(scheduleOptimizationState, {
                    starting: () => START_TEXT,
                    running: () => STOP_TEXT,
                    stopping: () => STOP_TEXT,
                    stopped: () => START_TEXT,
                    unknown: () => START_TEXT
                })

    const onClickHandler = modeChangeIsPending
        ? undefined
        : IOptimizationState.visit(scheduleOptimizationState, {
              starting: () => undefined,
              running: () => handleStop,
              stopping: () => undefined,
              stopped: () => handleStart,
              unknown: () => undefined
          })

    return (
        <Button disabled={onClickHandler === undefined} icon={buttonIcon} onClick={onClickHandler}>
            <div>
                <div>{buttonText}</div>

                {/* Ensure button always has the same width*/}
                <div className={classes.placeholderText}>{START_TEXT}</div>
                <div className={classes.placeholderText}>{STOP_TEXT}</div>
            </div>
        </Button>
    )
}
