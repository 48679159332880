import { message } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { activateDevMode, deactivateDevMode } from '../../store/devmode/actions'
import { useDevModeState } from '../../store/devmode/hooks'

import { CLICK_TIMEOUT, CLICKS_TO_TOGGLE_DEV_MODE } from './constants'
import { useRapidCounterState } from './hooks'
import logo from './logo.svg'
import classes from './style.module.css'

type MeitnerLogoProps = {
    className?: string
}

export const MeitnerLogo = (props: MeitnerLogoProps) => {
    const { className } = props
    const [incClickCounter, resetClickCounter] = useRapidCounterState(CLICK_TIMEOUT)
    const dispatch = useDispatch()
    const devMode = useDevModeState()
    const { t } = useTranslation()

    const handleClick = () => {
        const newClickCount = incClickCounter()
        if (newClickCount >= CLICKS_TO_TOGGLE_DEV_MODE) {
            const newDevMode = !devMode
            dispatch(newDevMode ? activateDevMode() : deactivateDevMode())
            message.info(`Utvecklarläge ${newDevMode ? 'aktiverat' : 'inaktiverat'}`)
            resetClickCounter()
        }
    }

    return (
        <button className={classNames(classes.logoButton, className)} onClick={handleClick} type="button">
            {/*  eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={logo} alt={t('LogoName')} height={24} />
        </button>
    )
}
