import { ICourseRound } from 'common-api'
import { useTranslation } from 'react-i18next'

import { SimpleListDiff } from '../../../../../../EditTable/components/SimpleListDiff'
import { DiffResult } from '../../../../../../EditTable/utils'
import { toTranslate } from '../../../../../../utils/miscUtil'

export type SimpleCourseRoundListDiffProps = {
    diff: DiffResult<ICourseRound>
}

const SimpleCourseRoundListDiff = ({ diff }: SimpleCourseRoundListDiffProps) => {
    const { t } = useTranslation()

    return (
        <SimpleListDiff
            diff={diff}
            getDisplayName={(courseRound) => courseRound.displayName}
            getPropertyDiffs={(prev, next) => [
                {
                    label: toTranslate('Kursomgång'),
                    prevValue: prev.courseRoundId,
                    nextValue: next.courseRoundId
                },
                {
                    label: t('Name'),
                    prevValue: prev.displayName,
                    nextValue: next.displayName
                }
            ]}
            translations={{
                newItems: toTranslate('Nya kursomgångar'),
                updatedItems: toTranslate('Uppdaterade kursomgångar'),
                deletedItems: toTranslate('Borttagna kursomgångar')
            }}
        />
    )
}

export default SimpleCourseRoundListDiff
