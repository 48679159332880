import type { ITimeOfDay } from 'common-api'
import { range } from 'lodash'

import { useLocalSchedule } from '../../../../store/schedule/hooks'
import brandColors from '../../../../styles/colors/brandColors'
import styled from '../../../../utils/styled'
import { positioningHeightRaw, positioningTopRaw } from '../util'

import classes from './style.module.css'

export const TimeLine = () => {
    const schedule = useLocalSchedule()

    // Todo: Remove the ! part since these will not be optional after migration is done
    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const firstHourMark = startOfDay.minute === 0 ? startOfDay.hour : startOfDay.hour + 1
    const lastHourMark = endOfDay.hour

    return (
        <div className={classes.timeLine}>
            {range(firstHourMark, lastHourMark + 1).map((hour) => (
                <div key={hour}>
                    {hour === endOfDay.hour ? null : (
                        <HourWrapper startOfDay={startOfDay} endOfDay={endOfDay} hour={hour} minute={30}>
                            <HourLine />
                        </HourWrapper>
                    )}
                    <HourWrapper startOfDay={startOfDay} endOfDay={endOfDay} hour={hour} minute={-30}>
                        <div className={classes.timeLineHourHeader}>
                            <div className={classes.timeLineHourTextWrapper}>
                                <span className={classes.timeLineHourText}>{hour < 10 ? `0${hour}` : hour}:00</span>
                            </div>
                        </div>
                    </HourWrapper>
                </div>
            ))}
        </div>
    )
}

interface HourProps {
    startOfDay: ITimeOfDay
    endOfDay: ITimeOfDay
    hour: number
    minute: number
}

const HourWrapper = styled('div')<HourProps>`
    position: absolute;
    top: ${(props) => positioningTopRaw(props.startOfDay, props.endOfDay, props.hour, props.minute)}%;
    height: ${(props) => positioningHeightRaw(props.startOfDay, props.endOfDay, 60)}%;
    width: 100%;
`

const HourLine = styled('div')`
    position: absolute;
    left: 40px;
    width: calc(100% - 40px);
    border-top: 1px dashed ${brandColors.gray10};
    margin-top: -1px;
`
