import { Column } from '@silevis/reactgrid'

export enum CourseRoundGridMetaDataColumn {
    RowIndex = 0
}

export enum CourseRoundGridContentColumn {
    Name = 1,
    CourseOrSubjectCode = 2,
    TotalHours = 3,
    TeacherSignatures = 4,
    Terms = 5,
    StudentGroup = 6
}

export const ALL_CONTENT_COLUMNS = [
    CourseRoundGridContentColumn.Name,
    CourseRoundGridContentColumn.CourseOrSubjectCode,
    CourseRoundGridContentColumn.TotalHours,
    CourseRoundGridContentColumn.TeacherSignatures,
    CourseRoundGridContentColumn.Terms,
    CourseRoundGridContentColumn.StudentGroup
]

export type CourseRoundGridRow = Record<CourseRoundGridContentColumn, string> & {
    [CourseRoundGridMetaDataColumn.RowIndex]: number
}

const ALL_COLUMNS = [CourseRoundGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_ROOM_COLUMNS = ALL_COLUMNS.length

export type CourseRoundGridColumnSpec = Column & {
    title: string
    gridColumn: CourseRoundGridMetaDataColumn | CourseRoundGridContentColumn
}
