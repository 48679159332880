import { Flex } from 'antd'
import { useNavigate } from 'react-router'

import { Icon } from '../../../../../icon/src'
import { toTranslate } from '../../../../../utils/miscUtil'
import Button from '../../../../Button'
import { HelpPanelDivider } from '../HelpPanelDivider'
import { ScreenshotImage } from '../ScreenshotImage'

import assignRoomImage from './room-assignment.png'
import { ScheduleLecturesHelpPanelProps } from './types'

export const ScheduleLecturesHelpPanel = ({ onNavigateAway }: ScheduleLecturesHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                När lektioner skapas så dyker dom upp under schemat, vilket betyder att de inte fått någon tid
                tilldelad. Det är då upp till dig som schemaläggare att placera ut lektionerna där dom ska vara. Detta
                gör du enklast genom att klicka på Schema i menyn, välja lämpligt schema, och dra och släppa lektioner.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    variant="primary"
                    startIcon={<Icon name="icon-arrow-right-16" />}
                    onClick={() => {
                        onNavigateAway()
                        navigate('/schedule')
                    }}
                >
                    {toTranslate('Gå till Schemavyn')}
                </Button>
            </Flex>
            <HelpPanelDivider />
            <p>
                Du behöver också tilldela rum till alla lektioner. Detta gör du genom att klicka på en lektion och välja
                sal i lektionens redigeringsmeny.
            </p>
            <ScreenshotImage src={assignRoomImage} />
            <HelpPanelDivider />
            <p>Både tid och sal kan tilldelas automatiskt av den automatiska schemaläggaren.</p>
        </div>
    )
}
