import { Splitter } from 'antd'
import { IOptimizationState } from 'conjure-ai-schema-server-api'
import { useCallback, useEffect } from 'react'

import usePersistedSplitter from '../../hooks/usePersistedSplitterWidth'
import { AutomationControlPanelWithSuspense } from '../../pages/schedule/components/automation-control/AutomationControlPanelWithSuspense'
import { useScheduleOptimizationState } from '../../store/autoscheduler/hooks'
import { FullHeightCollapse } from '../FullHeightCollapse'

import classes from './style.module.css'
import { SplitterPageProps } from './types'

const SplitterPage = <R,>({ onEscape, selectedRowKeys, sidePanels, children }: SplitterPageProps<R>) => {
    const showSidePanel = selectedRowKeys.length > 0
    const { splitterPanelProps, onResizeEnd } = usePersistedSplitter(showSidePanel)
    const scheduleOptimizationState = useScheduleOptimizationState()

    const isOptimizationRunning =
        scheduleOptimizationState !== undefined &&
        IOptimizationState.visit(scheduleOptimizationState, {
            starting: () => false,
            running: () => true,
            stopping: () => false,
            stopped: () => false,
            unknown: () => false
        })

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onEscape()
            }
        },
        [onEscape]
    )

    useEffect(() => {
        document.addEventListener('keydown', escFunction)

        return () => {
            document.removeEventListener('keydown', escFunction)
        }
    }, [escFunction])

    const items = sidePanels.map(({ key, header, panel }) => ({
        key,
        label: header,
        children: panel
    }))

    return (
        <Splitter onResizeEnd={onResizeEnd} style={{ overflow: 'hidden' }}>
            <Splitter.Panel>
                <div className={classes.mainPanel}>
                    <div className={classes.mainPanelContent}>{children}</div>
                    {isOptimizationRunning && (
                        <div className={classes.mainPanelFooter}>
                            <AutomationControlPanelWithSuspense
                                automationControlPanelProps={{ panelInitiallyOpen: false }}
                            />
                        </div>
                    )}
                </div>
            </Splitter.Panel>
            <Splitter.Panel {...splitterPanelProps}>
                {showSidePanel ? (
                    <FullHeightCollapse defaultActiveKey={['edit', 'actions']} bordered={false} items={items} />
                ) : null}
            </Splitter.Panel>
        </Splitter>
    )
}

export default SplitterPage
