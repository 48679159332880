import { Column } from '@silevis/reactgrid'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { ITeacher } from 'common-api'
import { Dispatch, SetStateAction } from 'react'

import { ProblemListProblem } from '../../../../../schedule/components/ProblemsList/types'

export enum TeacherGridMetaDataColumn {
    RowIndex = 0
}

export enum TeacherGridContentColumn {
    Signature = 1,
    FirstName,
    LastName,
    Qualifications,
    WorkPercentage
}

export const ALL_CONTENT_COLUMNS = [
    TeacherGridContentColumn.Signature,
    TeacherGridContentColumn.FirstName,
    TeacherGridContentColumn.LastName,
    TeacherGridContentColumn.Qualifications,
    TeacherGridContentColumn.WorkPercentage
]

const ALL_COLUMNS = [TeacherGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_TEACHER_COLUMNS = ALL_COLUMNS.length

export type TeacherGridRow = Record<TeacherGridContentColumn, string> & {
    [TeacherGridMetaDataColumn.RowIndex]: number
}

export type TableEditValidationProblem = {
    location?: IndexedLocation // Why is location optional? Either it's a ProblemListProblem, or it's a cell problem, no?
    problem: ProblemListProblem
}

export type TeacherGridProps = {
    teachers: TeacherGridRow[]
    setTeachers: Dispatch<SetStateAction<TeacherGridRow[]>>
    onReset: () => void
    onSave: () => void
    teachersWhenEditingStarted: ITeacher[]
}

export type GridColumnSpec = Column & {
    title: string
    gridColumn: TeacherGridMetaDataColumn | TeacherGridContentColumn
}
