import type { IDayAndTime, ITimeOfDay } from 'common-api'
import { DayOfWeek } from 'common-api'
import dayjs, { type Dayjs } from 'dayjs'

import { MINUTES_PER_SLOT } from '../pages/schedule/components/util'

import { combinedCmpFn, comparing } from './compareUtil'
import { AppError } from './errorutil'
import { intDiv } from './mathUtil'

const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = DayOfWeek

export const ALL_DAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]
export const ALL_WEEKDAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY]

const dayAsNumber = (day: DayOfWeek) => ALL_DAYS.indexOf(day)
const hourOfDayAndTime = (dat: IDayAndTime) => dat.hour
const minOfDayAndTime = (dat: IDayAndTime) => dat.minute

export type TimeRange = {
    from: IDayAndTime // inclusive
    to: IDayAndTime // exclusive
}

export const addMinutes = (dat: IDayAndTime, minutes: number): IDayAndTime => {
    const [quo, rem] = intDiv(dat.minute + minutes, 60)
    const minute = rem
    const hour = dat.hour + quo

    return { day: dat.day, hour, minute }
}

export const dayOfWeekComparator = comparing((d: DayOfWeek) => dayAsNumber(d))

const datComparator = combinedCmpFn<IDayAndTime>(
    comparing((dat) => dayAsNumber(dat.day)),
    comparing(hourOfDayAndTime),
    comparing(minOfDayAndTime)
)

const isBeforeOrEqual = (dat1: IDayAndTime, dat2: IDayAndTime) => datComparator(dat1, dat2) <= 0
const max = (dat1: IDayAndTime, dat2: IDayAndTime) => (datComparator(dat1, dat2) < 0 ? dat2 : dat1)
const min = (dat1: IDayAndTime, dat2: IDayAndTime) => (datComparator(dat1, dat2) < 0 ? dat1 : dat2)

export const intersection = (r1: TimeRange, r2: TimeRange) => {
    const maxFrom = max(r1.from, r2.from)
    const minTo = min(r1.to, r2.to)

    // Throw if there is no overlap
    if (isBeforeOrEqual(minTo, maxFrom)) {
        throw new AppError("Can't create intersection", { r1, r2 })
    }

    return { from: maxFrom, to: minTo }
}

const daysHoursMinutesSeconds = (seconds: number): [number, number, number, number] => [
    Math.floor(seconds / (3600 * 24)),
    Math.floor((seconds % (3600 * 24)) / 3600),
    Math.floor((seconds % 3600) / 60),
    Math.floor(seconds % 60)
]

export const formatDurationFromSeconds = (totalSeconds: number) => {
    const [days, hours, minutes, seconds] = daysHoursMinutesSeconds(totalSeconds)

    const parts = []
    if (days > 0) {
        parts.push(`${days} d`)
    }

    if (hours > 0) {
        parts.push(`${hours} h`)
    }

    if (minutes > 0) {
        parts.push(`${minutes} min`)
    }

    if (seconds > 0 || parts.length === 0) {
        parts.push(`${seconds} s`)
    }

    return parts.join(' ')
}

export const hoursToSlots = (hours: number) => (hours * 60) / MINUTES_PER_SLOT

const SE_DAYNAMES = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag']

export const seDayName = (day: DayOfWeek) => SE_DAYNAMES[dayAsNumber(day)]

export const twoDigits = (n: number) => (n < 10 ? '0' : '') + n

export const formatHHMM = ({ hour, minute }: { hour: number; minute: number }) =>
    `${twoDigits(hour)}:${twoDigits(minute)}`

export const formatOptionalHHMM = (datOpt: IDayAndTime | undefined) => (datOpt === undefined ? '' : formatHHMM(datOpt))

export const formatOptionalDay = (day: DayOfWeek | undefined) => (day === undefined ? '' : day.toLowerCase())

// Conversion between dayjs and ITimeOfDay
export const dayjsFromTimeOfDay = (tod: ITimeOfDay) => dayjs().set('hour', tod.hour).set('minute', tod.minute)
export const timeOfDayFromDayjs = (dayjs: Dayjs) => ({
    hour: dayjs.hour(),
    minute: dayjs.minute()
})
