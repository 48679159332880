import i18next from 'i18next'

export const MAX_LISTING_CLAUSES = 5

export const textualListing = (strs: string[], entityLbl: string, connective: string = i18next.t('And')) => {
    // Num.    Before  After  Example
    // strs    'and'   'and'
    // -------------------------------
    // 1       1       0      ABC
    // 2       1       1      ABC and DEF
    // 3       2       1      ABC, DEF and GHI
    // 4       3       1      ABC, DEF, GHI and JKL
    // 5       4       1      ABC, DEF, GHI, JKL and MNO
    // 6       4       2      ABC, DEF, GHI, JKL and 2 more
    // 7       4       3      ABC, DEF, GHI, JKL and 3 more
    const numPartsBeforeConnective = Math.max(1, Math.min(strs.length, MAX_LISTING_CLAUSES) - 1)
    const partsBeforeConnective = strs.slice(0, numPartsBeforeConnective)
    const partsAfterConnective = strs.slice(numPartsBeforeConnective)

    const afterConnective =
        partsAfterConnective.length === 0
            ? ''
            : partsAfterConnective.length === 1
              ? ` ${connective} ${partsAfterConnective[0]}`
              : ` ${connective} ${partsAfterConnective.length} andra ${entityLbl}`

    return `${partsBeforeConnective.join(', ')} ${afterConnective}`.trim()
}

export const splitAndCleanCommaSepList = (commaSepStr: string) =>
    commaSepStr.split(/\s*,\s*/).filter((s) => s.length > 0)

export const isBlank = (str: string) => str.trim().length === 0
