import { useTranslation } from 'react-i18next'

import SplitterPage from '../../components/SplitterPage'
import TeacherDetails from '../../components/teachers/TeacherDetails'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { TeacherActionsPanel } from './components/TeacherActionsPanel'
import { TeachersTable } from './components/TeachersTable'

export const TeachersPage = () => {
    const schedule = useLocalSchedule()
    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/teachers')

    const { t } = useTranslation()

    // Some selected teachers may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((teacherId) => schedule.doesTeacherIdExist(teacherId))

    const detailsPanel = (
        <TeacherDetails
            key={String(schedule.getVersion()) + validSelectedRowKeys.join(',')}
            teacherIds={validSelectedRowKeys}
        />
    )

    const actionsPanel = (
        <TeacherActionsPanel
            key={validSelectedRowKeys.join(',')}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
            teacherIds={validSelectedRowKeys}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        >
            <TeachersTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}
