import classNames from 'classnames'

import TypeScale from '../../../styles/TypeScale'
import { toTranslate } from '../../../utils/miscUtil'
import { useSearchStore } from '../store'
import styles from '../style.module.css'
import { type SearchItemProps } from '../types'

import { SearchItem } from './SearchItem'

type RecentProps = {
    searchItemProps: SearchItemProps
}

export const Recent = ({ searchItemProps }: RecentProps) => {
    const { recentSearches, removeRecentSearch } = useSearchStore()
    const { favorites, addFavorite, removeFavorite } = useSearchStore()
    const { isSelected, searchText, onClick, onClose } = searchItemProps

    if (recentSearches.length === 0) return null

    return (
        <div>
            <p className={classNames(TypeScale.Label_MD_Semibold, styles.label)}>{toTranslate('Recent')}</p>
            <div className={styles.list}>
                {recentSearches.map((recent) => {
                    const favorite = favorites.find((favorite) => favorite.term === recent.term)

                    return (
                        <SearchItem
                            key={recent.id}
                            term={recent.term}
                            onClose={onClose}
                            url={recent.url}
                            isSelected={isSelected(`recent-${recent.id}`)}
                            searchText={searchText}
                            onClick={onClick}
                            isFavorite={Boolean(favorite)}
                            onFavoriteToggle={() => {
                                if (favorite) {
                                    removeFavorite(favorite.id)
                                } else {
                                    addFavorite({ term: recent.term, url: recent.url })
                                }
                            }}
                            onDelete={() => {
                                removeRecentSearch(recent.id)
                            }}
                        />
                    )
                })}
            </div>
        </div>
    )
}
