import { Table } from 'antd'

import type { CourseId } from '../../../../commonTypes'
import TableView from '../../../../EditTable/components/TableView'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { NewCourseButton } from '../NewCourseButton'

import { useCourseColumns } from './columns'
import type { CourseRow, CoursesTableProps } from './types'

export const CoursesTable = ({ selectedRowKeys, setSelectedRowKeys }: CoursesTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useCourseColumns(filtering)

    const dataSourceUnfiltered = schedule.getCourses().map((course) => ({ course }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ course }) => course.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableView
            filtering={filtering}
            CreateButton={({ disabled }) => (
                <NewCourseButton
                    disabled={disabled}
                    onNewCourse={(newCourseId) => {
                        setSelectedRowKeys([newCourseId])
                    }}
                />
            )}
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            handleModeChange={handleModeChange}
        >
            <Table<CourseRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRows) => {
                        setSelectedRowKeys(newSelectedRows as CourseId[])
                    }
                }}
                dataSource={dataSource}
                columns={columns}
                showSorterTooltip={false}
                size="small"
                rowKey={({ course }) => course.getCourseId()}
                sticky
                onRow={({ course }) => ({
                    onClick: () => {
                        setSelectedRowKeys([course.getCourseId()])
                    }
                })}
                style={{ height: '100%' }}
                pagination={false}
                rowClassName={(record) => `row-${record.course.getCourseId()}`}
            />
        </TableView>
    )
}
