import { Layout } from 'antd'
import { useEffect } from 'react'
import { Outlet } from 'react-router'

import { AuthenticatedOnly } from '../AuthenticatedOnly'
import { GlobalSearch } from '../GlobalSearch'

import MenuSider from './MenuSider'

const { Content } = Layout

export function Root() {
    useEffect(() => {
        console.log(`Version: ${process.env.REACT_APP_VERSION || 'unknown'}`)
    }, [])

    return (
        <AuthenticatedOnly>
            <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
                <MenuSider />
                <Content style={{ backgroundColor: '#fdfdfd', width: 0 }}>
                    <GlobalSearch />
                    <Outlet />
                </Content>
            </Layout>
        </AuthenticatedOnly>
    )
}
