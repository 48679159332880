import { Flex } from 'antd'
import { Outlet } from 'react-router'

import TableCard from '../../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import ScheduleSuspense from '../../../../components/ScheduleSuspense'
import { TableEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch'
import { useEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch/useEditModeSwitch'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { AddTeacherButton } from '../NewTeacherButton'

import { useTeacherColumns } from './columns'
import './table-card.css'
import { TeacherViewTable } from './TeacherViewTable'
import type { TeachersTableProps } from './types'

export const TeachersTable = ({ selectedRowKeys, setSelectedRowKeys }: TeachersTableProps) => {
    const { isEditModeToggled } = useEditModeSwitch()
    const filtering = useFiltering()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useTeacherColumns(filtering)

    const dataSource = schedule
        .getTeachers()
        .map((teacher) => ({ teacher }))
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ teacher }) => teacher.getTeacherSchoolId()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableCard
            title={
                <Flex gap={8} align="center">
                    <FilterInput {...filtering} disabled={isEditModeToggled || isDummySchedule} />
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={isEditModeToggled || isDummySchedule}
                    />
                    <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                    <AddTeacherButton
                        onTeacherCreated={(newTeacherId) => {
                            setSelectedRowKeys([newTeacherId])
                        }}
                        disabled={isEditModeToggled}
                    />
                </Flex>
            }
        >
            {isEditModeToggled ? (
                <ScheduleSuspense>
                    <Outlet />
                </ScheduleSuspense>
            ) : (
                <TeacherViewTable
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    columns={columns}
                    dataSource={dataSource}
                />
            )}
        </TableCard>
    )
}
