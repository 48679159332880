import { Dropdown, MenuProps } from 'antd'
import { DayOfWeek } from 'common-api'
import { useEffect } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'

import { ComponentJoiner } from '../../../components/ComponentJoiner'
import { useLectureActions } from '../../../components/lectures/LectureActions/useLectureActions'
import { ApplicationState } from '../../../store'
import {
    setLectureSelection,
    setReservedTimeSelection,
    toggleLectureSelection,
    toggleReservedTimeSelection
} from '../../../store/lectureselector/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { WeekDaySelectorState } from '../../../store/weekDaySelector/types'
import brandColors from '../../../styles/colors/brandColors'
import { DndItemTypes } from '../../../utils/DndItemTypes'
import { toTranslate } from '../../../utils/miscUtil'
import styled from '../../../utils/styled'
import { Positioned } from '../styled'

import { Lecture } from './Lecture/Lecture'
import { ReservedTime } from './ReservedTime'
import { RoomNameAndPin } from './RoomNameAndPin'
import type { ScheduleEvent } from './ScheduleEvent'
import type { LayoutSpec } from './util'
import { positioningZIndex } from './util'

interface PositionedLectureProps {
    event: ScheduleEvent
    layoutSpec: LayoutSpec
    transition?: string
}

export const EventComponent = ({
    event,
    layoutSpec,
    transition = 'width 0.5s ease 0s, top 1s ease 0s, left 1s ease 0s'
}: PositionedLectureProps) => {
    const dispatch = useDispatch()
    const zIndex = positioningZIndex(layoutSpec)
    const schedule = useLocalSchedule()

    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: DndItemTypes.EVENT,
            item: { event, layoutSpec },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [event, layoutSpec]
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    const opacity = isDragging ? 0.3 : 1

    const lecture = event.accept({
        visitLecture: (lectureEvent) => lectureEvent.getLecture(),
        visitReservedTime: () => null
    })

    const actions = useLectureActions(lecture ? [lecture.getLectureId()] : [], () => {})
    const isScheduled = lecture?.getDayAndTime() !== undefined

    const items: MenuProps['items'] = lecture
        ? [
              {
                  label: toTranslate('Fäst dag och tid'),
                  key: '1',
                  onClick: () => {
                      actions.handlePinDayAndTime()
                  }
              },
              {
                  label: toTranslate('Duplicera lektion'),
                  key: '2',
                  onClick: () => {
                      if (lecture) {
                          actions.handleDuplicate(lecture)
                      }
                  }
              },
              {
                  label: toTranslate('Flytta till lapplådan'),
                  key: '3',
                  disabled: !isScheduled,
                  onClick: () => {
                      actions.handleUnscheduleLecture()
                  }
              },
              {
                  label: toTranslate('Dela upp block'),
                  key: '4',
                  disabled: !isScheduled,
                  children: [
                      {
                          label: toTranslate('Hela blocket'),
                          key: '1',
                          onClick: () => {
                              actions.handleBlockSplit(true)
                          }
                      },
                      {
                          label: toTranslate('Lektion'),
                          key: '2',
                          onClick: () => {
                              actions.handleBlockSplit(false)
                          }
                      }
                  ]
              },
              {
                  label: toTranslate('Ta bort lektion'),
                  key: '5',
                  danger: true,
                  onClick: () => {
                      actions.handleDeleteLecture()
                  }
              }
          ]
        : []

    return (
        <Dropdown menu={{ items }} trigger={['contextMenu']}>
            <Positioned
                startOfDay={startOfDay}
                endOfDay={endOfDay}
                ref={drag}
                transition={transition}
                opacity={opacity}
                layoutSpec={layoutSpec}
                duration={event.getDurationInMinutes()}
                zIndex={zIndex}
                selectedWeekDays={selectedWeekDays}
            >
                {event.accept({
                    visitLecture: (lecture) => (
                        <Lecture
                            dayAndTime={layoutSpec.type === 'allocated' ? layoutSpec.dayAndTime : undefined}
                            lecture={lecture.getLecture()}
                            onClick={(e) => {
                                dispatch(
                                    e.shiftKey || e.ctrlKey
                                        ? toggleLectureSelection(lecture.getLecture().getLectureId())
                                        : setLectureSelection([lecture.getLecture().getLectureId()])
                                )
                            }}
                        >
                            <CourseRoundName>{lecture.getLecture().getCourseRound().getDisplayName()}</CourseRoundName>
                            <div>
                                <ComponentJoiner
                                    components={lecture
                                        .getLecture()
                                        .getRoomAssignments()
                                        .filter((ra) => ra.getRoom() !== undefined)
                                        .map((ra) => (
                                            <RoomNameAndPin
                                                roomName={ra.getRoom()!.getName()}
                                                key={ra.getRoom()?.getRoomId()}
                                                pinned={ra.isPinned()}
                                            />
                                        ))}
                                />
                            </div>
                            <div>
                                <ComponentJoiner
                                    components={lecture
                                        .getLecture()
                                        .getEffectiveTeachers()
                                        .map((t) => t.getTeacherSchoolId())}
                                />
                            </div>
                        </Lecture>
                    ),
                    visitReservedTime: (reservedTimeEvent) => {
                        const startTime =
                            layoutSpec.type === 'allocated'
                                ? layoutSpec.dayAndTime
                                : { day: DayOfWeek.MONDAY, hour: 0, minute: 0 }

                        return (
                            <ReservedTime
                                dayAndTime={startTime}
                                reservedTime={reservedTimeEvent.getReservedTime()}
                                onClick={(e) => {
                                    dispatch(
                                        e.shiftKey || e.ctrlKey
                                            ? toggleReservedTimeSelection(
                                                  reservedTimeEvent.getReservedTime().getReservedTimeId()
                                              )
                                            : setReservedTimeSelection([
                                                  reservedTimeEvent.getReservedTime().getReservedTimeId()
                                              ])
                                    )
                                }}
                            >
                                {reservedTimeEvent.getReservedTime().getTitle().length > 0 && (
                                    <div style={{ lineHeight: '1em' }}>
                                        <span
                                            style={{
                                                color: 'white',
                                                background: brandColors.blocker,
                                                padding: '3px 2px 2px 2px'
                                            }}
                                        >
                                            {reservedTimeEvent.getReservedTime().getTitle()}
                                        </span>
                                    </div>
                                )}
                            </ReservedTime>
                        )
                    }
                })}
            </Positioned>
        </Dropdown>
    )
}

export default EventComponent

const CourseRoundName = styled('div')`
    font-size: 10px;
    font-weight: bold;
    overflow: hidden;
`
