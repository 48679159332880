import { Table } from 'antd'

import { RoomId } from '../../../commonTypes'
import TableView from '../../../EditTable/components/TableView'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import { rowFilter } from '../../../utils/filtering'
import { useFiltering } from '../../schedule/components/FilterInput/hooks'
import { NewRoomButton } from '../NewRoomButton'

import { useRoomColumns } from './columns'
import type { RoomRow, RoomsTableProps } from './types'

const RoomsTable = ({ selectedRowKeys, setSelectedRowKeys }: RoomsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useRoomColumns(filtering)

    const dataSourceUnfiltered = schedule.getRooms()
    const dataSource = dataSourceUnfiltered
        .map((room) => ({ room }))
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ room }) => room.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableView
            filtering={filtering}
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            handleModeChange={handleModeChange}
            CreateButton={({ disabled }) => (
                <NewRoomButton
                    disabled={disabled}
                    onNewRoom={(newRoomId) => {
                        setSelectedRowKeys([newRoomId])
                    }}
                />
            )}
        >
            <Table<RoomRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys as RoomId[])
                    }
                }}
                columns={columns}
                dataSource={dataSource}
                size="small"
                rowKey={({ room }) => room.getRoomId()}
                onRow={({ room }) => ({
                    onClick: () => {
                        setSelectedRowKeys([room.getRoomId()])
                    }
                })}
                pagination={false}
                sticky
                rowClassName={(record) => `row-${record.room.getRoomId()}`}
            />
        </TableView>
    )
}

export default RoomsTable
