import { Select, type SelectProps } from 'antd'
import { ICourseOrSubjectId } from 'common-api'
import { useTranslation } from 'react-i18next'

import type { CourseId, SubjectId } from '../../../commonTypes'
import { isSubsequenceIgnoreCase } from '../../../pages/schedule/components/ScheduleSearchSelector/searchUtil'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'

import type { CourseOrSubjectSelectorProps } from './types'

const COURSE_ID_PREFIX = 'course'
const SUBJECT_ID_PREFIX = 'subject'
const PREFIX_ID_SEP = '_'

// Serialize value to string
const courseOrSubjectIdToString = (cos: ICourseOrSubjectId) =>
    ICourseOrSubjectId.visit(cos, {
        subjectId: (subjectId: SubjectId) => `${SUBJECT_ID_PREFIX}${PREFIX_ID_SEP}${subjectId}`,
        courseId: (courseId: CourseId) => `${COURSE_ID_PREFIX}${PREFIX_ID_SEP}${courseId}`,
        unknown: () => 'unknown'
    })

// Deserialize string to value
const stringToCourseOrSubjectId = (str: string) => {
    const [idType, id] = str.split(PREFIX_ID_SEP)

    return idType === COURSE_ID_PREFIX ? ICourseOrSubjectId.courseId(id) : ICourseOrSubjectId.subjectId(id)
}

export const CourseOrSubjectSelector = (props: CourseOrSubjectSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const onChangeWrapper = (newValue: string) => {
        if (typeof props.onChange === 'function') {
            props.onChange(stringToCourseOrSubjectId(newValue))
        }
    }

    const subjectOptions = schedule
        .getSubjects()
        .map((subject) => ({
            value: courseOrSubjectIdToString(ICourseOrSubjectId.subjectId(subject.getSubjectId())),
            label: subject.getName()
        }))
        .toSorted(comparing((opt) => opt.label))

    const courseOptions = schedule
        .getCourses()
        .map((course) => ({
            value: courseOrSubjectIdToString(ICourseOrSubjectId.courseId(course.getCourseId())),
            label: course.getName()
        }))
        .toSorted(comparing((opt) => opt.label))

    const showCourseOptions = courseOptions.length > 0

    const options: SelectProps['options'] = showCourseOptions
        ? [
              { label: t('CourseOrSubject.Subjects'), options: subjectOptions },
              { label: t('CourseOrSubject.Courses'), options: courseOptions }
          ]
        : subjectOptions

    // Render an option with a "course" or "subject" suffix for clarification of option type
    const optionRenderHelper = (strValue: string) => {
        const [label, typeOfOption] = ICourseOrSubjectId.visit(stringToCourseOrSubjectId(strValue), {
            courseId: (cid) => [schedule.findCourse(cid).getName(), t('CourseOrSubject.Course')],
            subjectId: (sid) => [schedule.findSubject(sid).getName(), t('CourseOrSubject.Subject')],
            unknown: () => ['unknown', 'unknown']
        })

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{label}</div>
                <div style={{ color: '#ccc', fontSize: 'small' }}>{typeOfOption}</div>
            </div>
        )
    }

    return (
        <Select
            options={options}
            optionLabelProp="label"
            showSearch
            labelRender={showCourseOptions ? (props) => optionRenderHelper(props.value as string) : undefined}
            optionRender={showCourseOptions ? (option) => optionRenderHelper(option.value as string) : undefined}
            filterOption={(filterPhrase, option) =>
                isSubsequenceIgnoreCase(filterPhrase, (option?.label as string) || '')
            }
            value={courseOrSubjectIdToString(props.value)}
            onChange={onChangeWrapper}
        />
    )
}
