import { AutoSchedulerTargetMode } from 'conjure-ai-schema-server-api'
import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'

import { ScheduleId } from '../../commonTypes'

import type * as autoSchedulerActions from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerState } from './types'

export const locallyTriggeredAutoSchedulerTargetModeUpdate = (targetMode: AutoSchedulerTargetMode) =>
    action(AutoSchedulerActionTypes.LOCALLY_TRIGGERED_AUTO_SCHEDULER_TARGET_MODE_UPDATE, targetMode)

export const autoSchedulerStateUpdate = (newState: AutoSchedulerState['lastKnownState']) =>
    action(AutoSchedulerActionTypes.SERVER_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE, newState)

export const subscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.SUBSCRIBE, scheduleId)
export const unsubscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.UNSUBSCRIBE, scheduleId)

export type AutoSchedulerAction = ActionType<typeof autoSchedulerActions>
