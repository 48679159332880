import { Flex } from 'antd'

import { toTranslate } from '../../../../../utils/miscUtil'

import classes from './style.module.css'
import { ScreenshotImageProps } from './types'

export const ScreenshotImage = ({ src }: ScreenshotImageProps) => (
    <Flex justify="space-around">
        <img className={classes.screenshotImage} src={src} alt={toTranslate('Screenshot')} />
    </Flex>
)
