import { useCallback, useEffect } from 'react'

export type NavigableItem = {
    id: string
    onClick: () => void
    section: 'results' | 'recent' | 'favorites'
}

type KeyboardNavigationProps = {
    navigableItems: NavigableItem[]
    selectedItem: NavigableItem | null
    setSelectedItem: (item: NavigableItem | null) => void
}

export const useKeyboardNavigation = ({ navigableItems, selectedItem, setSelectedItem }: KeyboardNavigationProps) => {
    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (!navigableItems.length) return

            switch (e.key) {
                case 'ArrowDown': {
                    e.preventDefault()
                    const currentIndex = selectedItem
                        ? navigableItems.findIndex((item) => item.id === selectedItem.id)
                        : -1
                    const nextIndex = currentIndex === navigableItems.length - 1 ? 0 : currentIndex + 1
                    setSelectedItem(navigableItems[nextIndex])
                    break
                }
                case 'ArrowUp': {
                    e.preventDefault()
                    const currentIndex = selectedItem
                        ? navigableItems.findIndex((item) => item.id === selectedItem.id)
                        : 0
                    const prevIndex = currentIndex <= 0 ? navigableItems.length - 1 : currentIndex - 1
                    setSelectedItem(navigableItems[prevIndex])
                    break
                }
                case 'Enter': {
                    if (selectedItem) {
                        e.preventDefault()
                        selectedItem.onClick()
                    }

                    break
                }
            }
        },
        [navigableItems, selectedItem, setSelectedItem]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown])

    return {
        isSelected: (id: string) => selectedItem?.id === id
    }
}
