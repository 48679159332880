import { AutomationControlPanel } from '../AutomationControlPanel'
import { AutoSchedulerStateSuspense } from '../AutoSchedulerStateSuspense'

import { AutomationControlPanelWithSuspenseProps } from './types'

export const AutomationControlPanelWithSuspense = ({
    automationControlPanelProps = {}
}: AutomationControlPanelWithSuspenseProps) => (
    <AutoSchedulerStateSuspense>
        <AutomationControlPanel {...automationControlPanelProps} />
    </AutoSchedulerStateSuspense>
)
