import { ReactNode } from 'react'

import classes from './style.module.css'

type NonInputProps = {
    children: ReactNode
}

export default function NonInput({ children }: NonInputProps) {
    return <div className={classes.nonInput}>{children}</div>
}
