import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'

import { useSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const TeacherFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const noTeacherFilter = {
        value: '',
        text: toTranslate('Lärare saknas'),
        intrinsic: true
    }
    const allTeacherFilters = schedule.getTeachers().map((s) => ({
        value: s.getTeacherId(),
        text: s.getTeacherSchoolId()
    }))

    const { t } = useTranslation()

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={[noTeacherFilter, ...allTeacherFilters]}
            noKeysAvailableDescription={t('NoTeachers')}
        />
    )
}
