import { IOptimizationState } from 'conjure-ai-schema-server-api'
import dayjs from 'dayjs'

export const getSecondsSinceLastImprovement = (optimizationState: IOptimizationState) =>
    IOptimizationState.visit(optimizationState, {
        running: (running) => {
            const lastImprovementTime = running.lastImprovementTime
            const timeOfLastImprovementEpochSeconds = dayjs.unix(lastImprovementTime)

            return dayjs().diff(timeOfLastImprovementEpochSeconds, 'second')
        },
        starting: () => undefined,
        stopping: () => undefined,
        stopped: () => undefined,
        unknown: () => undefined
    })

export const getProgressFromOptState = (optimizationState: IOptimizationState) =>
    IOptimizationState.visit(optimizationState, {
        running: (running) => (running.progress === 'NaN' ? undefined : running.progress),
        starting: () => undefined,
        stopping: () => undefined,
        stopped: () => undefined,
        unknown: () => undefined
    })
