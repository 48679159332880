import i18next from 'i18next'
import { uniq } from 'lodash'

import { diff } from '../../../../../EditTable/utils'
import { ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { splitAndCleanCommaSepList } from '../../../../../textUtil'
import { newMultiMapFromKeyValuePairs, orThrow } from '../../../../../utils/collections'
import { toTranslate } from '../../../../../utils/miscUtil'
import { sortedCoursesFromSchedule } from '../../../../courses/components/CoursesTable/CoursesEditTable/utils'
import { ProblemListProblem } from '../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../schedule/components/ProblemsList/utils'
import { sortedSubjectsFromSchedule } from '../../../../subjects/components/SubjectsTable/SubjectsEditTable/utils'
import { conjureSubjectsFromSubjects } from '../../../../subjects/components/SubjectsTable/SubjectsEditTable/utils'
import {
    conjureStudentGroupsFromStudentGroups,
    conjureTeacherFromTeachers
} from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'
import { conjureCoursesFromCourses } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'
import { TableEditValidationProblem } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/types'
import { parseIntegerString } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/util'

import { courseRoundCmp, parseCourseRoundsRows, parseTerms } from './data'
import { CourseRoundGridContentColumn, CourseRoundGridMetaDataColumn, CourseRoundGridRow } from './types'
import { conjureCourseRoundsFromCourses, isEmptyCourseRoundsRow, sortedCourseRoundsFromSchedule } from './utils'

export const courseRoundCellValidationErrors = (
    courseRounds: CourseRoundGridRow[],
    schedule: ScheduleAccessor
): TableEditValidationProblem[] => {
    const errors: TableEditValidationProblem[] = []

    // Make sure course / subject code exists
    const existingCourseAndSubjectCodes = [
        ...schedule.getCourses().map((c) => c.getCode()),
        ...schedule.getSubjects().map((s) => s.getCode())
    ]
    for (const courseRoundRow of courseRounds) {
        if (isEmptyCourseRoundsRow(courseRoundRow)) {
            continue
        }

        // Valid course / subject codes
        const courseOrSubjectCode = courseRoundRow[CourseRoundGridContentColumn.CourseOrSubjectCode].trim()
        if (!existingCourseAndSubjectCodes.includes(courseOrSubjectCode)) {
            errors.push({
                location: {
                    rowIndex: courseRoundRow[CourseRoundGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseRoundGridContentColumn.CourseOrSubjectCode
                },
                problem: createBlocker(
                    toTranslate(`Kurs- eller ämneskod finns inte i systemet: ${courseOrSubjectCode}`)
                )
            })
        }

        // Valid total hours
        const totalHours = parseIntegerString(courseRoundRow[CourseRoundGridContentColumn.TotalHours])
        if (Number.isNaN(totalHours)) {
            errors.push({
                location: {
                    rowIndex: courseRoundRow[CourseRoundGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseRoundGridContentColumn.TotalHours
                },
                problem: createBlocker(toTranslate('Felaktigt antal timmar. Ange ett heltal'))
            })
        }

        // Valid teacher signatures
        const existingTeacherSignatures = schedule.getTeachers().map((t) => t.getTeacherSchoolId())
        const teacherSignatures = splitAndCleanCommaSepList(
            courseRoundRow[CourseRoundGridContentColumn.TeacherSignatures]
        )
        for (const teacherSignature of teacherSignatures) {
            if (!existingTeacherSignatures.includes(teacherSignature)) {
                errors.push({
                    location: {
                        rowIndex: courseRoundRow[CourseRoundGridMetaDataColumn.RowIndex] + 1,
                        colIndex: CourseRoundGridContentColumn.TeacherSignatures
                    },
                    problem: createBlocker(toTranslate(`Lärare finns inte i systemet: ${teacherSignature}`))
                })
            }
        }

        // HT/VT/Läsår
        const term = courseRoundRow[CourseRoundGridContentColumn.Terms]
        if (parseTerms(term) === undefined) {
            errors.push({
                location: {
                    rowIndex: courseRoundRow[CourseRoundGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseRoundGridContentColumn.Terms
                },
                problem: createBlocker(toTranslate('Felaktig period. Ange HT, VT eller Läsår'))
            })
        }

        // Student group exists
        const existingStudentGroups = schedule.getStudentGroups().map((sg) => sg.getDisplayName())
        const studentGroup = courseRoundRow[CourseRoundGridContentColumn.StudentGroup].trim()
        if (!existingStudentGroups.includes(studentGroup)) {
            errors.push({
                location: {
                    rowIndex: courseRoundRow[CourseRoundGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseRoundGridContentColumn.StudentGroup
                },
                problem: createBlocker(toTranslate(`Elevgrupp finns inte i systemet: ${studentGroup}`))
            })
        }
    }

    return errors
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newCourseRounds: CourseRoundGridRow[]
): ProblemListProblem[] => {
    const existingCourseRounds = conjureCourseRoundsFromCourses(sortedCourseRoundsFromSchedule(currentSchedule))
    const parsedCourseRounds = parseCourseRoundsRows({
        existingCourseRounds,
        existingCourses: conjureCoursesFromCourses(sortedCoursesFromSchedule(currentSchedule)),
        existingStudentGroups: conjureStudentGroupsFromStudentGroups(currentSchedule.getStudentGroups()),
        existingSubjects: conjureSubjectsFromSubjects(sortedSubjectsFromSchedule(currentSchedule)),
        existingTeachers: conjureTeacherFromTeachers(currentSchedule.getTeachers()),
        rowsIncludingEmpty: newCourseRounds
    })
    const diffToValidate = diff(existingCourseRounds, parsedCourseRounds, (r) => r.courseRoundId, courseRoundCmp)

    const referencedCourseRoundIds = new Set(
        currentSchedule.getLectures().map((l) => l.getCourseRound().getCourseRoundId())
    )
    const deletedAndReferencedCourseRoundErrors = diffToValidate.deleted
        .filter((deletedCourseRound) => referencedCourseRoundIds.has(deletedCourseRound.courseRoundId))
        .map((deletedAndReferencedCourseRound) =>
            createBlocker(
                toTranslate(
                    `Kan inte ta bort kursomgång ${deletedAndReferencedCourseRound.displayName} då det finns lektioner kopplade till den.`
                )
            )
        )

    const cellErrors = courseRoundCellValidationErrors(newCourseRounds, currentSchedule).map((cellValidationError) => ({
        ...cellValidationError.problem,
        messageText: `Rad ${cellValidationError.location?.rowIndex}: ${cellValidationError.problem.messageText}`
    }))

    return [...cellErrors, ...deletedAndReferencedCourseRoundErrors]
}
