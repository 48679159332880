import { SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import { SaveTableContentButtonProps } from './types'

export const SaveTableContentButton = ({ enabled, onSave }: SaveTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button variant="primary" onClick={onSave} disabled={!enabled} startIcon={<SaveOutlined />}>
            {t('Save')}
        </Button>
    )
}
