import classNames from 'classnames'

import type { CourseRoundId } from '../../commonTypes'
import { useLocalSchedule } from '../../store/schedule/hooks'

import classes from './CourseRoundCard.module.css'

type CourseRoundProps = {
    courseRoundId: CourseRoundId
    selected?: boolean
    isInFilter?: boolean
}

export function CourseRoundCard(props: CourseRoundProps) {
    const { isInFilter, selected, courseRoundId } = props

    const schedule = useLocalSchedule()
    const courseRound = schedule.findCourseRound(courseRoundId)

    return (
        <div
            className={classNames(classes.card, {
                [classes.selected]: selected,
                [classes.filtered]: isInFilter,
                [classes.notFiltered]: !isInFilter
            })}
            style={{ background: `#${courseRound.getSubject().getColor()}` }}
        >
            <strong>{courseRound.getDisplayName()}</strong>
            <div>{courseRound.getTotalHours()}h</div>
            <div>{courseRound.getCourse()?.getCode() || courseRound.getSubject().getCode()}</div>
        </div>
    )
}
