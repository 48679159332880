import { RefObject, useEffect, useRef } from 'react'

type UseKeyPressOptions = {
    onKeyPress: () => void
    elementToFocus?: RefObject<{ focus: () => void }>
    key: string
}

const useKeyPress = ({ onKeyPress, elementToFocus, key }: UseKeyPressOptions) => {
    // Store the elementToFocus in a ref to access the latest value in the event handler
    const elementToFocusRef = useRef(elementToFocus)

    // Update the ref when elementToFocus changes
    useEffect(() => {
        elementToFocusRef.current = elementToFocus
    }, [elementToFocus])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === key && (event.ctrlKey || event.metaKey)) {
                event.preventDefault()
                onKeyPress()

                // Use a more immediate approach to focus the element
                // This ensures it works on initial render
                const focusElement = () => {
                    if (elementToFocusRef.current?.current) {
                        try {
                            elementToFocusRef.current.current.focus()
                        } catch (e) {
                            console.error('Failed to focus element:', e)
                        }
                    }
                }

                setTimeout(focusElement, 100)
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [onKeyPress, key])
}

export default useKeyPress
