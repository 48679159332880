import { ScheduleSettingsPanel } from '../../components/schedule-settings/ScheduleSettingsPanel'
import ScheduleSuspense from '../../components/ScheduleSuspense'

export const ScheduleParametersIndexPage = () =>
    (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '3em'}}>
            <ScheduleSuspense>
                <ScheduleSettingsPanel/>
            </ScheduleSuspense>
        </div>
    )
