import { Cell, Row } from '@silevis/reactgrid'
import { ICourse } from 'common-api'
import { isEqual, merge } from 'lodash'
import { Dispatch, SetStateAction, useRef } from 'react'

import EditTable from '../../../../../EditTable'
import { BackgroundChangesAlert } from '../../../../../EditTable/components/BackgroundChangesAlert'
import EditTableWrapper from '../../../../../EditTable/components/EditTableWrapper'
import { GridActionsFooter } from '../../../../../EditTable/components/GridActionsFooter'
import { DEFAULT_CONTENT_CELL, HEADER_ROW, MIN_ROWS_TO_SHOW } from '../../../../../EditTable/constants'
import { useFocusedCell } from '../../../../../EditTable/hooks'
import {
    anyDiff,
    DEFAULT_HEADER_CELL,
    diff,
    generateEmptyRows,
    getIndexCell,
    handleClearTableContent
} from '../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { comparing } from '../../../../../utils/compareUtil'
import { handleCopy } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handleCopy'
import { handlePaste } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handlePaste'
import { conjureSubjectsFromSubjects } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'

import SimpleCourseListDiff from './components/SimpleCourseListDiff'
import { courseCmp, parseCoursesRows } from './data'
import { useCoursesGridColumns } from './hooks'
import { ALL_CONTENT_COLUMNS, CourseGridMetaDataColumn, CourseGridRow } from './types'
import { conjureCourseFromCourses, generateEmptyCourseRow, sortedCoursesFromSchedule } from './utils'
import { courseCellValidationErrors } from './validation'

type CoursesEditTableProps = {
    courses: CourseGridRow[]
    setCourses: Dispatch<SetStateAction<CourseGridRow[]>>
    onReset: () => void
    onSave: () => void
    coursesWhenEditingStarted: ICourse[]
}

export default function CoursesEditGrid({
    courses,
    setCourses,
    onReset,
    onSave,
    coursesWhenEditingStarted
}: CoursesEditTableProps) {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useCoursesGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveCourses = conjureCourseFromCourses(sortedCoursesFromSchedule(schedule))

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<ICourse>(coursesWhenEditingStarted, liveCourses, (r) => r.courseId, courseCmp)

    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())

    const parsedFromCurrentEditTable = parseCoursesRows({
        existingCourses: liveCourses,
        existingSubjects: liveSubjects,
        rowsIncludingEmpty: courses
    })
    const changesMade = anyDiff(diff(parsedFromCurrentEditTable, liveCourses, (r) => r.courseId, courseCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - courses.length)
    const newEmptyRows = generateEmptyRows(courses.length, emptyRowsToAdd, generateEmptyCourseRow)
    const courseGridData = [...courses, ...newEmptyRows]

    const rows = [
        HEADER_ROW,
        ...courseGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        const fromIndex = courses.length
        const withEmptyRows = generateEmptyRows(fromIndex, numRows, generateEmptyCourseRow)

        setCourses([...courseGridData, ...withEmptyRows])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    const validationProblems = courseCellValidationErrors(courses, liveSubjects)

    courseGridData.forEach((courseRow, courseIndex) => {
        const rowIndex = courseIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === CourseGridMetaDataColumn.RowIndex) {
                cells.push(getIndexCell(rowIndex))
            } else {
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: courseRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newCourseGridData = [...courseGridData]
                                    newCourseGridData[courseIndex][gridColumn] = newText
                                    setCourses(newCourseGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        {}
                    )
                )
            }
        })
    })

    const changedData = [
        ...backgroundUpdates.updated.map(([prev]) => prev),
        ...backgroundUpdates.created,
        ...backgroundUpdates.deleted
    ]

    return (
        <EditTableWrapper ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "room data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const newCourses = handlePaste<CourseGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: courseGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptyCourseRow(dstRowIndex),
                        indexLocation: targetCell
                    })

                    setCourses(newCourses)
                }}
            />
            {anyDiff(backgroundUpdates) ? (
                <BackgroundChangesAlert
                    diff={backgroundUpdates}
                    changedData={changedData}
                    DiffPopupComponent={<SimpleCourseListDiff diff={backgroundUpdates} />}
                    namesList={changedData.map((course) => course.name).toSorted(comparing((n) => n.length))}
                />
            ) : null}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={() => {
                    handleClearTableContent({
                        rows: courseGridData,
                        generateEmptyRow: generateEmptyCourseRow,
                        setData: setCourses
                    })
                }}
            />
        </EditTableWrapper>
    )
}
