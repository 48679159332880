import { PlusOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { ICourseOrSubjectId, IScheduleTransform, Terms } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import type { CourseRoundId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName, toTranslate } from '../../../../utils/miscUtil'

type NewCourseRoundButtonProps = {
    onNewCourseRound: (newCourseRoundId: CourseRoundId) => void
    disabled: boolean
}

export const NewCourseRoundButton = ({ onNewCourseRound, disabled }: NewCourseRoundButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const freshCourseRoundDisplayName = () =>
        generateFreshName(
            'Ny kursomgång',
            schedule.getCourseRounds().map((cr) => cr.getDisplayName())
        )

    const handleNewCourseRound = () => {
        if (schedule.getSubjects().length === 0) {
            message.error(toTranslate('Inga ämnen tillgängliga'))

            return
        }

        if (schedule.getStudentGroups().length === 0) {
            message.error(toTranslate('Inga elevgrupper tillgängliga'))

            return
        }

        const newCourseRoundId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.courseRoundTransform({
                    newCourseRound: {
                        courseRoundId: newCourseRoundId,
                        displayName: freshCourseRoundDisplayName(),
                        courseOrSubjectId: ICourseOrSubjectId.subjectId(schedule.getSubjects()[0].getSubjectId()),
                        teacherIds: [],
                        studentGroupId: schedule.getStudentGroups()[0].getStudentGroupId(),
                        terms: Terms.YEAR,
                        totalHours: 0
                    }
                })
            )
        )
        onNewCourseRound(newCourseRoundId)
    }

    return (
        <Button
            variant="primary"
            size="sm"
            startIcon={<PlusOutlined />}
            onClick={handleNewCourseRound}
            disabled={disabled}
        >
            {t('AddCourseOffering')}
        </Button>
    )
}
