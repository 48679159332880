import classNames from 'classnames'
import { IOptimizationState } from 'conjure-ai-schema-server-api'
import { useEffect, useState } from 'react'

import { useScheduleOptimizationStateOrThrow } from '../../../../../store/autoscheduler/hooks'
import { formatDurationFromSeconds } from '../../../../../utils/DayAndTimeUtil'
import { toTranslate } from '../../../../../utils/miscUtil'
import { TripleDotsMessage } from '../../../../import-export/TripleDotsMessage'

import { ProgressBar } from './ProgressBar'
import classes from './style.module.css'
import { OptimizationStatePanelProps } from './types'
import { getProgressFromOptState, getSecondsSinceLastImprovement } from './util'

export const OptimizationStatePanel = ({ compact = false }: OptimizationStatePanelProps) => {
    const optimizationState = useScheduleOptimizationStateOrThrow()
    const [secondsSinceLastImprovement, setSecondsSinceLastImprovement] = useState<number | undefined>(() =>
        getSecondsSinceLastImprovement(optimizationState)
    )

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSecondsSinceLastImprovement(getSecondsSinceLastImprovement(optimizationState))
        }, 200)

        return () => {
            clearInterval(intervalId)
        }
    }, [setSecondsSinceLastImprovement, optimizationState])

    const progress = getProgressFromOptState(optimizationState)

    const primaryStatus = IOptimizationState.visit(optimizationState, {
        running: (running) => {
            const progressNum = running.progress === 'NaN' ? 0 : running.progress

            return (
                <span>
                    {progressNum < 1 ? toTranslate('Placerar lektioner') : toTranslate('Letar efter förbättringar')}
                </span>
            )
        },
        starting: () => <TripleDotsMessage>{toTranslate('Startar automation')}</TripleDotsMessage>,
        stopping: () => <TripleDotsMessage>{toTranslate('Stoppar automation')}</TripleDotsMessage>,
        stopped: () => <span>{toTranslate('Automation stoppad')}</span>,
        unknown: () => null
    })

    if (compact) {
        const showProgressBar = IOptimizationState.visit(optimizationState, {
            running: () => true,
            starting: () => false,
            stopping: () => false,
            stopped: () => false,
            unknown: () => false
        })

        return (
            <div className={classNames(classes.optimizationStatePanel, classes.compact)}>
                {primaryStatus}
                <div style={{ flex: 1 }}>{showProgressBar && <ProgressBar progress={progress ?? 0} />}</div>
            </div>
        )
    }

    const showProgressBar = IOptimizationState.visit(optimizationState, {
        running: () => true,
        starting: () => true,
        stopping: () => true,
        stopped: () => false,
        unknown: () => false
    })

    const secondaryStatus = IOptimizationState.visit(optimizationState, {
        running: (running) => {
            const progressNum = running.progress === 'NaN' ? 0 : running.progress

            return (
                <span>
                    {progressNum < 1
                        ? `${(progressNum * 100).toFixed(0)}\u2009%`
                        : secondsSinceLastImprovement === undefined
                          ? null
                          : `${formatDurationFromSeconds(secondsSinceLastImprovement)} ${toTranslate('sedan senaste förbättringen')}`}
                </span>
            )
        },
        starting: () => null,
        stopping: () => null,
        stopped: () => null,
        unknown: () => null
    })

    return (
        <div className={classNames(classes.optimizationStatePanel, classes.expanded)}>
            <div className={classes.statusContainer}>
                {primaryStatus}
                {secondaryStatus}
            </div>
            {showProgressBar && <ProgressBar progress={progress ?? 0} />}
        </div>
    )
}
