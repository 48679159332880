import { Table } from 'antd'

import type { CourseRoundId } from '../../../../commonTypes'
import TableView from '../../../../EditTable/components/TableView'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import type { CourseRoundRow } from '../../types'
import { NewCourseRoundButton } from '../NewCourseRoundButton'

import { useCourseRoundColumns } from './columns'
import type { CourseRoundsTableProps } from './types'

const CourseRoundsTable = ({ selectedRowKeys, setSelectedRowKeys }: CourseRoundsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useCourseRoundColumns(filtering)

    const dataSourceUnfiltered: CourseRoundRow[] = schedule.getCourseRounds().map((courseRound) => ({ courseRound }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ courseRound }) => courseRound.getDisplayName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableView
            filtering={filtering}
            CreateButton={({ disabled }) => (
                <NewCourseRoundButton
                    disabled={disabled}
                    onNewCourseRound={(newCourseRoundId) => {
                        setSelectedRowKeys([newCourseRoundId])
                    }}
                />
            )}
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            handleModeChange={handleModeChange}
        >
            <Table<CourseRoundRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys as CourseRoundId[])
                    }
                }}
                dataSource={dataSource}
                showSorterTooltip={false}
                columns={columns}
                size="small"
                rowKey={({ courseRound }) => courseRound.getCourseRoundId()}
                sticky
                onRow={({ courseRound }) => ({
                    onClick: () => {
                        setSelectedRowKeys([courseRound.getCourseRoundId()])
                    }
                })}
                style={{ height: '100%' }}
                pagination={false}
                rowClassName={(record) => `row-${record.courseRound.getCourseRoundId()}`}
            />
        </TableView>
    )
}

export default CourseRoundsTable
