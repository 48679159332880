export const sv = {
    translation: {
        ActualHours: 'Faktiskt antal timmar',
        Add: 'Lägg till',
        AddLecture: 'Lägg till lektion',
        AddSetting: 'Lägg till inställning',
        AddBreakSetting: 'Lägg till rastvillkor',
        AddStudentGroup: 'Lägg till grupp',
        AddSchedule: 'Lägg till schema',
        After: 'efter',
        AfterLecture: 'Efter lektion',
        All: 'Alla',
        AllCourseOfferingsHaveATeacher: 'Alla kursomgångar har en lärare kopplat till sig.',
        AllowSkipInMatching: 'Tillåt överhopp i matchning',
        AllVisible: 'Alla synliga',
        And: 'och',
        Authenticating: 'Autentiserar...',
        At: 'vid',
        AT: 'HT',
        Atleast: 'minst',
        Attribute: 'Attribut',
        Automation: 'Automation',
        AWeeks: 'A-veckor',
        BackToAdmin: 'Tillbaka till admin',
        BackgroundChanges: {
            Updated: 'uppdaterats',
            Added: 'lagts till',
            Removed: 'tagits bort',
            Or: 'eller',
            Has: 'har',
            SinceEditModeOpened: ' sedan redigeringsläget öppnades. Var extra uppmärksam vid förhandsgranskningen.',
            BackgroundUpdatesTitle: 'Uppdateringar har gjorts i bakgrunden'
        },
        Before: 'före',
        BeforeLecture: 'Före lektion',
        Between: 'mellan',
        BlockedTime: 'Blockerad tid',
        BreakHasToBe: 'Rast måste vara minst',
        BreakSettings: 'Rastvillkor',
        BreakShouldPreferablyBe: '...men helst',
        BWeeks: 'B-veckor',
        Cancel: 'Avbryt',
        CanteenLoadDuringLunch: 'Matsalsbelastning under lunch',
        CaseSensitive: 'Skilj på stora / små bokstäver',
        ChooseLanguage: 'Välj språk: ',
        Class: 'Klass',
        Classes: 'Klasser',
        CourseOfferings: 'Kursomgångar',
        CourseOfferingsWithoutTeachers: 'Kursomgångar utan lärare',
        CourseOrSubject: {
            Subjects: 'Ämnen',
            Courses: 'Kurser',
            Course: 'kurs',
            Subject: 'ämne'
        },
        CourseListChanges: {
            NewCourses: 'Nya kurser',
            UpdatedCourses: 'Uppdaterade kurser',
            DeletedCourses: 'Borttagna kurser',
            NoNewCourses: 'Inga nya kurser',
            NoUpdatedCourses: 'Inga uppdaterade kurser',
            NoRemovedCourses: 'Inga borttagna kurser'
        },
        CourseRound: {
            Saved: 'Kursomgång sparad',
            SavedMultiple: 'Kursomgångar sparade',
            SelectSingleToEditName: 'Välj en enskild kursomgång för att redigera namnet.',
            SelectSingleToEditCourseOrSubject: 'Välj en enskild kursomgång för att ändra ämne/kurs.',
            SelectSingleToEditStudentGroup: 'Välj en enskild kursomgång för att ändra grupp.',
            SelectSingleToEditHours: 'Välj en enskild kursomgång för att ändra timmar.',
            SelectSingleToEditBreakRules: 'Välj en enskild kursomgång för att ändra rastvillkor.',
            SelectSingleToEditLectureDurations: 'Välj en enskild kursomgång för att ändra lektionslängder.',
            SubjectOrCourse: 'Ämne / kurs',
            Terms: 'Terminer',
            SchoolYear: 'Läsår',
            FallTerm: 'HT',
            SpringTerm: 'VT',
            TeacherOnlyForTheseOfferings: 'Denna lärare hör endast till följande kursomgångar: ',
            Singular: 'kursomgång',
            Plural: 'kursomgångar'
        },
        Checklist: {
            Staff: 'Personal',
            RoomsAndEquipment: 'Rum och utrustning',
            Subjects: 'Ämnen',
            Courses: 'Kurser',
            CourseOfferings: 'Kursomgångar',
            SelectBaseSettings: 'Välj grundinställningar',
            SelectBaseSettingsDesc: 'Välj skolform, rastvillkor, lektionslängder m.m.',
            AdjustYearDataAndPeriods: 'Justera läsårsdata och perioder',
            AdjustYearDataAndPeriodsDesc: 'Välj dagar då skolan har öppet och skapa perioder så som A- / B-veckor.',
            ImportData: 'Importera data',
            ImportDataDesc: 'Se till att lärare, grupper, rum etc. finns i schemat.',
            PerformTeacherAssignments: 'Genomför tjänstefördelning',
            PerformTeacherAssignmentsDesc: 'Se välj vilka lärare som ska undervisa vilka kursomgångar.',
            GenerateLectures: 'Generera lektioner',
            GenerateLecturesDesc: 'Se till att lektioner är skapade för alla kurser.',
            ScheduleLectures: 'Schemalägg lektioner',
            ScheduleLecturesDesc: 'Se till att alla lektioner har en tid och ett rum.',
            PublishSchedule: 'Publicera schemat',
            PublishScheduleDesc: 'Överför schemat till adminpanelen och publicera schemat.'
        },
        Filter: 'Filtrera',
        HighlightSearchText: 'Markera söktext',
        Rooms: 'Rum',
        ClassHasTooShortLunch: 'Klass {{Class}} har ingen lunch på {{Day}}',
        Code: 'Kod',
        CollapseAll: 'Kollapsa alla',
        Columns: 'Kolumner',
        ColumnsView: {
            SelectDaysToShow: 'Välj vilka dagar som ska visas i schemat',
            SelectViewMode: 'Välj hur du vill se dina valda scheman',
            PerSchedule: 'Per schema',
            Column: 'kolumn',
            Columns: 'kolumner'
        },
        Condition: 'Villkor',
        Course: 'Kurs',
        Continue: 'Fortsätt',
        Courses: 'Kurser',
        CourseCode: 'Kurskod',
        CourseOffering: 'Kursomgång',
        CourseOfferingHaveToo: 'Kursomgång har för',
        CourseOfferingHaveTooFewHours: 'Kursomgång har för få timmar: ',
        Create: 'Skapa',
        DayAndTime: 'Dag och tid',
        Description: 'Beskrivning',
        Designation: 'Titel',
        Development: 'Utveckling',
        DevelopmentButton: {
            DumpDaysTimes: 'Dumpa dagar och tider',
            RemoveAllPins: 'Ta bort alla pins för dag/tid',
            RemoveNonScheduledLectures: 'Ta bort icke schemalagda lektioner',
            RemoveTimeDay: 'Ta bort dag / tid från alla lektioner',
            RemoveTimeDayNonPinned: 'Ta bort dag / tid från alla icke-pinnade lektioner',
            TakeDayTimeOnLecturesOutsideBlock: 'Ta dag / tid på lektioner utanför block'
        },
        Duration: 'Längd',
        Employment: 'Tjänstegrad',
        EvenWeeks: 'Jämna veckor',
        EditCourseRound: 'Redigera kursomgång',
        Except: 'utom',
        ExpandAll: 'Expandera alla',
        Export: 'Exportera',
        ExportSemester: 'Exportera perioder',
        Few: 'få',
        FirstName: 'Förnamn',
        GroupAfterType: 'Gruppera efter typ',
        GroupAfterTeacher: 'Gruppera efter lärare',
        GroupAfterClass: 'Gruppera efter klass',
        HaveToBe: 'måste vara',
        Hours: 'Timmar',
        HoursSmall: ' timmar',
        HoursInPercentOfGoal: 'Timmar i procent av mål',
        InRelationToLecture: 'Förhållande till lektion',
        InTimePeriod: 'inom tidsperioden',
        Labels: 'Etiketter',
        Languages: {
            Swedish: 'Svenska',
            English: 'Engelska',
            German: 'Tyska'
        },
        LogoName: 'Schema',
        LastName: 'Efternamn',
        Lecture: 'Lektion',
        LectureSmall: 'lektion',
        LectureIsToo: 'Lektionen är för ',
        LectureDuration: 'Lektions\u00ADlängder',
        LectureDurationOverride: {
            InheritFrom: 'Ärv inställningar från {{entityType}}',
            SetSpecificFor: 'Sätt specifikt för {{entityType}}'
        },
        LectureEndsTooLate: 'Lektion slutar för sent:',
        Lectures: 'Lektioner',
        LessonDetails: 'Lektionsdetaljer',
        LessonToo: 'Lektion för ',
        Long: 'lång',
        MainMenu: {
            CanteenLoad: 'Matsalsbelastning',
            Courses: 'Kurser',
            Education: 'Utbildning',
            Rooms: 'Rum',
            Schedule: 'Schema',
            ServiceDistribution: 'Tjänstefördelning',
            StudentGroups: 'Grupper',
            Teachers: 'Lärare',
            TeachingGroups: 'Kursomgångar',
            TeachingHours: 'Undervisningstimmar',
            Parameters: 'Parametrar',
            Periods: 'Perioder',
            Lectures: 'Lektioner',
            Reports: 'Rapporter',
            Settings: 'Inställningar',
            SettingsSaved: 'Inställningar sparade',
            ShowAll: 'Visa alla',
            SchoolType: 'Skolform',
            OpeningHours: 'Dagens start och slut',
            LunchRules: 'Lunchregler'
        },
        Many: 'många',
        MarkedBlockedTime: 'Markera blockerad tid',
        MarkAsDone: 'Markera som klart',
        MarkAsNotDone: 'Markera som ej klar',
        Minutes: 'minuter',
        MinutesLongBut: 'men',
        MinutesSmall: 'minuter',
        MinimumOf: 'Minst',
        Name: 'Namn',
        Names: 'Namn',
        NoBreakSettings: 'Inga rastvillkor',
        NoClass: 'Ingen klass',
        NoCondition: 'Inget villkor',
        NoLectures: 'Inga lektioner',
        NoLecturesMatchFilter: 'Inga lektioner matchar valda filter',
        NoRoom: 'Inget rum',
        NoRoomSelected: 'Rum saknas för ',
        NoLessonSelected: 'Ingen lektion vald',
        NoSubjects: 'Inga ämnen',
        NoStudentGroups: 'Inga grupper',
        NoLabels: 'Inga etiketter',
        NoRooms: 'Inga rum',
        NoCourses: 'Inga kurser',
        NoCourseRounds: 'Inga kursomgångar',
        Non: 'icke ',
        NonHypen: 'icke-',
        NonOverlappingStudentGroups: 'Icke-överlappande grupper',
        NotPartOfBlock: 'Ej del av block',
        NotScheduled: 'Ej schemalagd: ',
        Number: 'Nr',
        NumberOfDays: 'Antal dagar',
        NumberOfWeeks: 'Antal veckor',
        OddWeeks: 'Udda veckor',
        Ok: 'Ok',
        Other: 'Övriga',
        OverlappingGroup: 'överlappande grupp',
        OverlappingStudentGroups: 'Överlappande grupper',
        OverlapWith: 'överlapp med',
        Remove: 'Ta bort',
        Reset: 'Nollställ',
        ReservedTime: 'Reserverad tid',
        ReservedTimes: 'Reserverade tider',
        RequiredRoomAttributes: 'Nödvändiga rumssattribut',
        Room: 'Rum',
        RoomAttributes: 'Rumssattribut',
        RoomId: 'Id - Rum',
        RoomChange: 'Rumsbyte',
        RoomsListTooltip: {
            Singular: 'rum',
            Plural: 'rum'
        },
        RoomChangeSmall: 'rumsbyte',
        RoomDoubleBooked: 'Rum dubbelbokat: {{roomName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
        RoomMissingAttributes: 'Rum {{roomName}} saknar attribut {{missingAttributes}}',
        RoomScheduledOnBlockedTime:
            'Rum schemalagt på blockerad tid: {{roomName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
        SameCourseMultipleTimesOnSameDay: 'Samma kurs flera gånger på en dag: ',
        SameSubjectMultipleTimesOnSameDay: 'Samma ämne flera gånger på en dag: {{subjectName}} med {{sgName}}',
        Save: 'Spara',
        SchoolYear: 'Läsår',
        SearchCourse: 'Sök kurs',
        SearchCourseOffering: 'Sök kursomgång',
        SearchRoom: 'Sök rum',
        SearchPlaceholders: {
            SearchSchedule: 'Sök schema',
            SearchStudentGroup: 'Sök grupp',
            SearchTeacher: 'Sök lärare'
        },
        Short: 'kort',
        StudentGroupId: 'Id - Grupp',
        ShouldPreferablyBe: 'ska helst vara',
        ShowInheritedSettings: 'Visa ärvda inställningar',
        Signature: 'Signatur',
        Square: 'Fyrkant',
        ST: 'VT',
        start: 'Starta',
        StudentGroupListChanges: {
            NewStudentGroups: 'Nya grupper',
            UpdatedStudentGroups: 'Uppdaterade grupper',
            DeletedStudentGroups: 'Borttagna grupper',
            NoNewStudentGroups: 'Inga nya grupper',
            NoUpdatedStudentGroups: 'Inga uppdaterade grupper',
            NoDeletedStudentGroups: 'Inga borttagna grupper'
        },
        stop: 'Stoppa',
        StudentGroup: 'Grupp',
        StudentGroupsDoubleBooked: 'Grupper dubbelbokade: {{sgNames}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
        Students: 'Elever',
        StudentsHasTooShortBreakOnDay: 'Elever i {{sgName}} har för kort rast mellan lektioner på {{day}}',
        Subject: 'Ämne',
        SubjectChange: 'Ämnesbyte',
        SubjectChangeSmall: 'ämnesbyte',
        SubjectId: 'Id - Ämne',
        SubjectListChanges: {
            NewSubjects: 'Nya ämnen',
            UpdatedSubjects: 'Uppdaterade ämnen',
            DeletedSubjects: 'Borttagna ämnen',
            NoNewSubjects: 'Inga nya ämnen',
            NoUpdatedSubjects: 'Inga uppdaterade ämnen',
            NoDeletedSubjects: 'Inga borttagna ämnen'
        },
        Teacher: 'Lärare',
        Teachers: 'Lärare',
        TeacherDoubleBooked: 'Lärare dubbelbokad: {{teacherName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
        TeacherFrameTimeExceeded: 'Lärares ramtid överskriden: {{teacherSchoolId}} har {{frameTime}}',
        TeachingGroup: 'Kurs\u00ADomgångar',
        TeachingGroups: 'Kurs\u00ADomgångar',
        TeacherHasNoLunchBreak: 'Lärare har ingen lunchrast: {{teacherSchoolId}} på {{dayName}}',
        TeacherHasTooShortBreak: '{{teacherId}} har för kort rast mellan lektioner',
        TeacherHasTooShortBreakOnDay: '{{teacherId}} har för kort rast mellan lektioner på {{day}}',
        TeacherScheduledOnBlockedTime:
            'Lärare schemalagd på blockerad tid: {{teacherSchoolId}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
        TheCourseOffering: 'kursomgången',
        TheGroup: 'gruppen',
        TheGroups: 'grupperna',
        ThisAttributeOnlyApplysToTheFollowingRooms: 'Detta attribut gäller endast följande rum: ',
        ThisAttributeOnlyApplysToTheFollowingLectures: 'Detta attribut gäller endast följande lektioner: ',
        ThisBlockOnlyApplysToTheFollowingLessons: 'Detta block gäller endast följande lektioner: ',
        ThisGroupOnlyOverlapsWithTheFollowingGroups: 'Denna grupp överlappar endast med följande grupper: ',
        ThisLabelOnlyApplysToTheFollowingGroups: 'Denna etikett gäller endast följande grupper: ',
        ThisSubjectOnlyApplysToTheFollowingTeachers: 'Detta ämne gäller endast följande lärare',
        TimePinned: 'Tid fäst',
        WeekDay: 'Veckodag',
        Yes: 'Ja',
        No: 'Nej',
        NoTeachers: 'Inga lärare',
        Preferably: 'helst',
        Problems: 'Problem',
        ProblemTypeLabel: {
            EndTimeProblem: 'Lektion slutar för sent',
            TeacherConflictProblem: 'Lärare dubbelbokad',
            StudentGroupLunchProblem: 'Klass har för kort lunchrast',
            TeacherBreakProblem: 'Lärare har för kort rast mellan lektioner',
            StudentGroupBreakProblem: 'Elever har för kort rast mellan lektioner',
            RoomConflictProblem: 'Rum dubbelbokat',
            RoomReservedTimeConflictProblem: 'Rum schemalagd på blockerad tid',
            RequiredRoomAttributeProblem: 'Rum saknar attribut',
            NoRoomSetProblem: 'Lektion saknar rum',
            UnscheduledLectureProblem: 'Lektion ej schemalagd',
            ScheduleOverrunProblem: 'Lektion slutar för sent',
            SameCourseMultipleTimesOnSameDayProblem: 'Samma kurs flera gånger på samma dag',
            SameSubjectMultipleTimesOnSameDayProblem: 'Samma ämne flera gånger på samma dag',
            StudentGroupConflictProblem: 'Grupp dubbelbokade',
            TeacherFrameTimeProblem: 'Lärares ramtid överskriden',
            TeacherLunchProblem: 'Lärare har för kort lunchrast',
            TeacherReservedTimeConflictProblem: 'Lärare schemalagd på blockerad tid',
            StudentGroupReservedTimeConflictProblem: 'Grupp schemalagd på blockerad tid',
            LectureDurationProblem: {
                TooLong: 'Lektion för lång',
                TooShort: 'Lektion för kort'
            },
            TotalCourseRoundDuration: {
                TooLong: 'Kursomgång har för många timmar',
                TooShort: 'Kursomgång har för få timmar'
            },
            UnassignedCourseRoundProblem: 'Kursomgång saknar lärare',
            UnAssignedCourseRoundProblem: 'Undervisningsgrupp {{courseName}} saknar lärare',
            TeacherNotQualifiedProblem: 'Lärare {{teacherName}} har inte behörighet att undervisa i {{subjectCode}}',
            TeacherNotQualifiedProblemLabel: 'Lärare saknar behörighet',
            TeacherOverloadProblemLabel: 'Lärare har för mycket undervisning',
            TeacherOverloadProblem: {
                Prefix: 'Lärare {{teacherName}} har för mycket undervisning på ',
                FallSuffix: 'HT ({{hours}}h)',
                SpringSuffix: 'VT ({{hours}}h)',
                And: 'och på'
            },
            UnknownCourseRound: 'Okänd kursomgång',
            CourseRoundDurationProblem: '{{courseName}} har för {{type}} timmar'
        },
        UnknownCourseRound: 'Okänd kursomgång',
        Qualifications: 'Behörigheter',
        TeacherSaved: 'Lärare sparad',
        TeachersSaved: 'Lärare sparade',
        CourseSaved: 'Kurs sparad',
        CoursesSaved: 'Kurser sparade',
        StudentGroupSaved: 'Grupp sparad',
        StudentGroupsSaved: 'Grupper sparade',
        SelectSingleStudentGroupToEditName: 'Välj en enskild grupp för att redigera namnet.',
        SelectSingleStudentGroupToEditDescription: 'Välj en enskild grupp för att redigera beskrivningen.',
        LecturesReferencingSelectedRooms: '{{count}} lektion(er) refererar till valda rum',
        RoomRemoved: 'Rum borttaget',
        RemoveRoom: 'Ta bort rum',
        RemoveRooms: 'Ta bort rum',
        AddRoom: 'Lägg till rum',
        NoRoomAttributes: 'Inga rumssattribut',
        ValidFor: 'Gäller för:',
        WeekDays: {
            Monday: 'Måndag',
            Tuesday: 'Tisdag',
            Wednesday: 'Onsdag',
            Thursday: 'Torsdag',
            Friday: 'Fredag',
            Saturday: 'Lördag',
            Sunday: 'Söndag'
        },
        WeekDaysShort: {
            Monday: 'Må',
            Tuesday: 'Ti',
            Wednesday: 'On',
            Thursday: 'To',
            Friday: 'Fr',
            Saturday: 'Lö',
            Sunday: 'Sö'
        },
        WholeSchoolYear: 'Hela läsåret',
        Weeks: 'Veckor',
        W: 'v.',
        Actions: 'Åtgärder',
        AddPeriod: 'Lägg till period',
        AddSubject: 'Lägg till ämne',
        AddCourse: 'Lägg till kurs',
        AddCourseOffering: 'Lägg till kursomgång',
        AddTeacher: 'Lägg till lärare',
        ClearContent: 'Töm innehåll',
        Edit: 'Redigera',
        EditEvent: 'Redigera händelse',
        NewSchedule: 'Nytt schema',
        NewRows: 'Nya rader',
        Rows: 'rader',
        NoEventSelected: 'Ingen händelse vald',
        PeriodName: 'Namn för perioden',
        RemoveCourse: 'Ta bort kurs',
        RemoveCourses: 'Ta bort kurser',
        RemoveSubject: 'Ta bort ämne',
        RemoveSubjects: 'Ta bort ämnen',
        RemoveStudentGroup: 'Ta bort grupp',
        RemoveStudentGroups: 'Ta bort grupper',
        RemoveTeacher: 'Ta bort lärare',
        ResetContent: 'Återställ innehåll',
        Search: 'Sök',
        SubjectCode: 'Ämneskod',
        TableEditing: 'Tabellredigering',
        Title: 'Titel',
        TransferToAdmin: 'Överför till Admin',
        TransferToSelectedSchedule: 'Exportera till valt schema',
        TransferComplete: 'Överföring klar',
        TransferStatus: {
            Rooms: 'Överför rum',
            Teachers: 'Överför lärare',
            Periods: 'Överför perioder',
            StudentGroups: 'Överför grupper',
            Courses: 'Överför kurser',
            Lectures: 'Överför lektioner'
        },
        TeacherChanges: {
            NewTeachers: 'Nya lärare',
            UpdatedTeachers: 'Uppdaterade lärare',
            RemovedTeachers: 'Borttagna lärare',
            NoNewTeachers: 'Inga nya lärare',
            NoUpdatedTeachers: 'Inga uppdaterade lärare',
            NoRemovedTeachers: 'Inga borttagna lärare',
            RemovedQualifications: 'Borttagna behörigheter',
            AddedQualifications: 'Tillagda behörigheter'
        },
        Errors: {
            BackgroundChanges: 'Uppdateringar har gjorts i bakgrunden',
            DuplicateSignature: 'Signatur förekommer även på rad',
            ErrorsMustBeFixed: 'Följande fel måste åtgärdas',
            ImportFailed: 'Schemaimport misslyckades. Vänligen kontakta Meitner Support.',
            InvalidPercentage: 'Felaktigt formatterad procentsats',
            LecturesReferencing: 'lektion(er) refererar till',
            NoSuitableEventType: 'Kunde inte hitta lämplig händelsetyp',
            PredefinedPeriodNotFound: {
                SchoolYear: 'Kunde inte hitta fördefinerad period för läsåret.',
                Spring: 'Kunde inte hitta fördefinerad period för vårterminen.',
                Fall: 'Kunde inte hitta fördefinerad period för höstterminen.'
            },
            ReservedTimesReferencing: 'reserverade tider refererar till perioden',
            StudentGroupReferencedInDistribution: 'Grupp refereras till i tjänstefördelningen ({{name}})',
            TeacherReferencedInDistribution: 'Lärare refereras till i tjänstefördelningen ({{name}})',
            UnknownSubject: 'Okänt ämne:',
            TryAgain: 'Försök igen',
            PleaseReload: 'Vänligen ladda om sidan och försök igen.',
            LecturesReferencingPeriod: '{{count}} lektion(er) refererar till perioden',
            ReservedTimesReferencingPeriod: '{{count}} reserverade tider refererar till perioden',
            LecturesReferencingCourseRound: '{{count}} lektion(er) refererar till i valda kursomgångar',
            ExistingLecturesForCourseRound: 'Det finns redan lektioner för någon av de valda kursomgångarna',
            UnassignedCourseRoundProblem: 'Kursomgång saknar lärare',
            TeacherNotQualifiedProblem: 'Lärare {{teacherName}} har inte behörighet att undervisa i {{subjectCode}}',
            DuplicateCourseCode: 'Kurskoden finns redan',
            MissingCourseCode: 'Kursen saknar kurskod',
            MissingCourseName: 'Kursen saknar namn',
            MissingSubject: 'Kursen saknar ämne',
            SubjectNotFound: 'Ämnet finns inte',
            SubjectNotFoundWithCode: 'Ämnet {{code}} finns inte'
        },
        Success: {
            Created: 'skapade',
            ImportComplete: 'Schema importerad.',
            SubjectSaved: 'Ämne sparad',
            SubjectsSaved: 'Ämnen sparade',
            SubjectRemoved: 'Ämne borttaget',
            SubjectsRemoved: 'Ämnen borttagna'
        },
        SelectSingleSubject: {
            BreakRules: 'Välj ett enskilt ämne för att ändra rastvillkor.',
            LectureDurations: 'Välj ett enskilt ämne för att ändra lektionslängder.'
        },
        EntityTypes: {
            Subject: 'ämne',
            Schedule: 'schemat'
        },
        DuplicateNamesInSubjectsCourses: 'Namndubbletter bland ämnen/kurser:',
        SelectScheduleToTransferTo: 'Välj vilket schema det aktuella schemat ska överföras till:',
        ImportFromRoyalSchedule: 'Import från RoyalSchedule',
        Semesters: 'Terminer',
        'Success.CourseRemoved': 'Kurs borttagen',
        'Errors.CourseRoundsReferencing': '{{count}} kursomgång(ar) refererar till valda kurser',
        TeacherListChanges: {
            NewTeachers: 'Nya lärare',
            UpdatedTeachers: 'Uppdaterade lärare',
            DeletedTeachers: 'Borttagna lärare',
            NoNewTeachers: 'Inga nya lärare',
            NoUpdatedTeachers: 'Inga uppdaterade lärare',
            NoRemovedTeachers: 'Inga borttagna lärare',
            RemovedQualifications: 'Borttagna behörigheter',
            AddedQualifications: 'Tillagda behörigheter'
        },
        RoomListChanges: {
            NewRooms: 'Nya rum',
            UpdatedRooms: 'Uppdaterade rum',
            DeletedRooms: 'Borttagna rum',
            NoNewRooms: 'Inga nya rum',
            NoUpdatedRooms: 'Inga uppdaterade rum',
            NoRemovedRooms: 'Inga borttagna rum'
        },
        SkipCharacter: 'Hoppa över tecken',
        LeaveUnchanged: 'Lämna oförändrade',
        SelectTwoOrMoreLectures: 'Välj två eller flera lektioner att skapa block av',
        AllLecturesAlreadyInBlock: 'Alla valda lektioner ligger redan i ett block',
        CreateBlock: 'Skapa block',
        ExtractSingleLectureFromBlock: 'Extrahera endast vald lektion från block',
        ExtractSelectedLecturesFromBlock: 'Extrahera endast valda lektioner från block',
        SplitBlock: 'Dela upp block',
        MoveToDrawer: 'Flytta till lapplådan',
        RemoveLecture: 'Ta bort lektion',
        RemoveLectures: 'Ta bort lektioner',
        WithSpecificWeeks: 'Med specifika veckor...',
        ExceptSpecificWeeks: 'Utan specifika veckor...',
        CourseRoundActions: {
            RemoveCourseRound: 'Ta bort kursomgång',
            GenerateLectures: 'Generera lektioner',
            ReferToSubject: 'Referera till ämne istället för kurs'
        },
        ViewOptions: 'Visningsalternativ',
        LectureSaved: 'Lektion sparad',
        LecturesSaved: 'Lektioner sparade',
        SelectLecturesFromSameBlockToEditDayAndTime: 'Välj lektioner från samma block för att redigera dag och tid',
        SelectSingleLectureToEditTeachers: 'Välj en enskild lektion för att ändra lärare',
        SelectSingleLectureToEditRooms: 'Välj en enskild lektion för att ändra rum',
        SelectSingleLectureToEditWeekSettings: 'Välj en enskild lektion för att ändra veckoinställningar',
        SelectSingleLectureToEditDuration: 'Välj en enskild lektion för att ändra längd',
        SelectSingleLectureToEditBreakSettings: 'Välj en enskild lektion för att ändra rastvillkor',
        SelectSingleLectureToEditDurationSettings: 'Välj en enskild lektion för att ändra längdinställningar',
        Without: 'utan',
        With: 'med',
        OneMoreStudentGroup: 'en till grupp',
        RemainingStudentGroups: '{{remaining}} andra grupper',
        SelectWeek: 'Välj vecka…',
        SelectWeekToExtract: 'Välj vecka att klippa ut:',
        Extract: 'Klipp ut',
        ExtractFromSeries: 'Klipp ut från serie',
        AddRequiredRoomAttributes: 'Lägg till nödvändiga rumssattribut',
        OpenMenu: 'Öppna menu',
        GraphicalView: 'Grafisk vy',
        Settings: 'Inställningar',
        Staff: 'Personal',
        Subjects: 'Ämnen',
        Transfer: 'Export',
        Import: 'Import',
        Configuration: 'Konfiguration',
        OpenChecklist: 'Öppna checklista',
        LogIn: 'Logga in',
        NoProfileSelected: 'Du har inte valt profil.',
        NoSchedulerRole: 'Du har inte behörighet till Meitner Schema. Kontakta din administratör.',
        SessionExpired: 'Du har blivit utloggad.',
        InheritFromCourseRound: 'Ärv från kursomgång',
        SetSpecificForLesson: 'Sätt specifikt för lektion',
        DuplicateLecture: 'Duplicera lektion',
        NoReservedTimeSelected: 'Ingen reserverad tid vald',
        RemoveReservedTime: 'Ta bort reserverad tid',
        RemoveReservedTimes: 'Ta bort reserverade tider',
        Split: 'Dela',
        SplitLecture: 'Dela lektion',
        StudentGroupAppliesOnlyToTheseReservedTimes: 'Denna grupp gäller bara följande reserverade tider',
        TeacherAppliesOnlyToTheseReservedTimes: 'Denna lärare gäller bara följande reserverade tider',
        ThisRoomOnlyApplysToTheFollowingReservedTimes: 'Detta rum gäller bara följande reserverade tider',
        NoEvent: 'Ingen händelse vald',
        SelectSingleReservedTimeToChangeLength: 'Välj en enskild reserverad tid för att ändra längd.',
        SelectSingleReservedTimeToChangeTitle: 'Välj en enskild reserverad tid för att ändra title',
        SelectSingleReservedTimeToChangeWeekSettings: 'Välj en enskild reserverad tid för att ändra veckoinställningar',
        LecturesInBlockSelected: '{{count}} av {{total}} lektioner i blocket valda.',
        SelectAllLecturesInBlock: 'Välj blockets alla lektioner',
        SelectAllLecturesInBlockToEdit: 'för att redigera dag och tid.',
        Loading: 'Laddar',
        RoomSaved: 'Rum sparad',
        RoomsSaved: 'Rum sparade',
        ShowAll: 'Visa alla',
        ShowFewer: 'Visa färre',
        ShowLess: 'Visa färre',
        AddedQualifications: 'Tillagda behörigheter',
        ErrorsMustBeFixed: 'Följande fel måste åtgärdas',
        NewTeachers: 'Nya lärare',
        NoNewTeachers: 'Inga nya lärare',
        NoRemovedTeachers: 'Inga borttagna lärare',
        NoUpdatedTeachers: 'Inga uppdaterade lärare',
        RemovedQualifications: 'Borttagna behörigheter',
        RemovedTeachers: 'Borttagna lärare',
        UpdatedTeachers: 'Uppdaterade lärare',
        ElementarySchool: 'Grundskola',
        HighSchool: 'Gymnasium',
        HiddenCoursesInElementaryMode:
            'Det finns kurser registrerade i schemat. I Grundskoleläge är kurstabellen dold och du kan inte se eller redigera kurserna utan att först växla tillbaka till Gymnasieläge.',
        Validation: {
            SignatureAppears: 'Signatur förekommer även på rad {{rows}}',
            UnknownSubject: 'Okänt ämne: {{subject}}',
            MalformedPercentage: 'Felaktigt formatterad procentsats',
            TeacherSignatureMultipleRows: 'Lärarsignaturen {{signature}} förekommer på mer än en rad: {{rows}}',
            TeacherRemovedButReferenced:
                'Lärare {{teacher}} är borttagen, men finns omnämnd i följande undervisningsgrupper: {{groups}}',
            RowUnknownSubject: 'Rad {{row}}: Okänt ämne: {{subject}}',
            RowMalformedPercentage: 'Rad {{row}}: Felaktigt formatterad procentsats',
            CouldntRemoveStudentGroups:
                'Kunde inte ta bort grupp {{studentGroup}} eftersom den används i kursomgången {{courseRound}}',
            DuplicateStudentGroupNames: 'Gruppnamn {{name}} förekommer flera gånger: {{rows}}',
            StudentGroupMissingName: 'Grupp saknar namn',
            StudentGroupAlreadyExists: 'Grupp finns redan',
            RoomNameMultipleRows: 'Rumnamnet "{{name}}" förekommer på flera rader: {{rows}}',
            SubjectNameMultipleRows: 'Ämnets namn "{{name}}" förekommer på flera rader: {{rows}}',
            SubjectMissingCode: 'Ämne {{subject}} saknar kod'
        },
        Preview: 'Förhandsgranskning',
        SubjectRemovedButReferenced: 'Ämne {{subject}} är borttaget, men finns omnämnd i följande kurser: {{courses}}'
    }
} as const
