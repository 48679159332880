import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import type { DuplicateLectureButtonProps } from './types'

export const DuplicateLectureButton = ({ disabled = false, onDuplicate }: DuplicateLectureButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button disabled={disabled} icon={<CopyOutlined />} onClick={onDuplicate}>
            {t('DuplicateLecture')}
        </Button>
    )
}
