import { ICourse, ISubject } from 'common-api'
import { v4 as uuid } from 'uuid'

import { matchRowsWithExistingData } from '../../../../../EditTable/data'
import { combinedCmpFn, comparing } from '../../../../../utils/compareUtil'

import { CourseGridContentColumn, CourseGridMetaDataColumn, CourseGridRow } from './types'
import { isEmptyCoursesRow } from './utils'

export const courseCmp = combinedCmpFn<ICourse>(
    comparing((r) => r.code),
    comparing((r) => r.subjectId),
    comparing((r) => r.name)
)

const matchScore = (row: CourseGridRow, course: ICourse, subject: ISubject | undefined) =>
    (course.code === row[CourseGridContentColumn.Code] ? 1 : 0) +
    (course.name === row[CourseGridContentColumn.Name] ? 1 : 0) +
    (course.subjectId === subject?.subjectId ? 1 : 0)

type ParseCoursesRowsParams = {
    existingCourses: ICourse[]
    rowsIncludingEmpty: CourseGridRow[]
    existingSubjects: ISubject[]
}

export const parseCoursesRows = ({
    existingCourses,
    rowsIncludingEmpty,
    existingSubjects
}: ParseCoursesRowsParams): ICourse[] => {
    const rows = rowsIncludingEmpty.filter((row) => !isEmptyCoursesRow(row))
    const matchedCourses = matchRowsWithExistingData<ICourse, CourseGridRow>({
        existingData: existingCourses,
        rows,
        matchRow: CourseGridMetaDataColumn.RowIndex,
        matchScore: (row, course) => {
            const subject = existingSubjects.find((s) => s.code === row[CourseGridContentColumn.Subject])

            return matchScore(row, course, subject)
        },
        maximumScore: 3,
        minimumAcceptableScore: 2
    })

    return rows.map((row) => {
        const code = row[CourseGridContentColumn.Code]
        const matchedCourse = matchedCourses.get(row[CourseGridMetaDataColumn.RowIndex])
        const courseId = matchedCourse?.courseId ?? uuid()
        const subject = existingSubjects.find((s) => s.code === row[CourseGridContentColumn.Subject])

        // This is terrible and should be fixed.
        // parseCourseRows should ignore invalid rows (unparseable rows) just as it ignores empty rows.
        const subjectId = subject?.subjectId ?? ''

        return {
            courseId,
            subjectId,
            code,
            name: row[CourseGridContentColumn.Name]
        }
    })
}
