type SearchResultProps = {
    results: Result[]
    isSearching: boolean
    isSelected: (id: string) => boolean
    searchText: string
}

type ResultSectionProps = {
    results: Result[]
    title: string
    isSearching: boolean
    isSelected: (id: string) => boolean
    searchText: string
}

type SearchItemProps = {
    onClick: (searchTerm: string) => void
    isSelected: (id: string) => boolean
    searchText: string
    onClose: () => void
}

const defaultFiltering = {
    caseSensitive: false,
    subseq: false
}

enum SearchType {
    Teacher = 'teacher',
    Subject = 'subject',
    Course = 'course',
    Room = 'room',
    StudentGroup = 'studentGroup'
}

type Result = {
    id: string
    title: string
    onClick: () => void
    type: SearchType
    url: string
}

export { defaultFiltering, SearchType }
export type { Result, ResultSectionProps, SearchItemProps, SearchResultProps }
