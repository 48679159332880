import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames'
import { IOptimizationState } from 'conjure-ai-schema-server-api'

import { AutoSchedulerStartStopButton } from '../../../../../components/AutoSchedulerStartStopButton'
import { useToggle } from '../../../../../hooks/useBoolean'
import { useScheduleOptimizationStateOrThrow } from '../../../../../store/autoscheduler/hooks'
import { useIsDummySchedule } from '../../../../../store/schedule/hooks'
import { OptimizationStatePanel } from '../OptimizationStatePanel'

import classes from './style.module.css'
import { AutomationControlPanelProps } from './types'

export const AutomationControlPanel = ({ panelInitiallyOpen }: AutomationControlPanelProps = {}) => {
    const scheduleOptimizationState = useScheduleOptimizationStateOrThrow()

    const { value: isPanelOpen, toggle: togglePanelOpen } = useToggle(
        panelInitiallyOpen ??
            IOptimizationState.visit(scheduleOptimizationState, {
                running: () => true,
                starting: () => true,
                stopping: () => false,
                stopped: () => false,
                unknown: () => false
            })
    )

    const isDummySchedule = useIsDummySchedule()

    if (isDummySchedule) {
        return null
    }

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            className={classNames(
                classes.automationControlPanel,
                isPanelOpen ? classes.openPanel : classes.closedPanel
            )}
            onClick={isPanelOpen ? undefined : togglePanelOpen}
        >
            <div className={classes.content}>
                <div className={classes.optimizationStatePanel}>
                    <OptimizationStatePanel compact={!isPanelOpen} />
                </div>
                {isPanelOpen && (
                    <div className={classes.startStopButton}>
                        <AutoSchedulerStartStopButton />
                    </div>
                )}
            </div>
            <Button icon={isPanelOpen ? <DownOutlined /> : <UpOutlined />} type="text" onClick={togglePanelOpen} />
        </div>
    )
}
