import { useState } from 'react'

export const useToggle = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue)

    return {
        value,
        toggle: () => {
            setValue(!value)
        }
    }
}
