import classNames from 'classnames'
import type { CSSProperties, Ref } from 'react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../icon/src'

import classes from './style.module.css'
import type { ChipProps } from './types'

type CSSVariables = Record<`--${string}`, string | number>

type CSSPropertiesWithVars = CSSProperties & CSSVariables

const Chip = forwardRef(
    (
        {
            size,
            variant,
            customColor,
            pill = false,
            clickable = false,
            className,
            children,
            startIcon,
            endIcon,
            onDelete
        }: ChipProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const { t } = useTranslation()

        const chipClasses = classNames(classes.chip, className, {
            [classes['chip--size-xs']]: size === 'xs',
            [classes['chip--size-md']]: size === 'md',
            [classes['chip--size-lg']]: size === 'lg',
            [classes['chip--pill']]: pill,
            [classes['chip--clickable']]: clickable || onDelete !== undefined,
            [classes['chip--variant-outlined']]: variant === 'outlined',
            [classes['chip--variant-filled']]: variant === 'filled'
        })

        const chipStyle: CSSPropertiesWithVars | undefined =
            variant === 'customColor'
                ? {
                      '--chip-text-color': 'var(--color-text-base-body-alt)',
                      '--chip-fill': customColor,
                      '--chip-border-color': customColor
                  }
                : undefined

        function renderStartIcon() {
            if (startIcon) {
                return <div className={classes.chip__icon}>{startIcon}</div>
            }

            return null
        }

        function renderEndIcon() {
            if (endIcon) {
                return <div className={classes.chip__icon}>{endIcon}</div>
            }

            return null
        }

        return (
            <div className={chipClasses} style={chipStyle} ref={ref}>
                {renderStartIcon()}
                {typeof children === 'string' ? <p>{children}</p> : children}
                {onDelete ? (
                    <button
                        onClick={onDelete}
                        className={classes.chip__deleteButton}
                        aria-label={t('Remove')}
                        type="button"
                    >
                        <Icon name="icon-xmark-16" className={classes.chip__deleteIcon16} />
                        <Icon name="icon-xmark-24" className={classes.chip__deleteIcon24} />
                    </button>
                ) : (
                    renderEndIcon()
                )}
            </div>
        )
    }
)

export default Chip
