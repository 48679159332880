import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import Chip from '../../../components/Chip'
import { FilterHighlight } from '../../../components/dev-util/FilterHighlight'
import type { FilterableColumn } from '../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../components/table-support/util'
import type { FilteringState } from '../../schedule/components/FilterInput/types'

import { RoomAttributeFilterDropdown } from './RoomAttributeFilterDropdown'
import type { RoomRow } from './types'

export enum RoomsColumnKey {
    Name = 0,
    Attributes = 1
}

export const useRoomColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleRoomColumns', [
        RoomsColumnKey.Name,
        RoomsColumnKey.Attributes
    ])
    const { t } = useTranslation()

    const columns: FilterableColumn<RoomRow>[] = [
        {
            title: t('Room'),
            key: RoomsColumnKey.Name,
            filterTexts: ({ room }) => [room.getName()]
        },
        {
            title: t('Attribute'),
            key: RoomsColumnKey.Attributes,
            filterDropdown: RoomAttributeFilterDropdown,
            onFilter: (attribute, { room }) => room.getAttributes().includes(attribute as string),
            render: (_, { room }) =>
                room.getAttributes().map((attribute) => (
                    <Chip key={attribute} variant="filled" size="xs">
                        <FilterHighlight text={attribute} filtering={filtering} />
                    </Chip>
                )),
            filterTexts: ({ room }) => room.getAttributes()
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
