import { Flex } from 'antd'
import classNames from 'classnames'
import { ChecklistStep } from 'common-api'
import { useState } from 'react'

import styled from '../../../utils/styled'
import { useChecklistUiItems } from '../hooks'
import { firstNonCompletedTopLevelStep } from '../util'

import { ChecklistItem } from './ChecklistItem'
import classes from './style.module.css'
import { ToggleExplicitlyDoneButton } from './ToggleExplicitlyDoneButton'
import { isNonVirtualStep, ScheduleChecklistPanelProps, VirtualChecklistStep } from './types'

export const ScheduleChecklistPanel = ({ onNavigateAway }: ScheduleChecklistPanelProps) => {
    const checklistItems = useChecklistUiItems(onNavigateAway)
    const [selectedStep, setSelectedStep] = useState<ChecklistStep | VirtualChecklistStep>(
        firstNonCompletedTopLevelStep(checklistItems)
    )
    const selectedItem = checklistItems.find((item) => item.step === selectedStep)!

    return (
        <Flex align="stretch">
            <Flex vertical>
                {checklistItems.map((item, i) => (
                    <ChecklistItemTab
                        key={item.step}
                        className={classNames({ selected: selectedItem === item })}
                        onClick={() => {
                            setSelectedStep(item.step)
                        }}
                    >
                        <ChecklistItem {...item} stepNumber={i + 1} />
                        {item.substeps === undefined ? null : (
                            <div
                                className={classNames(classes.scheduleChecklistPanel__ChecklistSubItemsContainer, {
                                    [classes['scheduleChecklistPanel__ChecklistSubItemsContainer--selected']]:
                                        selectedItem === item
                                })}
                            >
                                {item.substeps.map((subItem, j) => (
                                    <ChecklistItem
                                        key={subItem.step}
                                        {...subItem}
                                        small
                                        stepNumber={j + 1}
                                        style={{ margin: '1em 0em 1em 4em' }}
                                    />
                                ))}
                            </div>
                        )}
                    </ChecklistItemTab>
                ))}
                <ChecklistItemTab className="filler-tab" style={{ flexGrow: 1 }} />
            </Flex>
            <div className={classes.scheduleChecklistPanel__contentArea}>
                {selectedItem.substeps === undefined && isNonVirtualStep(selectedItem.step) && (
                    <div style={{ position: 'absolute', bottom: '3em', right: '3em' }}>
                        <ToggleExplicitlyDoneButton stepUiItem={selectedItem} />
                    </div>
                )}
                {selectedItem.helpContent}
            </div>
        </Flex>
    )
}

const ChecklistItemTab = styled.div`
    padding-right: 1em;

    &:not(.filler-tab) {
        cursor: pointer;
        padding: 1em 2em;
        &:hover {
            background-color: #fafafa;
        }
    }

    &:not(.selected) {
        background-color: #f5f5f5;
    }

    // Rounded bottom right corner above selected tab
    &:has(+ .selected) {
        border-bottom-right-radius: 16px;
    }

    // Rounded top right corner below selected tab
    .selected + &:not(.selected) {
        border-top-right-radius: 16px;
    }

    &.selected:hover {
        background-color: transparent;
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        padding-top: 2em;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        padding-bottom: 2em;
    }
`
