import { useTranslation } from 'react-i18next'

import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import StudentGroupDetails from './components/StudentGroupDetails'
import StudentGroupsTable from './components/StudentGroupTable'
import { StudentGroupActionsPanel } from './StudentGroupActionsPanel'

const StudentGroupsIndexPage = () => {
    const schedule = useLocalSchedule()

    const { t } = useTranslation()

    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/student-groups')

    // Some selected student groups may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((sgId) => schedule.doesStudentGroupIdExist(sgId))

    const selectedStudentGroups = validSelectedRowKeys.map((studentGroupId) =>
        schedule.findStudentGroup(studentGroupId)
    )

    const detailsPanel = (
        <StudentGroupDetails
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            studentGroups={selectedStudentGroups}
        />
    )
    const actionsPanel = (
        <StudentGroupActionsPanel
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            studentGroups={selectedStudentGroups}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        >
            <StudentGroupsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}

export default StudentGroupsIndexPage
