import { ColumnType } from 'antd/es/table'
import { ComponentType, Key, ReactNode } from 'react'
import { Outlet } from 'react-router'

import { VisibleColumnsSelector } from '../../../components/VisibleColumnsSelector'
import { FilterInput } from '../../../pages/schedule/components/FilterInput'
import { FilteringState } from '../../../pages/schedule/components/FilterInput/types'
import { useIsDummySchedule } from '../../../store/schedule/hooks'
import ScheduleSuspense from '../../../components/ScheduleSuspense'
import { TableEditModeSwitch } from '../TableEditModeSwitch'
import { useEditModeSwitch } from '../TableEditModeSwitch/useEditModeSwitch'

import classes from './style.module.css'

// TColumnKey needs to fulfill the Key type to be generic
type TableActionsProps<TRow, TColumnKey extends Key> = {
    CreateButton: ComponentType<{ disabled: boolean }>
    columns: ColumnType<TRow>[]
    setVisibleColumns: (newVisible: TColumnKey[]) => void
    handleModeChange: () => void
    children: ReactNode
    filtering: FilteringState
    showColumnsSelector?: boolean
}

const TableView = <TRow, TColumnKey extends Key>({
    CreateButton,
    columns,
    setVisibleColumns,
    handleModeChange,
    children,
    filtering,
    showColumnsSelector = true
}: TableActionsProps<TRow, TColumnKey>) => {
    const { isEditModeToggled } = useEditModeSwitch()
    const isDummySchedule = useIsDummySchedule()

    return (
        <div className={classes.tableView}>
            <div className={classes.tableView__header}>
                <FilterInput {...filtering} disabled={isEditModeToggled} />
                {showColumnsSelector ? (
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={isEditModeToggled}
                    />
                ) : null}
                <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                <CreateButton disabled={isEditModeToggled} />
            </div>

            {isEditModeToggled ? (
                <ScheduleSuspense>
                    <Outlet />
                </ScheduleSuspense>
            ) : (
                <div className={classes.tableView__table}>{children}</div>
            )}
        </div>
    )
}

export default TableView
