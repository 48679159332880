import i18next from 'i18next'

import { diff } from '../../../../EditTable/utils'
import { ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { isBlank, textualListing } from '../../../../textUtil'
import { newMultiMapFromKeyValuePairs, orThrow } from '../../../../utils/collections'
import { toTranslate } from '../../../../utils/miscUtil'
import { ProblemListProblem } from '../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../schedule/components/ProblemsList/utils'
import { TableEditValidationProblem } from '../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/types'

import { parseRoomRows, roomCmp } from './data'
import { RoomGridRow, RoomGridContentColumn, RoomGridMetaDataColumn } from './types'
import { isEmptyRoomsRow } from './utils'
import { conjureRoomFromRooms, sortedRoomsFromSchedule } from './utils'

const checkForDuplicateRoomNames = (
    roomNames: string[],
    roomRow: RoomGridRow
): TableEditValidationProblem | undefined => {
    const roomName = roomRow[RoomGridContentColumn.Name]

    const duplicateRoomNames = roomNames.filter((name) => name.toLowerCase() === roomName.toLowerCase())

    if (duplicateRoomNames.length > 1) {
        return {
            location: { rowIndex: roomRow[RoomGridMetaDataColumn.RowIndex] + 1, colIndex: RoomGridContentColumn.Name },
            problem: createBlocker(toTranslate('Salen finns redan'))
        }
    }

    return undefined
}

export const roomCellValidationErrors = (rooms: RoomGridRow[]): TableEditValidationProblem[] => {
    const result = []
    const roomNames = rooms.map((room) => room[RoomGridContentColumn.Name])

    for (const roomRow of rooms) {
        if (isEmptyRoomsRow(roomRow)) {
            continue
        }

        const roomName = roomRow[RoomGridContentColumn.Name]

        if (isBlank(roomName)) {
            result.push({
                location: {
                    rowIndex: roomRow[RoomGridMetaDataColumn.RowIndex] + 1,
                    colIndex: RoomGridContentColumn.Name
                },
                problem: createBlocker(toTranslate('Salen saknar namn'))
            })

            return result
        }

        const duplicateProblem = checkForDuplicateRoomNames(roomNames, roomRow)

        if (duplicateProblem !== undefined) {
            result.push(duplicateProblem)
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newRoomData: RoomGridRow[]
): ProblemListProblem[] => {
    const existingRooms = conjureRoomFromRooms(sortedRoomsFromSchedule(currentSchedule))
    const parsedRooms = parseRoomRows(existingRooms, newRoomData)
    const diffToValidate = diff(existingRooms, parsedRooms, (r) => r.name, roomCmp)

    // Check if deleted rooms are referenced in lectures
    const lecturesByRooms = newMultiMapFromKeyValuePairs(
        currentSchedule
            .getLectures()
            .flatMap((lecture) =>
                lecture.getAssignedRooms().map((room) => [room.getRoomId(), lecture] satisfies [string, typeof lecture])
            )
    )

    const referencedRoomIds = currentSchedule
        .getLectures()
        .flatMap((lecture) => lecture.getAssignedRooms())
        .map((room) => room.getRoomId())

    const deletedAndReferencedRooms = diffToValidate.deleted.filter((deletedRoom) =>
        referencedRoomIds.includes(deletedRoom.roomId)
    )

    const deletedAndReferencedRoomErrors = deletedAndReferencedRooms.map((deletedAndReferencedRoom) => {
        const referencingLectures = orThrow(lecturesByRooms.get(deletedAndReferencedRoom.roomId))
        const lectureCount = referencingLectures.length

        return createBlocker(
            i18next.t('LecturesReferencingSelectedRooms', {
                count: lectureCount
            })
        )
    })

    // Check for duplicate room names
    const roomNameCounts = new Map<string, number[]>()
    newRoomData.forEach((roomRow, index) => {
        const name = roomRow[RoomGridContentColumn.Name]
        if (name !== '') {
            if (!roomNameCounts.has(name)) {
                roomNameCounts.set(name, [])
            }

            const rows = roomNameCounts.get(name)
            if (rows) {
                rows.push(index)
            }
        }
    })

    const missingNamesWithErrorIndex = newRoomData
        .map((roomRow, index) => {
            if (isEmptyRoomsRow(roomRow)) {
                return undefined
            }

            if (isBlank(roomRow[RoomGridContentColumn.Name])) {
                return createBlocker(toTranslate(`Sal saknar namn på rad ${index + 1}`))
            }

            return undefined
        })
        .filter((error) => error !== undefined) satisfies ProblemListProblem[]

    const duplicateRoomErrors = [...roomNameCounts.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([roomName, rows]) =>
            createBlocker(
                i18next.t('Validation.RoomNameMultipleRows', {
                    name: roomName,
                    rows: textualListing(
                        rows.map((r) => `${r + 1}`),
                        i18next.t('Rows')
                    )
                })
            )
        )

    return [...deletedAndReferencedRoomErrors, ...duplicateRoomErrors, ...missingNamesWithErrorIndex]
}
