import { Cell, Row } from '@silevis/reactgrid'
import { ITeacher } from 'common-api'
import { isEqual, merge } from 'lodash'
import { useRef } from 'react'

import EditTable from '../../../../../../EditTable'
import { BackgroundChangesAlert } from '../../../../../../EditTable/components/BackgroundChangesAlert'
import EditTableWrapper from '../../../../../../EditTable/components/EditTableWrapper'
import { GridActionsFooter } from '../../../../../../EditTable/components/GridActionsFooter'
import { MIN_ROWS_TO_SHOW, HEADER_ROW, DEFAULT_CONTENT_CELL } from '../../../../../../EditTable/constants'
import { useFocusedCell } from '../../../../../../EditTable/hooks'
import { diff, anyDiff, DEFAULT_HEADER_CELL, getIndexCell } from '../../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { comparing } from '../../../../../../utils/compareUtil'

import { teacherCmp } from './diff'
import { handleCopy } from './handleCopy'
import { handlePaste } from './handlePaste'
import {
    conjureSubjectsFromSubjects,
    conjureTeacherFromTeachers,
    sortedTeachersFromSchedule,
    useTeacherGridColumns
} from './hooks'
import { SimpleTeacherListDiff } from './SimpleTeacherListDiff'
import { parseTeacherRows } from './teacherData'
import {
    ALL_CONTENT_COLUMNS,
    TeacherGridContentColumn,
    TeacherGridMetaDataColumn,
    TeacherGridProps,
    TeacherGridRow
} from './types'
import { formatPercentInput, generateEmptyTeacherRow, generateEmptyTeacherRows, shortTeacherDisplayName } from './util'
import { cellValidationErrors } from './validation'

export const TeacherGrid = ({
    teachers,
    setTeachers,
    onReset,
    onSave,
    teachersWhenEditingStarted
}: TeacherGridProps) => {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useTeacherGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())
    const liveTeachers = conjureTeacherFromTeachers(sortedTeachersFromSchedule(schedule))

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<ITeacher>(teachersWhenEditingStarted, liveTeachers, (t) => t.teacherId, teacherCmp)

    const parsedTeachersFromCurrentEditTable = parseTeacherRows(liveTeachers, liveSubjects, teachers)
    const changesMade = anyDiff(diff(parsedTeachersFromCurrentEditTable, liveTeachers, (t) => t.teacherId, teacherCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - teachers.length)
    const teacherGridData = [
        ...teachers,
        ...generateEmptyTeacherRows(teachers.length, teachers.length + emptyRowsToAdd)
    ]

    const rows = [
        HEADER_ROW,
        ...teacherGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        setTeachers([
            ...teacherGridData,
            ...generateEmptyTeacherRows(teacherGridData.length, teacherGridData.length + numRows)
        ])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    const validationProblems = cellValidationErrors(schedule, teachers)

    teacherGridData.forEach((teacherRow, teacherIndex) => {
        const rowIndex = teacherIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === TeacherGridMetaDataColumn.RowIndex) {
                cells.push(getIndexCell(rowIndex))
            } else {
                const overrides =
                    gridColumn == TeacherGridContentColumn.WorkPercentage
                        ? {
                              props: {
                                  onTextChanged: (newText: string) => {
                                      const newTeacherGridData = [...teacherGridData]
                                      newTeacherGridData[teacherIndex][TeacherGridContentColumn.WorkPercentage] =
                                          formatPercentInput(newText)
                                      setTeachers(newTeacherGridData)
                                  }
                              }
                          }
                        : {}
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: teacherRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newTeacherGridData = [...teacherGridData]
                                    newTeacherGridData[teacherIndex][gridColumn] = newText
                                    setTeachers(newTeacherGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        overrides
                    )
                )
            }
        })
    })

    const handleClearTableContent = () => {
        setTeachers(generateEmptyTeacherRows(0, Math.max(teachers.length, MIN_ROWS_TO_SHOW)))
    }

    const changedData = [
        ...backgroundUpdates.updated.map(([prev]) => prev),
        ...backgroundUpdates.created,
        ...backgroundUpdates.deleted
    ]

    return (
        <EditTableWrapper ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "teacher data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const formatters = new Map<keyof TeacherGridRow, (input: string) => string>()
                    formatters.set(Number(TeacherGridContentColumn.WorkPercentage), formatPercentInput)

                    const newTeachers = handlePaste<TeacherGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: teacherGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptyTeacherRow(dstRowIndex, '100 %'),
                        indexLocation: targetCell,
                        formatters
                    })
                    setTeachers(newTeachers)
                }}
            />
            {anyDiff(backgroundUpdates) ? (
                <BackgroundChangesAlert
                    diff={backgroundUpdates}
                    changedData={changedData}
                    namesList={changedData.map(shortTeacherDisplayName).toSorted(comparing((dn) => dn.length))}
                    DiffPopupComponent={<SimpleTeacherListDiff diff={backgroundUpdates} />}
                />
            ) : null}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={handleClearTableContent}
            />
        </EditTableWrapper>
    )
}
