import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'

import { ComponentJoiner } from '../../../../../../../components/ComponentJoiner'
import { DiffCard } from '../../../../../../../EditTable/components/DiffCard'
import { diff } from '../../../../../../../EditTable/utils'
import { useSchedule } from '../../../../../../../store/schedule/hooks'
import { stringCmp } from '../../../../../../../utils/compareUtil'
import { teacherDisplayName } from '../../TeacherGrid/util'

import { TeacherListDiffProps } from './types'

export const TeacherListDiff = ({ diff: diffToShow }: TeacherListDiffProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <DiffCard
                title={t('NewTeachers')}
                icon={<PlusOutlined />}
                noChangesText={t('NoNewTeachers')}
                changes={diffToShow.created}
                renderChange={(t) => <li key={t.teacherId}>{teacherDisplayName(t)}</li>}
            />
            <DiffCard
                title={t('UpdatedTeachers')}
                icon={<EditOutlined />}
                noChangesText={t('NoUpdatedTeachers')}
                changes={diffToShow.updated}
                renderChange={([prev, niew]) => {
                    const subjectDiffList = diff(
                        prev.qualifications,
                        niew.qualifications,
                        (subjectId) => subjectId,
                        stringCmp
                    )

                    return (
                        <li key={prev.teacherId}>
                            {teacherDisplayName(prev)}
                            <ul>
                                {propertyDiffListItem(t('Signature'), prev.teacherSchoolId, niew.teacherSchoolId)}
                                {propertyDiffListItem(t('FirstName'), prev.firstName, niew.firstName)}
                                {propertyDiffListItem(t('LastName'), prev.lastName, niew.lastName)}
                                {subjectDiffList.deleted.length > 0 && (
                                    <li>
                                        {t('RemovedQualifications')}:{' '}
                                        <ComponentJoiner
                                            components={subjectDiffList.deleted.map((subjectId) => (
                                                <del>{schedule.findSubject(subjectId).getName()}</del>
                                            ))}
                                        />
                                    </li>
                                )}
                                {subjectDiffList.created.length > 0 && (
                                    <li>
                                        {t('AddedQualifications')}:{' '}
                                        <ComponentJoiner
                                            components={subjectDiffList.created.map((subjectId) =>
                                                schedule.findSubject(subjectId).getName()
                                            )}
                                        />
                                    </li>
                                )}
                                {propertyDiffListItem(
                                    t('Employment'),
                                    `${prev.workPercentage} %`,
                                    `${niew.workPercentage} %`
                                )}
                            </ul>
                        </li>
                    )
                }}
            />
            <DiffCard
                title={t('RemovedTeachers')}
                icon={<MinusOutlined />}
                noChangesText={t('NoRemovedTeachers')}
                changes={diffToShow.deleted}
                renderChange={(t) => <li key={t.teacherId}>{teacherDisplayName(t)}</li>}
            />
        </Flex>
    )
}
