import { Flex } from 'antd'
import { MouseEvent } from 'react'

import { useCompletionStateToggler } from '../../hooks'
import { celebrateClick, isCelebrationStep } from '../../util'

import { ChecklistStateIcon } from './ChecklistStateIcon'
import { ChecklistItemProps } from './types'

export const ChecklistItem = ({
    step,
    isStartDetected,
    isMarkedAsCompleted,
    stepNumber,
    title,
    desc,
    style,
    small
}: ChecklistItemProps) => {
    const toggleState = useCompletionStateToggler(step)

    const onClick = (e: MouseEvent<HTMLSpanElement>) => {
        toggleState()
        if (isCelebrationStep(step) && !isMarkedAsCompleted) {
            celebrateClick(e)
        }
    }

    return (
        <Flex align="center" gap="1em" style={style}>
            <ChecklistStateIcon
                isStartDetected={isStartDetected}
                isMarkedAsCompleted={isMarkedAsCompleted}
                onClick={onClick}
                stepNumber={stepNumber}
                size={small ? '1.7rem' : '3rem'}
            />
            <div>
                <div style={{ fontSize: small ? '100%' : '150%' }} {...(small ? { onClick } : {})}>
                    {title}
                </div>
                <div style={{ color: '#999' }}>{desc}</div>
            </div>
        </Flex>
    )
}
