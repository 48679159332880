import { ReactNode } from 'react'

import classes from './style.module.css'

type HighlightProps = {
    children: ReactNode
}

export default function Highlight({ children }: HighlightProps) {
    return <mark className={classes.mark}>{children}</mark>
}
