import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useColumnWidth } from '../../../../../EditTable/hooks'
import { StudentGroupAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'

import {
    ALL_CONTENT_COLUMNS,
    StudentGroupGridRow,
    StudentGroupsColumnSpec,
    StudentGroupsGridContentColumn,
    StudentGroupsGridMetaDataColumn
} from './types'
import { sortedStudentGroupsFromSchedule } from './utils'

const useSortedStudentGroupsFromSchedule = () => {
    const schedule = useLocalSchedule()

    return sortedStudentGroupsFromSchedule(schedule)
}

const studentGroupToStudentGroupRow = (studentGroup: StudentGroupAccessor, rowIndex: number): StudentGroupGridRow => ({
    [StudentGroupsGridMetaDataColumn.RowIndex]: rowIndex,
    [StudentGroupsGridContentColumn.DisplayName]: studentGroup.getDisplayName(),
    [StudentGroupsGridContentColumn.Labels]: studentGroup.getLabels().join(', '),
    [StudentGroupsGridContentColumn.Description]: studentGroup.getDescription()
})

export const useLiveStudentGroupsTableData = () => {
    const studentGroups = useSortedStudentGroupsFromSchedule()

    return studentGroups.map((studentGroup, index) => studentGroupToStudentGroupRow(studentGroup, index))
}

export const useStudentGroupsTableDataState = () => {
    const studentGroupsTableData = useLiveStudentGroupsTableData()
    const [studentGroupsGridData, setStudentGroupsGridData] = useState<StudentGroupGridRow[]>(studentGroupsTableData)
    const resetStudentGroups = () => {
        setStudentGroupsGridData(studentGroupsTableData)
    }

    return {
        studentGroupsGridData,
        setStudentGroupsGridData,
        resetStudentGroups
    }
}

export const useStudentGroupsGridColumns = (target: RefObject<HTMLDivElement> | null): StudentGroupsColumnSpec[] => {
    const { t } = useTranslation()
    const columnWidth = useColumnWidth(target, ALL_CONTENT_COLUMNS.length)

    return [
        {
            title: '',
            gridColumn: StudentGroupsGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: 60,
            minWidth: 60
        },
        {
            title: t('Name'),
            gridColumn: StudentGroupsGridContentColumn.DisplayName,
            colIndex: 1,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Description'),
            gridColumn: StudentGroupsGridContentColumn.Description,
            colIndex: 2,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Labels'),
            gridColumn: StudentGroupsGridContentColumn.Labels,
            colIndex: 3,
            width: columnWidth,
            minWidth: columnWidth
        }
    ]
}
