import { useTranslation } from 'react-i18next'

import { CourseDetails } from '../../components/courses/CourseDetails'
import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { CourseActionsPanel } from './components/CourseActionsPanel'
import { CoursesTable } from './components/CoursesTable'

const CoursesIndexPage = () => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/courses')

    // Some selected courses may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedCourseId) =>
        schedule.doesCourseIdExist(selectedCourseId)
    )

    const selectedCourses = validSelectedRowKeys.map((courseId) => schedule.findCourse(courseId))

    const detailsPanel = (
        <CourseDetails key={schedule.getVersion() + validSelectedRowKeys.join(',')} courses={selectedCourses} />
    )

    const actionsPanel = (
        <CourseActionsPanel
            courses={selectedCourses}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        >
            <CoursesTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}

export default CoursesIndexPage
