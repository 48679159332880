export const de = {
    translation: {
        ActualHours: 'Tatsächliche Stunden',
        Add: 'Hinzufügen',
        AddLecture: 'Unterrichtsstunde hinzufügen',
        AddSetting: 'Einstellung hinzufügen',
        AddBreakSetting: 'Pauseneinstellung hinzufügen',
        AddStudentGroup: 'Schülergruppe hinzufügen',
        AddSchedule: 'Stundenplan hinzufügen',
        After: 'nach',
        AfterLecture: 'Nach dem Unterricht',
        All: 'Alle',
        AllCourseOfferingsHaveATeacher: 'Alle Kursangebote haben einen Lehrer zugewiesen.',
        AllowSkipInMatching: 'Übersprünge in der Suche erlauben',
        AllVisible: 'Alle sichtbaren',
        And: 'und',
        Authenticating: 'Authentifizierung...',
        At: 'bei',
        AT: '1. HJ',
        Atleast: 'mindestens',
        Attribute: 'Attribut',
        Automation: 'Automatisierung',
        AWeeks: 'A-Wochen',
        BackToAdmin: 'Zurück zur Verwaltung',
        Before: 'vor',
        BeforeLecture: 'Vor der Vorlesung',
        Between: 'zwischen',
        BlockedTime: 'Blockierte Zeit',
        BreakHasToBe: 'Pause ist obligatorisch',
        BreakSettings: 'Pauseneinstellungen',
        BreakShouldPreferablyBe: '...aber vorzugsweise',
        BWeeks: 'B-Wochen',
        Cancel: 'Abbrechen',
        CanteenLoadDuringLunch: 'Auslastung der Schulcafeteria während des Mittagessens',
        CaseSensitive: 'Groß-/Kleinschreibung beachten',
        ChooseLanguage: 'Sprache wählen: ',
        Class: 'Klasse',
        Classes: 'Klassen',
        CourseOfferings: 'Kursangebote',
        CourseOfferingsWithoutTeachers: 'Kursangebote ohne Lehrer',
        CourseOrSubject: {
            Subjects: 'Fächer',
            Courses: 'Kurse',
            Course: 'kurs',
            Subject: 'fach'
        },
        Continue: 'Fortfahren',
        CourseRound: {
            Saved: 'Kursangebot gespeichert',
            SavedMultiple: 'Kursangebote gespeichert',
            SelectSingleToEditName: 'Wählen Sie ein einzelnes Kursangebot aus, um den Namen zu bearbeiten.',
            SelectSingleToEditCourseOrSubject: 'Wählen Sie ein einzelnes Kursangebot aus, um Fach/Kurs zu ändern.',
            SelectSingleToEditStudentGroup: 'Wählen Sie ein einzelnes Kursangebot aus, um die Schülergruppe zu ändern.',
            SelectSingleToEditHours: 'Wählen Sie ein einzelnes Kursangebot aus, um die Stunden zu ändern.',
            SelectSingleToEditBreakRules: 'Wählen Sie ein einzelnes Kursangebot aus, um die Pausenregeln zu ändern.',
            SelectSingleToEditLectureDurations:
                'Wählen Sie ein einzelnes Kursangebot aus, um die Unterrichtsdauer zu ändern.',
            SubjectOrCourse: 'Fach / Kurs',
            Terms: 'Termine',
            SchoolYear: 'Schuljahr',
            FallTerm: 'Herbstsemester',
            SpringTerm: 'Frühjahrssemester',
            TeacherOnlyForTheseOfferings: 'Diese Lehrkraft ist nur den folgenden Kursangeboten zugeordnet: ',
            Singular: 'kursangebot',
            Plural: 'kursangebote'
        },
        CourseListChanges: {
            NewCourses: 'Neue Kurse',
            UpdatedCourses: 'Aktualisierte Kurse',
            DeletedCourses: 'Gelöschte Kurse',
            NoNewCourses: 'Keine neuen Kurse',
            NoUpdatedCourses: 'Keine aktualisierten Kurse',
            NoRemovedCourses: 'Keine gelöschten Kurse'
        },
        Checklist: {
            Staff: 'Personal',
            RoomsAndEquipment: 'Räume und Ausstattung',
            Subjects: 'Fächer',
            Courses: 'Kurse',
            CourseOfferings: 'Kursangebote',
            SelectBaseSettings: 'Grundeinstellungen wählen',
            SelectBaseSettingsDesc: 'Wählen Sie Schulform, Pausenregeln, Unterrichtsdauer usw.',
            AdjustYearDataAndPeriods: 'Schuljahrdaten und Perioden anpassen',
            AdjustYearDataAndPeriodsDesc: 'Wählen Sie Schultage und erstellen Sie Perioden wie A/B-Wochen.',
            ImportData: 'Daten importieren',
            ImportDataDesc:
                'Stellen Sie sicher, dass Lehrer, Schülergruppen, Räume usw. im Stundenplan vorhanden sind.',
            PerformTeacherAssignments: 'Lehrerzuweisungen durchführen',
            PerformTeacherAssignmentsDesc: 'Wählen Sie, welche Lehrer welche Kursangebote unterrichten.',
            GenerateLectures: 'Unterrichtsstunden generieren',
            GenerateLecturesDesc: 'Stellen Sie sicher, dass für alle Kurse Unterrichtsstunden erstellt sind.',
            ScheduleLectures: 'Unterrichtsstunden planen',
            ScheduleLecturesDesc: 'Stellen Sie sicher, dass alle Unterrichtsstunden eine Zeit und einen Raum haben.',
            PublishSchedule: 'Stundenplan veröffentlichen',
            PublishScheduleDesc: 'Übertragen Sie den Stundenplan an das Admin-Panel und veröffentlichen Sie ihn.'
        },
        Filter: 'Filter',
        HighlightSearchText: 'Suchtext hervorheben',
        Rooms: 'Räume',
        ClassHasTooShortLunch: 'Die Klasse {{Class}} hat keine Mittagspause am {{Day}}',
        Code: 'Code',
        CollapseAll: 'Alle minimieren',
        Columns: 'Spalten',
        ColumnsView: {
            SelectDaysToShow: 'Wählen Sie die Tage aus, die im Zeitplan angezeigt werden sollen',
            SelectViewMode: 'Wählen Sie aus, wie Sie Ihre ausgewählten Zeitpläne anzeigen möchten',
            PerSchedule: 'Pro Zeitplan',
            Column: 'Spalte',
            Columns: 'Spalten'
        },
        Condition: 'Bedingung',
        Course: 'Fach',
        Courses: 'Fächer',
        CourseCode: 'Kurskennziffer',
        CourseOffering: 'Unterrichtsangebot',
        CourseOfferingHaveToo: 'Unterrichtsangebot hat zu',
        CourseOfferingHaveTooFewHours: 'Unterrichtsangebot hat zu wenige Stunden: ',
        Create: 'Erstellen',
        DayAndTime: 'Tag und Zeit',
        Description: 'Beschreibung',
        Designation: 'Bezeichnung',
        Development: 'Entwicklung',
        DevelopmentButton: {
            DumpDaysTimes: 'Tage und Zeiten verwerfen',
            RemoveAllPins: 'Alle Markierungen für Tag/Zeit löschen',
            RemoveNonScheduledLectures: 'Nicht geplante Unterrichtsstunden löschen',
            RemoveTimeDay: 'Entfernen Sie Tag/Zeit von allen Unterrichtsstunden',
            RemoveTimeDayNonPinned: 'Entfernen Sie Tag / Zeit von allen nicht markierten Unterrichtsstunden',
            TakeDayTimeOnLecturesOutsideBlock: 'Bitte Tag / Zeit außerhalb eines Unterrichtsblocks wählen'
        },
        OneMoreStudentGroup: 'eine weitere Schülergruppe',
        RemainingStudentGroups: '{{remaining}} weitere Schülergruppen',
        Duration: 'Dauer',
        Employment: 'Beschäftigung',
        EvenWeeks: 'Gerade Wochen',
        Except: 'außer',
        ExpandAll: 'Alle erweitern',
        EditCourseRound: 'Kursrunden bearbeiten',
        Export: 'Exportieren',
        ExportSemester: 'Zeitperiode exportieren',
        Few: 'wenige',
        FirstName: 'Vorname',
        GroupAfterType: 'Gruppieren nach Typ',
        GroupAfterTeacher: 'Gruppieren nach Lehrer',
        GroupAfterClass: 'Gruppieren nach Klasse',
        HaveToBe: 'auf jeden Fall',
        Hours: 'Stunden',
        HoursSmall: '  stunden',
        HoursInPercentOfGoal: 'Stunden in prozentualer Zielerreichung',
        InRelationToLecture: 'In Bezug auf den Unterricht',
        InTimePeriod: 'im Zeitraum',
        Labels: 'Markierungen',
        LastName: 'Nachname',
        Lecture: 'Unterrichtsstunde',
        LogoName: 'Stundenplan',
        LectureSmall: 'unterrichtsstunde',
        LectureIsToo: 'Unterrichtsstunde ist zu',
        LectureDuration: 'Unterrichtsdauer',
        LectureEndsTooLate: 'Unterrichtsstunde endet zu spät:',
        Lectures: 'Unterrichtsstunden',
        LessonDetails: 'Näheres zur Unterrichtsstunde',
        LessonToo: 'Unterricht zu',
        Long: 'lang',
        MainMenu: {
            CanteenLoad: 'Auslastung der Cafeteria',
            Courses: 'Kurse',
            Education: 'Bildung',
            Rooms: 'Räume',
            Schedule: 'Stundenplan',
            ServiceDistribution: 'Serviceverteilung',
            StudentGroups: 'Schülergruppen',
            Teachers: 'Lehrer',
            TeachingGroups: 'Klassen',
            TeachingHours: 'Unterrichtsstunden',
            Parameters: 'Parameter',
            Periods: 'Perioden',
            Lectures: 'Vorlesungen',
            Reports: 'Berichte',
            Settings: 'Einstellungen',
            SettingsSaved: 'Einstellungen gespeichert',
            ShowAll: 'Alle anzeigen'
        },
        Many: 'viele',
        MarkedBlockedTime: 'Geblockte Zeit markieren',
        MarkAsDone: 'Als erledigt markieren',
        MarkAsNotDone: 'Als nicht erledigt markieren',
        Minutes: 'Minuten',
        MinutesLongBut: 'aber',
        MinutesSmall: 'minuten',
        MinimumOf: 'Mindestens',
        Name: 'Name',
        Names: 'Namen',
        NoBreakSettings: 'Keine Pauseneinstellungen',
        NoClass: 'Keine Klasse',
        NoCondition: 'Keine Bedingung',
        NoLectures: 'Keine Unterrichtsstunden',
        NoLecturesMatchFilter: 'Keine Unterrichtsstunden entsprechen den ausgewählten Filtern',
        NoRoom: 'Kein Raum',
        NoRoomSelected: 'Kein Raum ausgewählt für ',
        NoLessonSelected: 'Keine Unterrichtsstunde ausgewählt',
        NoSubjects: 'Keine Fächer',
        NoStudentGroups: 'Keine Schülergruppen',
        NoLabels: 'Keine Etiketten',
        NoRooms: 'Keine Räume',
        NoCourses: 'Keine Kurse',
        NoCourseRounds: 'Keine Kursrunden',
        Non: 'nicht ',
        NonHypen: 'nicht-',
        NonOverlappingStudentGroups: 'Nicht überlappende Schülergruppen',
        NotPartOfBlock: 'Nicht Teil des Blocks',
        NotScheduled: 'Nicht geplant: ',
        NoTeachers: 'Keine Lehrer',
        Number: 'Nr.',
        NumberOfDays: 'Anzahl der Tage',
        NumberOfWeeks: 'Anzahl der Wochen',
        OddWeeks: 'Ungerade Wochen',
        Ok: 'Ok',
        Other: 'Andere',
        OverlappingGroup: 'überlappende Gruppe',
        OverlappingStudentGroups: 'Überlappend Schülergruppen',
        OverlapWith: 'überlappen mit',
        Remove: 'entfernen',
        Reset: 'Zurücksetzen',
        ReservedTime: 'Reservierte Zeit',
        ReservedTimes: 'Reservierte Zeiten',
        RequiredRoomAttributes: 'Erforderliche Raumattribute',
        Room: 'Raum',
        RoomChange: 'Raumwechsel',
        RoomsListTooltip: {
            Singular: 'Raum',
            Plural: 'Räume'
        },
        RoomChangeSmall: 'Raumwechsel',
        RoomDoubleBooked: 'Raum doppelt gebucht: {{roomName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
        RoomMissingAttributes: 'Raum fehlt {{missingAttributes}}',
        RoomScheduledOnBlockedTime:
            'Raum geplant zu blockierter Zeit: {{roomName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
        SameCourseMultipleTimesOnSameDay: 'Derselbe Kurs mehrmals an einem Tag: ',
        SameSubjectMultipleTimesOnSameDay: 'Derselbe Kurs mehrmals an einem Tag: {{subjectName}} mit {{sgName}}',
        Save: 'Speichern',
        SchoolYear: 'Schuljahr',
        SearchCourse: 'Kurs suchen',
        SearchCourseOffering: 'Nach Unterrichtsstunden suchen',
        SearchRoom: 'Nach Räumen suchen',
        SearchPlaceholders: {
            SearchSchedule: 'Nach Stundenplan suchen',
            SearchStudentGroup: 'Nach Schülergruppen suchen',
            SearchTeacher: 'Nach Lehrer suchen'
        },
        Short: 'kurz',
        StudentGroupListChanges: {
            NewStudentGroups: 'Neue Schülergruppen',
            UpdatedStudentGroups: 'Aktualisierte Schülergruppen',
            DeletedStudentGroups: 'Gelöschte Schülergruppen',
            NoNewStudentGroups: 'Keine neuen Schülergruppen',
            NoUpdatedStudentGroups: 'Keine aktualisierten Schülergruppen',
            NoRemovedStudentGroups: 'Keine gelöschten Schülergruppen'
        },
        ShouldPreferablyBe: 'sollte vorzugsweise sein',
        ShowInheritedSettings: 'Vorab festgelegte Einstellungen anzeigen',
        Signature: 'Kürzel',
        Square: 'Quadrat',
        ST: 'VT',
        start: 'Start',
        stop: 'Stopp',
        StudentGroup: 'Schülergruppe',
        StudentGroupsDoubleBooked: 'Schülergruppen doppelt gebucht: {{sgNames}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
        Students: 'Schüler',
        StudentsHasTooShortBreakOnDay: 'Schüler in {{sgName}} haben zu kurze Pause zwischen Vorlesungen am {{day}}',
        Subject: 'Fach',
        SubjectChange: 'Fachwechsel',
        SubjectChangeSmall: 'fachwechsel',
        Teacher: 'Lehrer',
        Teachers: 'Lehrer',
        TeacherDoubleBooked: 'Lehrer doppelt gebucht: {{teacherName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
        TeacherFrameTimeExceeded: 'Lehrer Rahmenzeit überschritten: {{teacherSchoolId}} hat {{frameTime}}',
        TeachingGroup: 'Klasse',
        TeachingGroups: 'Klassen',
        TeacherHasNoLunchBreak: 'Lehrer hat keine Mittagspause: {{teacherSchoolId}} am {{dayName}}',
        TeacherHasTooShortBreak: '{{teacherId}} hat zu kurze Pause zwischen Unterrichtsstunden',
        TeacherHasTooShortBreakOnDay: '{{teacherId}} hat zu kurze Pause zwischen Unterrichtsstunden am {{day}}',
        TeacherScheduledOnBlockedTime:
            'Lehrer geplant zu blockierter Zeit: {{teacherSchoolId}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
        TheCourseOffering: 'das Unterrichtsangebot',
        TheGroup: 'die Gruppe',
        TheGroups: 'die Gruppen',
        ThisAttributeOnlyApplysToTheFollowingRooms: 'Dieses Attribut gilt nur für die folgenden Räume: ',
        ThisAttributeOnlyApplysToTheFollowingLectures:
            'Dieses Attribut gilt nur für die folgenden Unterrichtsstunden: ',
        ThisBlockOnlyApplysToTheFollowingLessons: 'Dieser Block gilt nur für die folgenden Unterrichtsstunden: ',
        ThisGroupOnlyOverlapsWithTheFollowingGroups: 'Diese Gruppe überlappt nur mit den folgenden Gruppen: ',
        ThisLabelOnlyApplysToTheFollowingGroups: 'Dieses Label gilt nur für die folgenden Gruppen: ',
        ThisSubjectOnlyApplysToTheFollowingTeachers: 'Dieses Fach gilt nur für die folgenden Lehrer',
        TimePinned: 'Zeit fixiert',
        WeekDay: 'Wochentag',
        Yes: 'Ja',
        No: 'Nein',
        Preferably: 'vorzugsweise',
        Problems: 'Probleme',
        ProblemTypeLabel: {
            EndTimeProblem: 'Vorlesung endet zu spät',
            TeacherConflictProblem: 'Lehrer doppelt gebucht',
            StudentGroupLunchProblem: 'Klasse hat zu kurze Mittagspause',
            UnAssignedCourseRoundProblemLabel: 'Der Lehrgruppe {{courseName}} fehlt ein Lehrer',
            TeacherBreakProblem: 'Lehrer hat zu kurze Pause zwischen Unterrichtsstunden',
            StudentGroupBreakProblem: 'Schüler haben zu kurze Pause zwischen Unterrichtsstunden',
            RoomConflictProblem: 'Raum doppelt gebucht',
            RoomReservedTimeConflictProblem: 'Raum geplant zu blockierter Zeit',
            RequiredRoomAttributeProblem: 'Raum fehlt Attribut',
            NoRoomSetProblem: 'Unterricht ohne Raum',
            UnscheduledLectureProblem: 'Unterricht nicht geplant',
            ScheduleOverrunProblem: 'Unterricht endet zu spät',
            SameCourseMultipleTimesOnSameDayProblem: 'Gleicher Kurs mehrmals am selben Tag',
            SameSubjectMultipleTimesOnSameDayProblem: 'Gleiches Fach mehrmals am selben Tag',
            StudentGroupConflictProblem: 'Schülergruppe doppelt gebucht',
            TeacherFrameTimeProblem: 'Lehrer Rahmenzeit überschritten',
            TeacherLunchProblem: 'Lehrer hat zu kurze Mittagspause',
            TeacherReservedTimeConflictProblem: 'Lehrer geplant zu blockierter Zeit',
            StudentGroupReservedTimeConflictProblem: 'Schülergruppe während blockierter Zeit geplant',
            LectureDurationProblem: {
                TooLong: 'Unterricht zu lang',
                TooShort: 'Unterricht zu kurz'
            },
            TotalCourseRoundDuration: {
                TooLong: 'Kursrunde hat zu viele Stunden',
                TooShort: 'Kursrunde hat zu wenige Stunden'
            },
            UnassignedCourseRoundProblem: 'Kursrunde fehlt Lehrer',
            UnknownCourseRound: 'Unbekannte Kursrunde',
            UnknownSubject: 'Unbekanntes Fach:',
            TeacherNotQualifiedProblem: 'Lehrer {{teacherName}} ist nicht qualifiziert für {{subjectCode}}',
            TeacherNotQualifiedProblemLabel: 'Dem Lehrer fehlt die Berechtigung',
            TeacherOverloadProblem: {
                Prefix: 'Lehrer {{teacherName}} hat zu viel Unterricht im ',
                FallSuffix: 'Herbstsemester ({{hours}}h)',
                SpringSuffix: 'Frühjahrssemester ({{hours}}h)',
                And: 'und'
            },
            TeacherOverloadProblemLabel: 'Lehrer haben zu viel Unterricht'
        },
        UnknownCourseRound: 'Unbekannte Kursrunde',
        Qualifications: 'Qualifikationen',
        TeacherSaved: 'Lehrer gespeichert',
        TeachersSaved: 'Lehrer gespeichert',
        CourseSaved: 'Kurs gespeichert',
        CoursesSaved: 'Kurse gespeichert',
        StudentGroupSaved: 'Schülergruppe gespeichert',
        StudentGroupsSaved: 'Schülergruppen gespeichert',
        SelectSingleStudentGroupToEditName: 'Wählen Sie eine einzelne Schülergruppe aus, um den Namen zu bearbeiten.',
        SelectSingleStudentGroupToEditDescription:
            'Wählen Sie eine einzelne Schülergruppe aus, um die Beschreibung zu bearbeiten.',
        LecturesReferencingSelectedRooms: '{{count}} Unterricht(e) referenzieren die ausgewählten Räume',
        RoomRemoved: 'Raum entfernt',
        RemoveRoom: 'Raum entfernen',
        RemoveRooms: 'Räume entfernen',
        AddRoom: 'Raum hinzufügen',
        NoRoomAttributes: 'Keine Raumattribute',
        ValidFor: 'Gültig für:',
        WeekDays: {
            Monday: 'Montag',
            Tuesday: 'Dienstag',
            Wednesday: 'Mittwoch',
            Thursday: 'Donnerstag',
            Friday: 'Freitag',
            Saturday: 'Samstag',
            Sunday: 'Sonntag'
        },
        WeekDaysShort: {
            Monday: 'Mo',
            Tuesday: 'Di',
            Wednesday: 'Mi',
            Thursday: 'Do',
            Friday: 'Fr',
            Saturday: 'Sa',
            Sunday: 'So'
        },
        WholeSchoolYear: 'Das ganze Schuljahr',
        Weeks: 'Wochen',
        W: 'w.',
        Actions: 'Aktionen',
        AddPeriod: 'Zeitraum hinzufügen',
        AddSubject: 'Fach hinzufügen',
        AddCourse: 'Kurs hinzufügen',
        AddCourseOffering: 'Kursangebot hinzufügen',
        AddTeacher: 'Lehrer hinzufügen',
        ClearContent: 'Inhalt löschen',
        Edit: 'Bearbeiten',
        EditEvent: 'Ereignis bearbeiten',
        NewSchedule: 'Neuer Stundenplan',
        NewRows: 'Neue Zeilen',
        NoEventSelected: 'Kein Ereignis ausgewählt',
        PeriodName: 'Name des Zeitraums',
        RemoveCourse: 'Kurs entfernen',
        RemoveCourses: 'Kurse entfernen',
        RemoveSubject: 'Fach entfernen',
        RemoveSubjects: 'Fächer entfernen',
        RemoveStudentGroup: 'Schülergruppe entfernen',
        RemoveStudentGroups: 'Schülergruppen entfernen',
        RemoveTeacher: 'Lehrer entfernen',
        ResetContent: 'Inhalt zurücksetzen',
        Search: 'Suche',
        SubjectCode: 'Fachkennziffer',
        TableEditing: 'Tabellenbearbeitung',
        Title: 'Titel',
        TransferToAdmin: 'An Admin übertragen',
        TransferToSelectedSchedule: 'An ausgewählten Stundenplan exportieren',
        TransferComplete: 'Übertragung abgeschlossen',
        TransferStatus: {
            Rooms: 'Räume übertragen',
            Teachers: 'Lehrer übertragen',
            Periods: 'Zeiträume übertragen',
            StudentGroups: 'Schülergruppen übertragen',
            Courses: 'Kurse übertragen',
            Lectures: 'Lektionen übertragen'
        },
        TeacherChanges: {
            NewTeachers: 'Neue Lehrer',
            UpdatedTeachers: 'Aktualisierte Lehrer',
            RemovedTeachers: 'Entfernte Lehrer',
            NoNewTeachers: 'Keine neuen Lehrer',
            NoUpdatedTeachers: 'Keine aktualisierten Lehrer',
            NoRemovedTeachers: 'Keine entfernten Lehrer',
            RemovedQualifications: 'Entfernte Qualifikationen',
            AddedQualifications: 'Hinzugefügte Qualifikationen'
        },
        Errors: {
            BackgroundChanges: 'Hintergrundänderungen',
            DuplicateSignature: 'Signatur kommt auch auf der Zeile vor',
            ErrorsMustBeFixed: 'Die folgenden Fehler müssen behoben werden',
            ImportFailed: 'Schema-Import fehlgeschlagen. Bitte wenden Sie sich an den Meitner-Support.',
            InvalidPercentage: 'Ungültiges Prozentformat',
            LecturesReferencing: 'lektion(er) referenzieren',
            NoSuitableEventType: 'Keine passende Ereignisart gefunden',
            PredefinedPeriodNotFound: {
                SchoolYear: 'Vordefiniertes Zeitraum für Schuljahr nicht gefunden.',
                Spring: 'Vordefiniertes Zeitraum für Frühjahr nicht gefunden.',
                Fall: 'Vordefiniertes Zeitraum für Herbst nicht gefunden.'
            },
            ReservedTimesReferencing: 'reservierte Zeiten referenzieren Zeitraum',
            StudentGroupReferencedInDistribution:
                'Studentengruppe, auf die in der Verteilung verwiesen wird ({{name}})',
            TeacherReferencedInDistribution: 'Teacher referenced in distribution ({{name}})',
            UnknownSubject: 'Unbekanntes Fach:',
            TryAgain: 'Versuchen Sie es erneut',
            PleaseReload: 'Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
            LecturesReferencingPeriod: '{{count}} Vorlesung(en) referenzieren die Periode',
            ReservedTimesReferencingPeriod: '{{count}} reservierte Zeiten referenzieren die Periode',
            LecturesReferencingCourseRound: '{{count}} Vorlesung(en) referenzieren die ausgewählten Kursrunden',
            ExistingLecturesForCourseRound: 'Es gibt bereits Vorlesungen für einige der ausgewählten Kursrunden',
            DuplicateCourseCode: 'Kurskennziffer existiert bereits',
            MissingCourseCode: 'Kurskennziffer fehlt',
            MissingCourseName: 'Kursname fehlt',
            MissingSubject: 'Fach fehlt',
            SubjectNotFound: 'Fach nicht gefunden',
            SubjectNotFoundWithCode: 'Fach {{code}} nicht gefunden'
        },
        Success: {
            Created: 'erstellt',
            ImportComplete: 'Schema importiert.',
            SubjectSaved: 'Fach gespeichert',
            SubjectsSaved: 'Fächer gespeichert',
            SubjectRemoved: 'Fach entfernt',
            SubjectsRemoved: 'Fächer entfernt'
        },
        SelectSingleSubject: {
            BreakRules: 'Wählen Sie ein einzelnes Fach aus, um Rastregeln zu ändern.',
            LectureDurations: 'Wählen Sie ein einzelnes Fach aus, um Lektionslängder zu ändern.'
        },
        StudentGroupId: 'Id - Gruppe',
        EntityTypes: {
            Subject: 'Fach',
            Schedule: 'Stundenplan'
        },
        DuplicateNamesInSubjectsCourses: 'Doppelte Namen in Fächern/Kursen:',
        SelectScheduleToTransferTo:
            'Wählen Sie aus, in welchen Stundenplan der aktuelle Stundenplan übertragen werden soll:',
        ImportFromRoyalSchedule: 'Import von RoyalSchedule',
        Semesters: 'Feiertage',
        'Success.CourseRemoved': 'Kurs entfernt',
        'Errors.CourseRoundsReferencing': '{{count}} Kursrunde(n) verweisen auf die ausgewählten Kurse',
        TeacherListChanges: {
            NewTeachers: 'Nya Lehrer',
            UpdatedTeachers: 'Aktualisierte Lehrer',
            DeletedTeachers: 'Entfernte Lehrer',
            NoNewTeachers: 'Keine neuen Lehrer',
            NoUpdatedTeachers: 'Keine aktualisierten Lehrer',
            NoRemovedTeachers: 'Keine entfernten Lehrer',
            RemovedQualifications: 'Entfernte Qualifikationen',
            AddedQualifications: 'Hinzugefügte Qualifikationen'
        },
        SkipCharacter: 'Zeichen überspringen',
        LeaveUnchanged: 'Unverändert lassen',
        SelectTwoOrMoreLectures: 'Wählen Sie zwei oder mehr Vorlesungen aus, um einen Block zu erstellen',
        AllLecturesAlreadyInBlock: 'Alle ausgewählten Vorlesungen befinden sich bereits in einem Block',
        CreateBlock: 'Block erstellen',
        ExtractSingleLectureFromBlock: 'Nur ausgewählte Vorlesung aus Block extrahieren',
        ExtractSelectedLecturesFromBlock: 'Nur ausgewählte Vorlesungen aus Block extrahieren',
        SplitBlock: 'Block aufteilen',
        MoveToDrawer: 'In Ablage verschieben',
        RemoveLecture: 'Vorlesung entfernen',
        RemoveLectures: 'Vorlesungen entfernen',
        WithSpecificWeeks: 'Mit bestimmten Wochen...',
        ExceptSpecificWeeks: 'Außer bestimmten Wochen...',
        CourseRoundActions: {
            RemoveCourseRound: 'Kursrunde entfernen',
            GenerateLectures: 'Vorlesungen generieren',
            ReferToSubject: 'Auf Fach statt Kurs verweisen'
        },
        ViewOptions: 'Anzeigeoptionen',
        LectureSaved: 'Lektion gespeichert',
        LecturesSaved: 'Lektionen gespeichert',
        SelectLecturesFromSameBlockToEditDayAndTime:
            'Wählen Sie Vorlesungen aus demselben Block aus, um Tag und Uhrzeit zu bearbeiten',
        SelectSingleLectureToEditTeachers: 'Wählen Sie eine einzelne Vorlesung aus, um die Lehrer zu ändern',
        SelectSingleLectureToEditRooms: 'Wählen Sie eine einzelne Vorlesung aus, um die Räume zu ändern',
        SelectSingleLectureToEditWeekSettings:
            'Wählen Sie eine einzelne Vorlesung aus, um die Wocheninstellungen zu ändern',
        SelectSingleLectureToEditDuration: 'Wählen Sie eine einzelne Vorlesung aus, um die Dauer zu ändern',
        SelectSingleLectureToEditBreakSettings:
            'Wählen Sie eine einzelne Vorlesung aus, um die Pauseneinstellungen zu ändern',
        SelectSingleLectureToEditDurationSettings:
            'Wählen Sie eine einzelne Vorlesung aus, um die Dauerinstellungen zu ändern',
        Without: 'ohne',
        With: 'mit',
        SelectWeek: 'Wählen Sie eine Woche…',
        SelectWeekToExtract: 'Wählen Sie eine Woche aus, um auszuschneiden:',
        Extract: 'Ausschneiden',
        ExtractFromSeries: 'Ausschneiden aus Serie',
        AddRequiredRoomAttributes: 'Erforderliche Raumattribute hinzufügen',
        OpenMenu: 'Menü öffnen',
        GraphicalView: 'Grafische Ansicht',
        Settings: 'Einstellungen',
        Staff: 'Personal',
        Subjects: 'Fächer',
        Transfer: 'Übertragen',
        Import: 'Importieren',
        Configuration: 'Konfiguration',
        OpenChecklist: 'Checkliste öffnen',
        LogIn: 'Anmelden',
        NoProfileSelected: 'Kein Profil ausgewählt.',
        NoSchedulerRole: 'Sie haben keine Berechtigung für Meitner Schedule. Kontaktieren Sie Ihren Administrator.',
        SessionExpired: 'Sie wurden abgemeldet.',
        InheritFromCourseRound: 'Von Kursrunde erben',
        SetSpecificForLesson: 'Spezifisch für Lektion setzen',
        DuplicateLecture: 'Vorlesung duplizieren',
        NoReservedTimeSelected: 'Keine reservierte Zeit ausgewählt',
        RemoveReservedTime: 'Reservierte Zeit entfernen',
        RemoveReservedTimes: 'Reservierte Zeiten entfernen',
        Split: 'Teilen',
        SplitLecture: 'Vorlesung teilen',
        StudentGroupAppliesOnlyToTheseReservedTimes:
            'Diese Schülergruppe gilt nur für die folgenden reservierten Zeiten',
        TeacherAppliesOnlyToTheseReservedTimes: 'Diese Lehrkraft gilt nur für die folgenden reservierten Zeiten',
        ThisRoomOnlyApplysToTheFollowingReservedTimes: 'Denna sal gäller bara följande reserverade tider',
        NoEvent: 'Kein Ereignis ausgewählt',
        SelectSingleReservedTimeToChangeLength:
            'Wählen Sie eine einzelne reservierte Zeit aus, um die Länge zu ändern.',
        SelectSingleReservedTimeToChangeTitle: 'Wählen Sie eine einzelne reservierte Zeit aus, um den Titel zu ändern',
        SelectSingleReservedTimeToChangeWeekSettings: 'Select a single reserved time to change week settings',
        LecturesInBlockSelected: '{{count}} of {{total}} lectures in the block selected.',
        SelectAllLecturesInBlock: 'Select all lectures in the block',
        SelectAllLecturesInBlockToEdit: 'to edit day and time.',
        RoomPinned: 'Raum fixiert',
        RoomUnpinned: 'Raum nicht fixiert',
        Loading: 'Lädt',
        RoomSaved: 'Raum gespeichert',
        RoomsSaved: 'Räume gespeichert',
        ShowAll: 'Alle anzeigen',
        ShowFewer: 'Weniger anzeigen',
        ShowLess: 'Weniger anzeigen',
        AddedQualifications: 'Hinzugefügte Qualifikationen',
        ErrorsMustBeFixed: 'Die folgenden Fehler müssen behoben werden',
        NewTeachers: 'Nya Lehrer',
        NoNewTeachers: 'Keine neuen Lehrer',
        NoRemovedTeachers: 'Keine entfernten Lehrer',
        NoUpdatedTeachers: 'Keine aktualisierten Lehrer',
        RemovedQualifications: 'Entfernte Qualifikationen',
        RemovedTeachers: 'Entfernte Lehrer',
        UpdatedTeachers: 'Aktualisierte Lehrer',
        ElementarySchool: 'Grundschule',
        HighSchool: 'Gymnasium',
        HiddenCoursesInElementaryMode:
            'Es sind Kurse im Stundenplan registriert. Im Grundschulmodus ist die Kurstabelle ausgeblendet und Sie können die Kurse erst einsehen oder bearbeiten, wenn Sie wieder in den Gymnasialmodus wechseln.',
        SchoolType: 'Schulform',
        Rows: 'zeilen',
        SettingsSaved: 'Einstellungen gespeichert',
        OpeningHours: 'Öffnungszeiten',
        BackgroundChanges: {
            Updated: 'aktualisiert',
            Added: 'hinzugefügt',
            Removed: 'entfernt',
            Or: 'oder',
            Has: 'hat',
            SinceEditModeOpened:
                ' seit dem Öffnen des Bearbeitungsmodus. Bitte seien Sie bei der Vorschau besonders aufmerksam.',
            BackgroundUpdatesTitle: 'Im Hintergrund wurden Aktualisierungen vorgenommen'
        },
        Validation: {
            SignatureAppears: 'Unterschrift erscheint auch in Zeile {{rows}}',
            UnknownSubject: 'Unbekanntes Fach: {{subject}}',
            MalformedPercentage: 'Falsch formatierte Prozentangabe',
            TeacherSignatureMultipleRows: 'Lehrerunterschrift {{signature}} erscheint in mehreren Zeilen: {{rows}}',
            TeacherRemovedButReferenced:
                'Lehrer {{teacher}} wurde entfernt, wird aber in folgenden Lerngruppen referenziert: {{groups}}',
            RowUnknownSubject: 'Zeile {{row}}: Unbekanntes Fach: {{subject}}',
            RowMalformedPercentage: 'Zeile {{row}}: Falsch formatierte Prozentangabe',
            CouldntRemoveStudentGroups:
                'Kann Schülergruppe {{studentGroup}} nicht entfernen, weil sie in folgenden Kursrunden referenziert wird: {{courseRound}}',
            DuplicateStudentGroupNames: 'Schülergruppenname {{name}} erscheint in mehreren Zeilen: {{rows}}',
            StudentGroupMissingName: 'Schülergruppe saknar Namen',
            StudentGroupAlreadyExists: 'Schülergruppe existiert bereits'
        }
    }
} as const
