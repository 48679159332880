import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import Chip from '../../../../components/Chip'
import { FilterHighlight } from '../../../../components/dev-util/FilterHighlight'
import { StudentGroupFilterDropdown } from '../../../../components/filter-dropdowns/StudentGroupFilterDropdown'
import { StudentGroupLabelFilterDropdown } from '../../../../components/filter-dropdowns/StudentGroupLabelFilterDropdown'
import type { FilterableColumn } from '../../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../../components/table-support/util'
import type { StudentGroupAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import type { FilteringState } from '../../../schedule/components/FilterInput/types'
import { StudentGroupList } from '../StudentGroupList'

import type { StudentGroupRow } from './types'

export enum StudentGroupsColumnKey {
    Name = 0,
    OverlappingStudentGroups = 1,
    Labels = 2
}

export const useStudentGroupColumns = (showOverlappingGroupsColumn: boolean, filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleStudentGroupColumns', [
        StudentGroupsColumnKey.Name,
        StudentGroupsColumnKey.OverlappingStudentGroups,
        StudentGroupsColumnKey.Labels
    ])
    const { t } = useTranslation()

    const columns: FilterableColumn<StudentGroupRow>[] = [
        {
            title: t('Name'),
            key: StudentGroupsColumnKey.Name,
            filterTexts: ({ studentGroup }) => [studentGroup.getDisplayName()]
        },
        ...(showOverlappingGroupsColumn
            ? ([
                  {
                      title: t('OverlappingStudentGroups'),
                      key: StudentGroupsColumnKey.OverlappingStudentGroups,
                      onFilter: (sgId, { studentGroup }) =>
                          studentGroup
                              .getOverlaps()
                              .map((sg: StudentGroupAccessor) => sg.getStudentGroupId())
                              .includes(sgId as string),
                      filterDropdown: StudentGroupFilterDropdown,
                      render: (_, { studentGroup }) => (
                          <StudentGroupList
                              maxClauses={3}
                              studentGroupIds={studentGroup
                                  .getOverlaps()
                                  .map((sg: StudentGroupAccessor) => sg.getStudentGroupId())}
                          />
                      )
                  }
              ] as FilterableColumn<StudentGroupRow>[])
            : []),
        {
            title: t('Labels'),
            key: StudentGroupsColumnKey.Labels,
            onFilter: (label, { studentGroup }) => studentGroup.getLabels().includes(label as string),
            filterDropdown: StudentGroupLabelFilterDropdown,
            render: (_, { studentGroup }) =>
                studentGroup.getLabels().map((label) => (
                    <Chip key={label} variant="filled" size="xs">
                        <FilterHighlight text={label} filtering={filtering} />
                    </Chip>
                )),
            filterTexts: ({ studentGroup }) => studentGroup.getLabels()
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
