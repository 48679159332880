import { Modal } from 'antd'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'

import { ProblemListProblem } from '../../../pages/schedule/components/ProblemsList/types'
import { DiffResult } from '../../utils'
import BulkUpdatePreview from '../BulkUpdatePreview'

import classes from './style.module.css'

type EditModalProps<T> = {
    isPreviewOpen: boolean
    onSave: () => void
    setIsPreviewOpen: (isPreviewOpen: boolean) => void
    diffPreview: DiffResult<T>
    validationErrors: ProblemListProblem[]
    DiffComponent: ComponentType<{
        diff: DiffResult<T>
    }>
}

export default function EditModal<T>({
    isPreviewOpen,
    onSave,
    setIsPreviewOpen,
    diffPreview,
    validationErrors,
    DiffComponent
}: EditModalProps<T>) {
    const { t } = useTranslation()

    return (
        <Modal
            className={classes.editModal}
            width="auto"
            onOk={onSave}
            okText={t('Save')}
            cancelText={t('Cancel')}
            onCancel={() => {
                setIsPreviewOpen(false)
            }}
            okButtonProps={{
                disabled: validationErrors.length > 0
            }}
            open={isPreviewOpen}
            closeIcon={false}
            destroyOnClose
        >
            <h1>{t('Preview')}</h1>
            <BulkUpdatePreview<T>
                diff={diffPreview}
                validationErrors={validationErrors}
                DiffComponent={DiffComponent}
            />
        </Modal>
    )
}
