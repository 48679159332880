import i18next from 'i18next'

import { TableEditValidationProblem } from '../../../../../EditTable/types'
import { diff } from '../../../../../EditTable/utils'
import { CourseAccessor, ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { isBlank, textualListing } from '../../../../../textUtil'
import { newMultiMapFromKeyValuePairs, orThrow } from '../../../../../utils/collections'
import { toTranslate } from '../../../../../utils/miscUtil'
import { ProblemListProblem } from '../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../schedule/components/ProblemsList/utils'

import { parseSubjectRows, subjectCmp } from './data'
import { SubjectGridRow, SubjectGridContentColumn, SubjectGridMetaDataColumn } from './types'
import { conjureSubjectsFromSubjects, isEmptySubjectRow, sortedSubjectsFromSchedule } from './utils'

const checkForDuplicateSubjects = (
    subjectNames: string[],
    subjectRow: SubjectGridRow
): TableEditValidationProblem | undefined => {
    const subjectName = subjectRow[SubjectGridContentColumn.Name]

    const duplicateSubjectNames = subjectNames.filter((name) => name.toLowerCase() === subjectName.toLowerCase())

    if (duplicateSubjectNames.length > 1) {
        return {
            location: {
                rowIndex: subjectRow[SubjectGridMetaDataColumn.RowIndex] + 1,
                colIndex: SubjectGridContentColumn.Name
            },
            problem: createBlocker(toTranslate('Ämne finns redan'))
        }
    }

    return undefined
}

export const subjectCellValidationErrors = (subjects: SubjectGridRow[]): TableEditValidationProblem[] => {
    const result = []
    const subjectNames = subjects.map((subject) => subject[SubjectGridContentColumn.Name])

    for (const subjectRow of subjects) {
        if (isEmptySubjectRow(subjectRow)) {
            continue
        }

        const subjectName = subjectRow[SubjectGridContentColumn.Name]

        if (isBlank(subjectName)) {
            result.push({
                location: {
                    rowIndex: subjectRow[SubjectGridMetaDataColumn.RowIndex] + 1,
                    colIndex: SubjectGridContentColumn.Name
                },
                problem: createBlocker(toTranslate('Ämne saknar namn'))
            })

            return result
        }

        const subjectCode = subjectRow[SubjectGridContentColumn.Code]

        if (isBlank(subjectCode) && !isEmptySubjectRow(subjectRow)) {
            result.push({
                location: {
                    rowIndex: subjectRow[SubjectGridMetaDataColumn.RowIndex] + 1,
                    colIndex: SubjectGridContentColumn.Code
                },
                problem: createBlocker(toTranslate('Ämne saknar kod'))
            })

            return result
        }

        const duplicateProblem = checkForDuplicateSubjects(subjectNames, subjectRow)

        if (duplicateProblem !== undefined) {
            result.push(duplicateProblem)
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newSubjectData: SubjectGridRow[]
): ProblemListProblem[] => {
    const existingSubjects = conjureSubjectsFromSubjects(sortedSubjectsFromSchedule(currentSchedule))
    const parsedSubjects = parseSubjectRows(existingSubjects, newSubjectData)
    const diffToValidate = diff(existingSubjects, parsedSubjects, (s) => s.subjectId, subjectCmp)

    const referencedSubjectIds = currentSchedule
        .getCourses()
        .flatMap((course) => course.getSubject())
        .map((subject) => subject.getSubjectId())

    const deletedAndReferencedSubjects = diffToValidate.deleted.filter((deletedSubject) =>
        referencedSubjectIds.includes(deletedSubject.subjectId)
    )

    // Check for duplicate subject names
    const subjectNameCounts = new Map<string, number[]>()
    newSubjectData.forEach((subjectRow, index) => {
        const name = subjectRow[SubjectGridContentColumn.Name]
        if (name !== '') {
            if (!subjectNameCounts.has(name)) {
                subjectNameCounts.set(name, [])
            }

            const rows = subjectNameCounts.get(name)
            if (rows) {
                rows.push(index)
            }
        }
    })

    const duplicateSubjectErrors = [...subjectNameCounts.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([name, rows]) =>
            createBlocker(
                i18next.t('Validation.SubjectNameMultipleRows', {
                    name,
                    rows: textualListing(
                        rows.map((r) => `${r + 1}`),
                        i18next.t('Rows')
                    )
                })
            )
        )

    const coursesBySubjects = newMultiMapFromKeyValuePairs(
        currentSchedule.getCourses().map((course) => [course.getSubjectId(), course] as [string, CourseAccessor])
    )

    const deletedAndReferencedSubjectsErrors = deletedAndReferencedSubjects.map((deletedAndReferencedSubject) => {
        const referencingCourses = orThrow(coursesBySubjects.get(deletedAndReferencedSubject.subjectId))
            .map((course) => course.getName())
            .join(', ')

        return createBlocker(
            i18next.t('Validation.SubjectRemovedButReferenced', {
                subject: deletedAndReferencedSubject.name,
                courses: referencingCourses
            })
        )
    })

    const missingSubjectCode = newSubjectData.filter((subjectRow) => {
        const subjectCode = subjectRow[SubjectGridContentColumn.Code]

        if (isBlank(subjectCode) && !isEmptySubjectRow(subjectRow)) {
            return subjectRow
        }
    })

    const missingSubjectCodeErrors = missingSubjectCode.map((subjectRow) =>
        createBlocker(
            i18next.t('Validation.SubjectMissingCode', {
                subject: subjectRow[SubjectGridContentColumn.Name]
            })
        )
    )

    return [...deletedAndReferencedSubjectsErrors, ...duplicateSubjectErrors, ...missingSubjectCodeErrors]
}
