import { useTranslation } from 'react-i18next'

import SplitterPage from '../../components/SplitterPage'
import useUrlParamSelection from '../../hooks/useUrlParamSelection'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { SubjectActionsPanel } from './components/SubjectActionsPanel'
import { SubjectDetails } from './components/SubjectDetails'
import { SubjectsTable } from './components/SubjectsTable'

const SubjectsIndexPage = () => {
    const schedule = useLocalSchedule()

    const { t } = useTranslation()
    const { selectedRowKeys, setSelectedRowKeys } = useUrlParamSelection('/subjects')

    // Some selected subjects may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((subjId) => schedule.doesSubjectIdExist(subjId))

    const selectedSubjects = validSelectedRowKeys.map((subjectId) => schedule.findSubject(subjectId))

    const detailsPanel = (
        <SubjectDetails key={schedule.getVersion() + validSelectedRowKeys.join(',')} subjects={selectedSubjects} />
    )

    const actionsPanel = (
        <SubjectActionsPanel
            subjects={selectedSubjects}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <SplitterPage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                { key: 'actions', header: t('Actions'), panel: actionsPanel }
            ]}
        >
            <SubjectsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
        </SplitterPage>
    )
}

export default SubjectsIndexPage
