import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useColumnWidth } from '../../../../../EditTable/hooks'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { toTranslate } from '../../../../../utils/miscUtil'

import {
    ALL_CONTENT_COLUMNS,
    CourseRoundGridColumnSpec,
    CourseRoundGridContentColumn,
    CourseRoundGridMetaDataColumn,
    CourseRoundGridRow
} from './types'
import { courseRoundToCourseRoundRow, sortedCourseRoundsFromSchedule } from './utils'

const useSortedCourseRoundsFromSchedule = () => {
    const schedule = useLocalSchedule()

    return sortedCourseRoundsFromSchedule(schedule)
}

export const useLiveCourseRoundsTableData = () => {
    const courseRounds = useSortedCourseRoundsFromSchedule()

    return courseRounds.map((courseRound, index) => courseRoundToCourseRoundRow(courseRound, index))
}

export const useCourseRoundsTableDataState = () => {
    const courseRoundTableData = useLiveCourseRoundsTableData()
    const [courseRoundGridData, setCourseRounds] = useState<CourseRoundGridRow[]>(courseRoundTableData)
    const resetCourseRounds = () => {
        setCourseRounds(courseRoundTableData)
    }

    return { courseRoundGridData, setCourseRounds, resetCourseRounds }
}

export const useCourseRoundsGridColumns = (target: RefObject<HTMLDivElement> | null): CourseRoundGridColumnSpec[] => {
    const { t } = useTranslation()
    const columnWidth = useColumnWidth(target, ALL_CONTENT_COLUMNS.length)

    return [
        {
            title: '',
            gridColumn: CourseRoundGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: 60,
            minWidth: 60
        },
        {
            title: t('Name'),
            gridColumn: CourseRoundGridContentColumn.Name,
            colIndex: 1,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: toTranslate('Kurs- / ämneskod'),
            gridColumn: CourseRoundGridContentColumn.CourseOrSubjectCode,
            colIndex: 2,
            width: columnWidth
        },
        {
            title: toTranslate('Totalt antal timmar'),
            gridColumn: CourseRoundGridContentColumn.TotalHours,
            colIndex: 3,
            width: columnWidth
        },
        {
            title: toTranslate('Lärare'),
            gridColumn: CourseRoundGridContentColumn.TeacherSignatures,
            colIndex: 4,
            width: columnWidth
        },
        {
            title: toTranslate('Terminer'),
            gridColumn: CourseRoundGridContentColumn.Terms,
            colIndex: 5,
            width: columnWidth
        },
        {
            title: toTranslate('Elevgrupp'),
            gridColumn: CourseRoundGridContentColumn.StudentGroup,
            colIndex: 6,
            width: columnWidth
        }
    ]
}
