import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { ICourseRound } from 'common-api'
import { useTranslation } from 'react-i18next'

import { TeacherId } from '../../../../../../commonTypes'
import { DiffCard } from '../../../../../../EditTable/components/DiffCard'
import { DiffResult } from '../../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { findOrThrow } from '../../../../../../utils/collections'
import { toTranslate } from '../../../../../../utils/miscUtil'
import { sortedCoursesFromSchedule } from '../../../../../courses/components/CoursesTable/CoursesEditTable/utils'
import {
    conjureCoursesFromCourses,
    conjureStudentGroupsFromStudentGroups,
    sortedConjureTeachersFromSchedule
} from '../../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'
import { termsToString } from '../utils'

type CourseRoundListDiffProps = {
    diff: DiffResult<ICourseRound>
}

const CourseRoundListDiff = ({ diff: diffToShow }: CourseRoundListDiffProps) => {
    const { t } = useTranslation()
    const schedule = useLocalSchedule()

    const liveCourses = conjureCoursesFromCourses(sortedCoursesFromSchedule(schedule))
    const liveStudentGroups = conjureStudentGroupsFromStudentGroups(schedule.getStudentGroups())
    const liveTeachers = sortedConjureTeachersFromSchedule(schedule)

    const findCourseCode = (courseId: string) => {
        const course = liveCourses.find((c) => c.courseId === courseId)

        return course?.code ?? courseId
    }

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    const teacherSignatures = (teacherIds: TeacherId[]) =>
        teacherIds
            .map((tid) => findOrThrow(liveTeachers, tid, (t) => t.teacherId))
            .map((t) => t.teacherSchoolId)
            .join(', ')

    return (
        <Flex gap={8}>
            <DiffCard
                title={toTranslate('Nya kursomgångar')}
                icon={<PlusOutlined />}
                noChangesText={toTranslate('Inga nya kursomgångar')}
                changes={diffToShow.created}
                renderChange={(courseRound) => <li key={courseRound.courseRoundId}>{courseRound.displayName}</li>}
            />
            <DiffCard
                title={toTranslate('Uppdaterade kursomgångar')}
                icon={<EditOutlined />}
                noChangesText={toTranslate('Inga uppdaterade kursomgångar')}
                changes={diffToShow.updated}
                renderChange={([prev, next]) => (
                    <li key={prev.courseRoundId}>
                        {prev.displayName}
                        <ul>
                            {propertyDiffListItem(toTranslate('Namn'), prev.displayName, next.displayName)}
                            {propertyDiffListItem(
                                toTranslate('Kurs- / ämneskod'),
                                findCourseCode(prev.courseRoundId),
                                findCourseCode(next.courseRoundId)
                            )}
                            {propertyDiffListItem(
                                toTranslate('Totalt antal timmar'),
                                prev.totalHours.toString(),
                                next.totalHours.toString()
                            )}
                            {propertyDiffListItem(
                                toTranslate('Lärare'),
                                teacherSignatures(prev.teacherIds),
                                teacherSignatures(next.teacherIds)
                            )}
                            {propertyDiffListItem(
                                toTranslate('Terminer'),
                                termsToString(prev.terms),
                                termsToString(next.terms)
                            )}
                            {propertyDiffListItem(
                                toTranslate('Elevgrupp'),
                                liveStudentGroups.find((s) => s.studentGroupId === prev.studentGroupId)?.displayName ??
                                    '',
                                liveStudentGroups.find((s) => s.studentGroupId === next.studentGroupId)?.displayName ??
                                    ''
                            )}
                        </ul>
                    </li>
                )}
            />
            <DiffCard
                title={toTranslate('Borttagna kursomgångar')}
                icon={<MinusOutlined />}
                noChangesText={toTranslate('Inga borttagna kursomgångar')}
                changes={diffToShow.deleted}
                renderChange={(courseRound) => <li key={courseRound.courseRoundId}>{courseRound.displayName}</li>}
            />
        </Flex>
    )
}

export default CourseRoundListDiff
