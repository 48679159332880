import { Spin } from 'antd'

import { useIsDummySchedule } from '../../store/schedule/hooks'

import classes from './style.module.css'
import { ScheduleSuspenseProps } from './types'

function ScheduleSuspense({ children }: ScheduleSuspenseProps) {
    const isDummySchedule = useIsDummySchedule()

    return isDummySchedule ? (
        <Spin className={classes.loadingSpinner} size="large">
            {children}
        </Spin>
    ) : (
        children
    )
}

export default ScheduleSuspense
