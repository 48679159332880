import { UndoOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import type { ResetTableContentButtonProps } from './types'

export const ResetTableContentButton = ({ onReset }: ResetTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button variant="secondary" onClick={onReset} startIcon={<UndoOutlined />}>
            {t('ResetContent')}
        </Button>
    )
}
