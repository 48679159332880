import { ICourse, ICourseOrSubjectId, ICourseRound, IStudentGroup, ISubject, ITeacher, Terms } from 'common-api'
import { v4 as uuid } from 'uuid'

import { matchRowsWithExistingData } from '../../../../../EditTable/data'
import { splitAndCleanCommaSepList } from '../../../../../textUtil'
import { containsSameElements, findOrThrow } from '../../../../../utils/collections'
import { arrayStringCmp, combinedCmpFn, comparing } from '../../../../../utils/compareUtil'
import { parseIntegerString } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/util'

import { CourseRoundGridContentColumn, CourseRoundGridMetaDataColumn, CourseRoundGridRow } from './types'
import { isEmptyCourseRoundsRow } from './utils'

export const courseRoundCmp = combinedCmpFn<ICourseRound>(
    comparing((r) => r.displayName),
    comparing((r) =>
        ICourseOrSubjectId.visit(r.courseOrSubjectId!, {
            courseId: (courseId) => courseId,
            subjectId: (subjectId) => subjectId,
            unknown: () => ''
        })
    ),
    comparing((r) => r.totalHours),
    (r1, r2) => arrayStringCmp(r1.teacherIds, r2.teacherIds),
    comparing((r) => r.terms),
    comparing((r) => r.studentGroupId)
)

export const parseTerms = (terms: string): Terms | undefined => {
    const cleanedTerms = terms.trim().toLowerCase()

    if (cleanedTerms === 'ht') {
        return Terms.FALL
    }

    if (cleanedTerms === 'vt') {
        return Terms.SPRING
    }

    if (cleanedTerms === 'läsår') {
        return Terms.YEAR
    }

    return undefined
}

const isCourse = (courseOrSubject: ICourse | ISubject): courseOrSubject is ICourse =>
    (courseOrSubject as any).courseId !== undefined

const getCourseOrSubjectCode2 = (courseOrSubject: ICourse | ISubject): string =>
    isCourse(courseOrSubject) ? courseOrSubject.code : courseOrSubject.code

const matchScore = (
    row: CourseRoundGridRow,
    courseRound: ICourseRound,
    courseOrSubject: ICourse | ISubject,
    teachers: ITeacher[],
    studentGroup: IStudentGroup
) =>
    (courseRound.displayName === row[CourseRoundGridContentColumn.Name].trim() ? 1 : 0) +
    (getCourseOrSubjectCode2(courseOrSubject) === row[CourseRoundGridContentColumn.CourseOrSubjectCode].trim()
        ? 1
        : 0) +
    (courseRound.totalHours === parseIntegerString(row[CourseRoundGridContentColumn.TotalHours]) ? 1 : 0) +
    (containsSameElements(
        splitAndCleanCommaSepList(row[CourseRoundGridContentColumn.TeacherSignatures]),
        teachers.map((t) => t.teacherSchoolId)
    )
        ? 1
        : 0) +
    (courseRound.terms === parseTerms(row[CourseRoundGridContentColumn.Terms]) ? 1 : 0) +
    (studentGroup.displayName === row[CourseRoundGridContentColumn.StudentGroup].trim() ? 1 : 0)

type ParseCoursesRowsParams = {
    existingCourseRounds: ICourseRound[]
    existingCourses: ICourse[]
    existingStudentGroups: IStudentGroup[]
    existingSubjects: ISubject[]
    existingTeachers: ITeacher[]
    rowsIncludingEmpty: CourseRoundGridRow[]
}

const getCourseOrSubjectId = (
    courseOrSubjectCode: string,
    existingSubjects: ISubject[],
    existingCourses: ICourse[]
): ICourseOrSubjectId | undefined => {
    const course = existingCourses.find((c) => c.code === courseOrSubjectCode)
    if (course !== undefined) {
        return ICourseOrSubjectId.courseId(course.courseId)
    }

    const subject = existingSubjects.find((s) => s.code === courseOrSubjectCode)
    if (subject !== undefined) {
        return ICourseOrSubjectId.subjectId(subject.subjectId)
    }

    return undefined
}

const getCourseOrSubjectOrThrow = (
    courseOrSubjectId: ICourseOrSubjectId,
    existingSubjects: ISubject[],
    existingCourses: ICourse[]
): ICourse | ISubject =>
    ICourseOrSubjectId.visit<ICourse | ISubject>(courseOrSubjectId, {
        courseId: (courseId) => findOrThrow(existingCourses, courseId, (c) => c.courseId),
        subjectId: (subjectId) => findOrThrow(existingSubjects, subjectId, (s) => s.subjectId),
        unknown: () => {
            throw new Error('Course or subject not found')
        }
    })

export const parseCourseRoundsRows = ({
    existingCourseRounds,
    existingCourses,
    existingSubjects,
    existingStudentGroups,
    existingTeachers,
    rowsIncludingEmpty
}: ParseCoursesRowsParams): ICourseRound[] => {
    const rows = rowsIncludingEmpty.filter((row) => !isEmptyCourseRoundsRow(row))
    const matchedCourseRounds = matchRowsWithExistingData<ICourseRound, CourseRoundGridRow>({
        existingData: existingCourseRounds,
        rows,
        matchRow: CourseRoundGridMetaDataColumn.RowIndex,
        matchScore: (row, courseRound) => {
            const courseOrSubject = getCourseOrSubjectOrThrow(
                courseRound.courseOrSubjectId!,
                existingSubjects,
                existingCourses
            )

            const teachers = courseRound.teacherIds.map((teacherId) =>
                findOrThrow(existingTeachers, teacherId, (t) => t.teacherId)
            )
            const studentGroup = findOrThrow(existingStudentGroups, courseRound.studentGroupId, (s) => s.studentGroupId)

            return matchScore(row, courseRound, courseOrSubject, teachers, studentGroup)
        },
        maximumScore: 5,
        minimumAcceptableScore: 3
    })

    const parsedCourseRounds = []
    for (const row of rows) {
        const matchedCourseRound = matchedCourseRounds.get(row[CourseRoundGridMetaDataColumn.RowIndex])
        const courseRoundId = matchedCourseRound?.courseRoundId ?? uuid()

        // Display name
        const displayName = row[CourseRoundGridContentColumn.Name].trim()

        // Course or subject id
        const courseOrSubjectId = getCourseOrSubjectId(
            row[CourseRoundGridContentColumn.CourseOrSubjectCode].trim(),
            existingSubjects,
            existingCourses
        )
        if (courseOrSubjectId === undefined) {
            continue
        }

        // Total hours
        const totalHours = parseIntegerString(row[CourseRoundGridContentColumn.TotalHours])
        if (Number.isNaN(totalHours)) {
            continue
        }

        // Teachers
        const teacherIds = []
        for (const teacherSignature of splitAndCleanCommaSepList(row[CourseRoundGridContentColumn.TeacherSignatures])) {
            const teacher = existingTeachers.find((t) => t.teacherSchoolId === teacherSignature)
            if (teacher === undefined) {
                continue
            }

            teacherIds.push(teacher.teacherId)
        }

        // Terms
        const terms = parseTerms(row[CourseRoundGridContentColumn.Terms])
        if (terms === undefined) {
            continue
        }

        // Student group
        const sgDisplayName = row[CourseRoundGridContentColumn.StudentGroup].trim()
        const studentGroup = existingStudentGroups.find((sg) => sg.displayName === sgDisplayName)
        if (studentGroup === undefined) {
            continue
        }

        const studentGroupId = studentGroup.studentGroupId

        parsedCourseRounds.push({
            ...matchedCourseRound,
            courseRoundId,
            displayName,
            courseOrSubjectId,
            teacherIds,
            studentGroupId,
            terms,
            totalHours
        })
    }

    return parsedCourseRounds
}
