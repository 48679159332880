import { IScheduleTransform } from 'common-api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import EditModal from '../../../../../EditTable/components/EditModal'
import { diff } from '../../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'

import SubjectListDiff from './components/SubjectListDiff'
import { parseSubjectRows, subjectCmp } from './data'
import { useSubjectTableDataState } from './hooks'
import SubjectsEditGrid from './SubjectsEditGrid'
import { conjureSubjectsFromSubjects, sortedSubjectsFromSchedule } from './utils'
import { globalValidationErrors } from './validation'

export default function SubjectsEditTable() {
    const schedule = useLocalSchedule()
    const liveSubjects = conjureSubjectsFromSubjects(sortedSubjectsFromSchedule(schedule))
    const [subjectsWhenEditStarted, setSubjectsWhenEditStarted] = useState(liveSubjects)

    const { subjectGridData, setSubjects, resetSubjects } = useSubjectTableDataState()
    const dispatch = useDispatch()

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const onSave = () => {
        const parsedSubjects = parseSubjectRows(liveSubjects, subjectGridData)
        const diffToSave = diff(liveSubjects, parsedSubjects, (subject) => subject.subjectId, subjectCmp)

        const subjectTransforms = [
            ...diffToSave.deleted.map((subjectToDelete) =>
                IScheduleTransform.subjectDeleteTransform({ subjectId: subjectToDelete.subjectId })
            ),
            ...diffToSave.updated.map(([_, newSubject]) => IScheduleTransform.subjectTransform({ newSubject })),
            ...diffToSave.created.map((subjectToCreate) =>
                IScheduleTransform.subjectTransform({ newSubject: subjectToCreate })
            )
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(subjectTransforms)))
        setIsPreviewOpen(false)

        setSubjectsWhenEditStarted(parsedSubjects)
    }

    const newSubjects = parseSubjectRows(liveSubjects, subjectGridData)
    const diffPreview = diff(liveSubjects, newSubjects, (subject) => subject.subjectId, subjectCmp)

    const validationErrors = globalValidationErrors(schedule, subjectGridData)

    return (
        <>
            <EditModal
                isPreviewOpen={isPreviewOpen}
                onSave={onSave}
                setIsPreviewOpen={setIsPreviewOpen}
                diffPreview={diffPreview}
                validationErrors={validationErrors}
                DiffComponent={SubjectListDiff}
            />
            <SubjectsEditGrid
                subjects={subjectGridData}
                setSubjects={setSubjects}
                onReset={resetSubjects}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
                subjectsWhenEditingStarted={subjectsWhenEditStarted}
            />
        </>
    )
}
