import { IScheduleTransform } from 'common-api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import EditModal from '../../../../../EditTable/components/EditModal'
import { diff } from '../../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { sortedCoursesFromSchedule } from '../../../../courses/components/CoursesTable/CoursesEditTable/utils'
import { sortedSubjectsFromSchedule } from '../../../../subjects/components/SubjectsTable/SubjectsEditTable/utils'
import {
    conjureCourseRoundsFromCourseRounds,
    conjureCoursesFromCourses,
    conjureStudentGroupsFromStudentGroups,
    conjureSubjectsFromSubjects,
    conjureTeacherFromTeachers
} from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'

import CourseRoundListDiff from './components/CourseRoundListDiff'
import CourseRoundsEditGrid from './CourseRoundsEditGrid'
import { courseRoundCmp, parseCourseRoundsRows } from './data'
import { useCourseRoundsTableDataState } from './hooks'
import { sortedCourseRoundsFromSchedule } from './utils'
import { globalValidationErrors } from './validation'

const CourseRoundsEditTable = () => {
    const schedule = useLocalSchedule()
    const liveCourseRounds = conjureCourseRoundsFromCourseRounds(sortedCourseRoundsFromSchedule(schedule))
    const [courseRoundsWhenEditStarted, setCourseRoundsWhenEditStarted] = useState(liveCourseRounds)

    const { courseRoundGridData, setCourseRounds, resetCourseRounds } = useCourseRoundsTableDataState()
    const dispatch = useDispatch()

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const liveCourses = conjureCoursesFromCourses(sortedCoursesFromSchedule(schedule))
    const liveStudentGroups = conjureStudentGroupsFromStudentGroups(schedule.getStudentGroups())
    const liveSubjects = conjureSubjectsFromSubjects(sortedSubjectsFromSchedule(schedule))
    const liveTeachers = conjureTeacherFromTeachers(schedule.getTeachers())
    const onSave = () => {
        const parsedCourseRounds = parseCourseRoundsRows({
            existingCourseRounds: liveCourseRounds,
            existingCourses: liveCourses,
            existingStudentGroups: liveStudentGroups,
            existingSubjects: liveSubjects,
            existingTeachers: liveTeachers,
            rowsIncludingEmpty: courseRoundGridData
        })
        const diffToSave = diff(
            liveCourseRounds,
            parsedCourseRounds,
            (courseRound) => courseRound.courseRoundId,
            courseRoundCmp
        )

        const courseRoundTransforms = [
            ...diffToSave.deleted.map((courseRoundToDelete) =>
                IScheduleTransform.courseRoundDeleteTransform({ courseRoundId: courseRoundToDelete.courseRoundId })
            ),
            ...diffToSave.updated.map(([_, newCourseRound]) =>
                IScheduleTransform.courseRoundTransform({ newCourseRound })
            ),
            ...diffToSave.created.map((courseRoundToCreate) =>
                IScheduleTransform.courseRoundTransform({ newCourseRound: courseRoundToCreate })
            )
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(courseRoundTransforms)))
        setIsPreviewOpen(false)

        setCourseRoundsWhenEditStarted(parsedCourseRounds)
    }

    const newCourseRounds = parseCourseRoundsRows({
        existingCourseRounds: liveCourseRounds,
        existingCourses: liveCourses,
        existingStudentGroups: liveStudentGroups,
        existingSubjects: liveSubjects,
        existingTeachers: liveTeachers,
        rowsIncludingEmpty: courseRoundGridData
    })
    const diffPreview = diff(
        liveCourseRounds,
        newCourseRounds,
        (courseRound) => courseRound.courseRoundId,
        courseRoundCmp
    )

    const validationErrors = globalValidationErrors(schedule, courseRoundGridData)

    return (
        <>
            <EditModal
                isPreviewOpen={isPreviewOpen}
                onSave={onSave}
                setIsPreviewOpen={setIsPreviewOpen}
                diffPreview={diffPreview}
                validationErrors={validationErrors}
                DiffComponent={CourseRoundListDiff}
            />
            <CourseRoundsEditGrid
                courseRounds={courseRoundGridData}
                setCourseRounds={setCourseRounds}
                onReset={resetCourseRounds}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
                courseRoundsWhenEditingStarted={courseRoundsWhenEditStarted}
            />
        </>
    )
}

export default CourseRoundsEditTable
