import { StarFilled, StarOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import TypeScale from '../../../styles/TypeScale'
import { toTranslate } from '../../../utils/miscUtil'
import { FilterHighlight } from '../../dev-util/FilterHighlight'
import { useSearchStore } from '../store'
import styles from '../style.module.css'
import { defaultFiltering, type ResultSectionProps } from '../types'

export const ResultSection = ({ results, title, isSearching, isSelected, searchText }: ResultSectionProps) => {
    const { favorites, addFavorite, removeFavorite } = useSearchStore()

    return (
        <div>
            <p className={classNames(TypeScale.Label_SM_Semibold, styles.label)}>{title}</p>
            <div className={styles.resultsContainer}>
                {results.length === 0 ? (
                    <p className={classNames(TypeScale.Label_SM_Regular, styles.label)}>
                        {toTranslate(isSearching ? 'No results found' : 'Start typing to search...')}
                    </p>
                ) : (
                    results.map((result) => {
                        const favorite = favorites.find((favorite) => favorite.url === result.url)

                        return (
                            <div
                                key={result.id}
                                className={classNames(styles.item, {
                                    [styles.selected]: isSelected(`result-${result.id}`)
                                })}
                            >
                                <Link to={result.url} onClick={result.onClick}>
                                    <FilterHighlight
                                        text={result.title}
                                        filtering={{
                                            ...defaultFiltering,
                                            filterText: searchText
                                        }}
                                    />
                                </Link>
                                {favorite?.id ? (
                                    <StarFilled
                                        className={styles.starIcon}
                                        onClick={() => {
                                            removeFavorite(favorite.id)
                                        }}
                                    />
                                ) : (
                                    <StarOutlined
                                        className={styles.starIcon}
                                        onClick={() => {
                                            addFavorite({ term: result.title, url: result.url })
                                        }}
                                    />
                                )}
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}
