import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined,
    MergeCellsOutlined,
    SplitCellsOutlined
} from '@ant-design/icons'
import { Button, Dropdown, Empty, Flex, Space } from 'antd'
import { cloneElement, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocalSchedule } from '../../../store/schedule/hooks'
import { DuplicateLectureButton } from '../DuplicateLectureButton'
import { ExtractLectureFromSeriesButton } from '../ExtractLectureFromSeriesButton'
import { SplitLectureButton } from '../SplitLectureButton'

import { LectureActionsPanelProps } from './types'
import { useLectureActions } from './useLectureActions'

const LecturesActionsPanel = ({ lectureIds, onDeleteLecture }: LectureActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const { handleBlockSplit, handleDuplicate, handleDeleteLecture, handleUnscheduleLecture, handleBlockCreation } =
        useLectureActions(lectureIds, onDeleteLecture)

    // If selected lecture has been deleted (for example in another browser tab) the selection may have become invalid.
    const validSelectedLectureIds = lectureIds.filter((lectureId) => schedule.doesLectureIdExist(lectureId))

    if (validSelectedLectureIds.length === 0) {
        return <Empty description={t('NoLessonSelected')} />
    }

    const lectures = lectureIds.map((lid) => schedule.findLecture(lid))

    return (
        <Flex vertical gap={8} align="stretch" flex={1}>
            <Button
                icon={<MergeCellsOutlined />}
                onClick={() => {
                    handleBlockCreation()
                }}
                disabled={lectures.length < 2}
            >
                {t('CreateBlock')}
            </Button>
            <Dropdown.Button
                buttonsRender={([left, right]) => [
                    isValidElement(left) ? cloneElement(left, { ...left.props, style: { flex: 1 } }) : left,
                    right
                ]}
                menu={{
                    items: [
                        {
                            key: 1,
                            label:
                                lectures.length === 1
                                    ? t('ExtractSingleLectureFromBlock')
                                    : t('ExtractSelectedLecturesFromBlock'),
                            onClick: () => {
                                handleBlockSplit(false)
                            }
                        }
                    ]
                }}
                icon={<DownOutlined />}
                onClick={() => {
                    handleBlockSplit(true)
                }}
            >
                <Space>
                    <SplitCellsOutlined />
                    {t('SplitBlock')}
                </Space>
            </Dropdown.Button>
            {lectures.length === 1 ? (
                <DuplicateLectureButton
                    onDuplicate={() => {
                        handleDuplicate(lectures[0])
                    }}
                    disabled={lectures.length !== 1}
                />
            ) : null}

            <SplitLectureButton disabled={lectures.length !== 1} lectureId={lectureIds[0]} />
            <ExtractLectureFromSeriesButton lectureIds={lectureIds} />
            <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                    handleUnscheduleLecture()
                }}
            >
                {t('MoveToDrawer')}
            </Button>
            <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                    handleDeleteLecture()
                }}
            >
                {t(lectures.length === 1 ? 'RemoveLecture' : 'RemoveLectures')}
            </Button>
        </Flex>
    )
}

export default LecturesActionsPanel
