import { PushpinFilled } from '@ant-design/icons'
import classNames from 'classnames'
import type { IDayAndTime } from 'common-api'

import { formatHHMM } from '../../../../utils/DayAndTimeUtil'

import classes from './style.module.css'

type CornerPos = 'topleft' | 'bottomright'

export const TimestampCorner = ({
    pos,
    dayAndTime,
    pinned = false
}: {
    dayAndTime: IDayAndTime
    pos: CornerPos
    pinned?: boolean
}) => {
    const formattedDayAndTime = formatHHMM(dayAndTime)

    return (
        <div
            className={classNames(classes.timestampCorner, {
                [classes['timestampCorner--topleft']]: pos === 'topleft',
                [classes['timestampCorner--bottomright']]: pos === 'bottomright'
            })}
        >
            <div className={classes.timestampCorner__content}>
                <time dateTime={formattedDayAndTime}>{formattedDayAndTime}</time>
                {pinned ? <PushpinFilled style={{ fontSize: '80%' }} /> : null}
            </div>
        </div>
    )
}
