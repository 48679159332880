import { useTranslation } from 'react-i18next'

import { CourseRoundDetails } from '../../../components/course-rounds/CourseRoundDetails'
import TeacherDetails from '../../../components/teachers/TeacherDetails'
import { CourseRoundAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { CourseRoundActionsPanel } from '../../course-rounds/components/CourseRoundActionsPanel'
import { TeacherActionsPanel } from '../../teachers/components/TeacherActionsPanel'

type TeachingAssignmentsSidePanelsProps = {
    selectedTeachersIds: string[]
    setSelectedTeachersIds: React.Dispatch<React.SetStateAction<string[]>>
    selectedCourseRounds: CourseRoundAccessor[]
    setSelectedCourseRounds: React.Dispatch<React.SetStateAction<CourseRoundAccessor[]>>
    schedule: ReturnType<typeof useLocalSchedule>
}

const useTeachingAssignmentsSidePanels = ({
    selectedTeachersIds,
    setSelectedTeachersIds,
    selectedCourseRounds,
    setSelectedCourseRounds,
    schedule
}: TeachingAssignmentsSidePanelsProps) => {
    const { t } = useTranslation()
    const courseRoundIds = selectedCourseRounds.map((cr) => cr.getCourseRoundId())

    const onDeleteSidePanel = () => {
        setSelectedTeachersIds([])
        setSelectedCourseRounds([])
    }

    const teacherDetailsPanel = (
        <TeacherDetails
            key={String(schedule.getVersion()) + selectedTeachersIds.join(',')}
            teacherIds={selectedTeachersIds}
        />
    )

    const teacherActionsPanel = (
        <TeacherActionsPanel
            key={selectedTeachersIds.join(',')}
            onDelete={onDeleteSidePanel}
            teacherIds={selectedTeachersIds}
        />
    )

    const courseRoundDetailsPanel = (
        <CourseRoundDetails
            key={String(schedule.getVersion()) + selectedCourseRounds.map((cr) => cr.getCourseRoundId()).join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    const courseRoundActionsPanel = (
        <CourseRoundActionsPanel
            onDelete={onDeleteSidePanel}
            key={selectedCourseRounds.map((cr) => cr.getCourseRoundId()).join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    const sidePanels = [
        {
            key: 'edit',
            header: t('Edit'),
            panel: selectedTeachersIds.length > 0 ? teacherDetailsPanel : courseRoundDetailsPanel
        },
        {
            key: 'actions',
            header: t('Actions'),
            panel: selectedTeachersIds.length > 0 ? teacherActionsPanel : courseRoundActionsPanel
        }
    ]

    const selectedRowKeys = [...courseRoundIds, ...selectedTeachersIds]

    return {
        sidePanels,
        selectedRowKeys,
        onDeleteSidePanel
    }
}

export default useTeachingAssignmentsSidePanels
