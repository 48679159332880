import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import type { ClearTableContentButtonProps } from './types'

export const ClearTableContentButton = ({ onClear }: ClearTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button destructive variant="secondary" onClick={onClear} startIcon={<DeleteOutlined />}>
            {t('ClearContent')}
        </Button>
    )
}
